import { IGreenRoomActionPanelTabs } from './types';

export const greenRoomActionPanelOptions = [
  {
    id: IGreenRoomActionPanelTabs.CURRENT_SESSION,
    content: 'Live Session',
  },
  {
    id: IGreenRoomActionPanelTabs.MEDIA,
    content: 'Media',
  },
  {
    id: IGreenRoomActionPanelTabs.ANNOUNCEMENTS,
    content: 'Extras',
  },
];
