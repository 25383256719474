import React from 'react';
import CurrentSessionTabItem from './CurrentSessionTabItem';

const CurrentSessionTab = (props) => {
  const {
    sessionsData,
    userMoveFns,
    accountStreamMap,
    refreshListOfPeople,
    localAvConfig,
  } = props;
  return sessionsData.map((item, index) => (
    <CurrentSessionTabItem
      index={index}
      speakers={item.speakers}
      name={item.name}
      time={item.time}
      userMoveFns={userMoveFns}
      accountStreamMap={accountStreamMap}
      refreshListOfPeople={refreshListOfPeople}
      localAvConfig={localAvConfig}
      eventTz={item.eventTz}
    />
  ));
};

export default CurrentSessionTab;
