import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import ImageIcon from '@/components/ui/new-icons/Image';
import ImageAddIcon from '@/components/ui/new-icons/ImageAdd';
import VideoRecordingIcon from '@/components/ui/new-icons/VideoRecording';
import VideoRecordingAddIcon from '@/components/ui/new-icons/VideoRecordingAdd';
import PresentationIcon from '@/components/ui/new-icons/Presentation';
import { IUploadType } from '@/hooks/use-uploader';
import MediaPreview from '../MediaPreview';
import styles from './styles.module.scss';
import IconTextButton, {
  IIconTextButtonTypes,
  IUIIcons,
} from '@/components/ui/buttons/IconTextButton';
import { IContentColors } from '@/types';

const MediaList = ({
  mediaList,
  title,
  onMediaClick,
  selected,
  onAdd,
  onDelete,
  mediaType,
  channelId,
  moveToId = -1,
  setMoveToId = () => undefined,
  filterOnBroadCast = true,
  isItemClickDisabled=false
}) => {
  const mediaExist = mediaList.length > 0;
  const [mediaRecords, setMediaRecords] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const { AddIcon } = (() => {
    if (mediaType === IUploadType.VIDEO) {
      return {
        DisplayIcon: VideoRecordingIcon,
        AddIcon: VideoRecordingAddIcon,
      };
    }
    if (mediaType === IUploadType.PDF_FILE) {
      return {
        DisplayIcon: PresentationIcon,
        AddIcon: ImageAddIcon,
      };
    }
    return {
      DisplayIcon: ImageIcon,
      AddIcon: ImageAddIcon,
    };
  })();

  const { compareKey, DisplayIcon, uploadText, emptyStateText } = (() => {
    let ck; // compareKey
    let di; // DisplayIcon
    let ut; // uploadText
    let est; // emptyStateText

    if (mediaType === IUploadType.IMAGE) {
      ck = 'channelImageId';
      di = ImageIcon;
      ut = 'Upload New Image';
      est = 'No Pre-uploaded images';
    } else if (mediaType === IUploadType.VIDEO) {
      ck = 'channelVideoId';
      di = VideoRecordingIcon;
      ut = 'Upload New Video';
      est = 'No Pre-uploaded videos';
    } else if (mediaType === IUploadType.PDF_FILE) {
      ck = 'channelPresentationId';
      di = PresentationIcon;
      ut = 'Upload New Presentation';
      est = 'No Pre-uploaded pdfs';
    }

    return {
      compareKey: ck,
      DisplayIcon: di,
      uploadText: ut,
      emptyStateText: est,
    };
  })();

  const sortedMediaList = useMemo(() => {
    if (!selected || !filterOnBroadCast) {
      return mediaList;
    }
    const selectedMedia = mediaList.find(v => v[compareKey] === selected);
    const withoutSelected = mediaList.filter(v => v[compareKey] !== selected);
    if (selectedMedia) {
      withoutSelected.unshift(selectedMedia);
    }
    return withoutSelected;
  }, [mediaList, selected]);

  useEffect(() => {
    if(sortedMediaList?.length > 0) {
      setMediaRecords(sortedMediaList);
    }
  }, [sortedMediaList])

  const refs =  React.useMemo(() => mediaRecords.reduce((acc, value) => {
    acc[value[compareKey]] = React.createRef();
    return acc;
  }, {}),[mediaRecords]);

  useEffect(() => {
    if(!moveToId || moveToId === -1) return;

    refs[moveToId].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    
    setMoveToId(-1);
  }, [moveToId])

  const getItemStyle = (isDragging, draggableStyle, index) => ({
    userSelect: 'none',
    background: isDragging ? 'grey' : 'none',
    ...draggableStyle,
    top: `${(index * 60) + (index > 1 ? ((index - 1) * 16) : 0)}px`,
    position:  'static',
    left: '0px'
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragStart = () => {
    setIsDragging(true);
  }

  const onDragEnd = result => {
    const { destination, source, draggableId } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    let newItems: any = reorder(
      mediaRecords,
      source.index,
      destination.index,
    );

    setMediaRecords(newItems);
    setIsDragging(false);
  };

  return (
    <div className={classnames(styles.container,{[styles.disabledClick]:isItemClickDisabled})}>
      {mediaExist && (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} >
          <Droppable droppableId="droppable-poll-options">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.imageList}>
                {mediaRecords.map((v, index) => {
                  const optionNumber = index + 1;
                  return <Draggable
                    key={`DRAGGABLE-OPTION-${optionNumber}`}
                    draggableId={`DRAGGABLE-ID-${optionNumber}`}
                    index={index}
                  >
                    {(_provided, _snapshot) => (
                      <div className={styles.row} key={`OPTION-${optionNumber}`}
                        ref={_provided.innerRef}
                        {..._provided.draggableProps}
                        {..._provided.dragHandleProps}
                        style={getItemStyle(
                          _snapshot.isDragging,
                          _provided.draggableProps.style,
                          index
                        )}
                      >
                        <div ref={refs[v[compareKey]]}>
                          <MediaPreview
                            key={v[compareKey]}
                            mediaType={mediaType}
                              onClick={(isFillerVideo: boolean) => {
                                onMediaClick({
                                  ...v,
                                  isFillerVideo: isFillerVideo || false,
                                });
                              }}
                            onDelete={() => onDelete(v)}
                            selected={v[compareKey] === selected}
                            channelId={channelId}
                            {...v}
                            mediaId={v[compareKey]}
                            currentlySelectedMediaId={selected}
                            isItemClickDisabled={isItemClickDisabled}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!mediaExist && (
        <div className={styles.emptyState}>
          <DisplayIcon color={IContentColors.NEUTRAL_MID_1} size={18} />
          <Text
            text={emptyStateText}
            textSize={ITextSizes.SMALL}
            textColor={ITextColors.NEUTRAL_MEDIUM_2}
          />
        </div>
      )}
      <div className={styles.addCta}>
        <IconTextButton
          buttonType={IIconTextButtonTypes.COLOURS_NEUTRAL_BASE_3}
          showDefault
          onClick={onAdd}
          defaultLabel={uploadText}
          defaultIcon={IUIIcons.UPLOAD_FILLED}
        />
      </div>
    </div>
  );
};

export default MediaList;
