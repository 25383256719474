/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Import all the third party stuff
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import FontFaceObserver from 'fontfaceobserver';
import 'sanitize.css/sanitize.css';
import 'react-tabs/style/react-tabs.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import { enableMapSet } from 'immer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import queryClient from '@/core/queryClient';

// Import root app
import App from '@/components/App';


import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

import { HelmetProvider } from 'react-helmet-async';

import history from '@/core/history';
import configureStore from '@/core/configureStore';

// Import i18n messages
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import ErrorBoundary from './components/ErrorBoundary';
import { initLogRocket, shouldTrackPathInLogrocket } from './utils/logrocket';
import { OPTIMIZELY_SDK_KEY } from './config';
import api from '@/api';

// To enable Immer to operate on the native Map and Set collections
enableMapSet();

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const interObserver = new FontFaceObserver('Inter', {});

const hostName = window.location.hostname;

// Checking if event is on custom domain then redirecting to landing page (eg. event custom domain -> healthaction.familiesusa.org)
// Ignoring in case of zuddl domains
if (
  !hostName.includes('zuddl.com') &&
  !hostName.includes('zuddl.io') &&
  window.location.pathname === '/'
) {
  api.eventCustomDomain.getLandingPageByCustomDomain(hostName)
    .then(({ data: url }) => {
      if (url) {
        window.location.href = url;
      }
    });
}
if (process.env.NODE_ENV === 'production' && shouldTrackPathInLogrocket()) {
  initLogRocket();
} else if (
  process.env.NODE_ENV === 'production' &&
  window.navigator?.userAgent?.toLowerCase().indexOf('chrome/92') !== -1
) {
  initLogRocket();
}

// When Open Sans is loaded, add a font-family using Open Sans to the body
interObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

//optimizely setup
const optimizely = createInstance({
  datafile: window.optimizelyDatafile,
  sdkKey: OPTIMIZELY_SDK_KEY,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 60000, // 1 minutes in milliseconds
    urlTemplate: `https://cdn.optimizely.com/datafiles/${OPTIMIZELY_SDK_KEY}.json`,
  }
})

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app') as HTMLElement;

const ConnectedApp = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={history}>
          <HelmetProvider>
            <ErrorBoundary>
              <OptimizelyProvider
                optimizely={optimizely}
                user={{ id: 'zuddl'}}
                >
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </OptimizelyProvider>
            </ErrorBoundary>
          </HelmetProvider>
        </ConnectedRouter>
      </I18nextProvider>
    </QueryClientProvider>
  </Provider>
);
const render = () => {
  ReactDOM.render(<ConnectedApp />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

// Chunked polyfill for browsers without Intl support
if (!(window as any).Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    )
    .then(() => render())
    .catch(err => {
      throw err;
    });
} else {
  render();
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  OfflinePluginRuntime.install();
}
