import React from 'react';
import LogRocket from 'logrocket';
// Components
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import styles from './styles.module.scss';

interface GenericErrorBoundaryProps {
  fallbackText?: string;
  showFallbackUI?: boolean;
  fallbackUI?: JSX.Element;
}

class GenericErrorBoundary extends React.Component<GenericErrorBoundaryProps> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    LogRocket.captureException(error);
  }

  fallback = () => {
    if (!this.props.showFallbackUI) {
      return null;
    }
    return (
      this.props.fallbackUI || (
        <div className={styles.errorContainer}>
          <Text
            block
            text={this.props.fallbackText}
            textSize={ITextSizes.MEDIUM}
            textColor={ITextColors.NEUTRAL_DARKEST_3}
          />
        </div>
      )
    );
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.fallback();
    }

    return this.props.children;
  }
}

GenericErrorBoundary.defaultProps = {
  fallbackText: 'Something happened, please try again!',
  showFallbackUI: false,
  fallbackUI: <div />,
};

export default GenericErrorBoundary;
