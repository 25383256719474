import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '@/api';

const DyteCall = ({ meetingId, roomName }) => {
  const [authToken, setAuthToken] = useState();
  const { eventId = '' } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    if (!eventId) return;
    api.discussion.joinDyteMeeting(eventId, meetingId).then(({ data }) => {
      if (data) {
        setAuthToken(data);
      }
    });
  }, [meetingId, eventId]);

  const dyteUrlOrigin = 'https://app.dyte.in';

  function receiveMessage(evt) {
    if (evt?.origin === dyteUrlOrigin) {
      if (evt.data?.type === 'meetingEnded') {
        // take action on meeting end
        if (
          [
            '8d3f4b3d-3369-4d5e-964a-7c0d9307613d',
            '0544fee3-3d5c-4fcc-85fa-8f97c312a55d',
            '9a73b8be-8a0c-4c46-952d-2f5c980d3ee9',
          ].includes(meetingId)
        ) {
          history.push(`/l/event/${eventId}/stages/`);
        } else {
          history.push(`/l/event/${eventId}/discussions/`);
        }
        // Adding a slash at the end of this url so that RouteLeavingGuard doesn't show up
        // as it does not when we move between rooms because Dyte is already showing a leave popup.
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => window.removeEventListener('message', receiveMessage, false);
  }, [eventId]);

  if (!authToken) {
    return <div />;
  }

  return (
    <iframe
      style={{
        height: '100%',
        width: '100%',
      }}
      title="Dyte Call"
      src={`${dyteUrlOrigin}/meeting/stage/${roomName}?authToken=${authToken}&showSetupScreen=1&disableVideoBackground=true&orgId=5dcdc892-c072-4718-b389-b9fc32f1798b`}
      allow="camera;microphone;fullscreen;display-capture;"
    ></iframe>
  );
};

export default DyteCall;
