import { useQuery } from '@tanstack/react-query';
import Keys from './query.keys';
import api from './apis';
import { Message, Reaction } from './types';

const getReactionsList = (messageId: number) =>
  useQuery<
    {
      [key: string]: Reaction[];
    },
    Error
  >(Keys.reactionsList(messageId), () =>
    api.getReactionsList(messageId).then(({ data }) => data),
  );

const getMessageById = (messageId: number, enabled: boolean) =>
  useQuery<Message, Error>(
    Keys.messageById(messageId),
    () => api.getMessageById(messageId).then(({ data }) => data),
    { enabled },
  );
export { Keys as ReactionQueryKeys };

export default {
  getReactionsList,
  getMessageById,
};
