import React from 'react';
import classnames from 'classnames';
// components
import SpeakersOnStageRow from './SpeakersOnStageRow';
// config + styles + types
import styles from './styles.module.scss';
import types from './types';

const SpeakersOnStage = props => {
  const { speakers, userMoveFns, localAvConfig } = props;

  return (
    <div className={classnames(styles.speakersOnStageContainer, {[styles.scrollbarPadding]: speakers.length > 4})}>
      {speakers.map((s, idx) => (
        <SpeakersOnStageRow speaker={s} userMoveFns={userMoveFns} frame={idx+1} key={s.accountId} localAvConfig={localAvConfig} />
      ))}
    </div>
  );
};

export default SpeakersOnStage;
