import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// components
import TabMenu, { ITabMenuTypes } from '@/components/ui/menus/TabMenu';
import CurrentSessionTab from './CurrentSessionTab';
// config + styles + types
import styles from './styles.module.scss';
import { greenRoomActionPanelOptions } from './config';
// models
import {
  makeSelectEventById,
  makeSelectSpeakerListByEventId,
  makeSelectStageByEventId,
} from '@/models/event';
// hooks
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import MediaTab from './MediaTab';
import ExtrasTab from './ExtrasTab';
import GenericErrorBoundary from '@/components/GenericErrorBoundary';
import { useVideoState } from '@/services/video/video-context';
import api from '@/api';
import moment from 'moment';
import { IRaisedHandRequestStatus } from '@/components/Stage/types';
import { StreamType } from '../types';
import { throttle } from 'lodash';
import { convertInAnotherTimeZone } from '@/utils/helpers';

const GreenRoomActionPanel = ({
  liveStreamConfig,
  channelConfig,
  userMoveFns,
  streams,
  greenroomStreams,
  timerConfig,
  confettiConfig,
  videoMediaConfig,
  raisedHandRequestList,
  presentationConfig,
  breakoutConfig,
  localAvConfig,
  currentActionPanelTab,
  setCurrentActionPanelTab,
  currentVisibleMediaList,
  setCurrentVisibleMediaList,
}) => {
  const dispatch = useDispatch();
  const { eventId, stageId } = useParams();
  const eventData = useMemoizedSelector(makeSelectEventById, eventId);
  const stageData = useMemoizedSelector(makeSelectStageByEventId, {
    eventId,
    stageId,
  });
  const { startDateTime, endDateTime } = eventData;
  const { name: sessionName } = stageData;
  const [accountStreamMap, setAccountStreamMap] = useState({});
  const [organizers, setOrganizers] = useState([]);
  const [raisedHandRequests, setRaisedHandRequests] = useState([]);

  const refreshListOfPeople = () => {
    dispatch({
      type: 'event/getAllEventSpeakers',
      payload: {
        eventId,
        refresh: true,
      },
    });
    api.event.getLiveEventOrganizers(eventId).then(response => {
      if (response.data) {
        setOrganizers(response.data);
      }
    });
  };

  const throttledRefreshList = useCallback(
    throttle(refreshListOfPeople, 5000),
    [dispatch, eventId],
  );

  useEffect(() => {
    throttledRefreshList();
  }, [eventId]);

  useEffect(() => {
    const liveRequests = raisedHandRequestList.filter(
      v => v.status === IRaisedHandRequestStatus.LIVE,
    );
    const formattedData = liveRequests.map(v => ({
      accountId: v.accountId,
      firstName: v.accountName,
      isRaisedHand: true,
    }));

    setRaisedHandRequests(formattedData);
  }, [raisedHandRequestList]);

  const selectSpeakersByEventId = useMemo(
    () => makeSelectSpeakerListByEventId(eventId),
    [],
  );
  const speakers: any = useSelector(selectSpeakersByEventId);

  const raisedHandAccountIds = useMemo(
    () => raisedHandRequests.map(v => v.accountId),
    [raisedHandRequests],
  );

  const organizersAccountIds = useMemo(() => organizers.map(v => v.accountId), [
    organizers,
  ]);

  const startDateTimeInEventTz =
    eventData && convertInAnotherTimeZone(startDateTime, eventData.tz);
  const endDateTimeInEventTz =
    eventData && convertInAnotherTimeZone(endDateTime, eventData.tz);
  const sessionsData = [
    {
      name: sessionName,
      time: `${moment(startDateTimeInEventTz).format(
        'Do MMM hh:mm A',
      )} - ${moment(endDateTimeInEventTz).format('Do MMM hh:mm A')}`,
      speakers: organizers
        .filter(v => !raisedHandAccountIds.includes(v.accountId))
        .concat(
          speakers
            .filter(v => !raisedHandAccountIds.includes(v.accountId))
            .filter(v => !organizersAccountIds.includes(v.accountId)),
        )
        .concat(raisedHandRequests),
      eventTz: eventData && eventData.tz,
    },
  ];

  const { streamInfoMap = {} } = useVideoState();

  const streamsCount = streams.length;
  const greenroomStreamsCount = greenroomStreams.length;

  useEffect(() => {
    const temp = {};
    streams.forEach(element => {
      const _e = streamInfoMap[element.uid];
      if (_e) {
        const { stream: { isLocal = undefined } = {} } = element;
        if (_e.type === StreamType.SCREEN) return;
        temp[_e.accountId] = {
          role: _e.role,
          type: _e.type,
          uid: _e.uid,
          greenroom: false,
          isLocal,
        };
      }
    });
    greenroomStreams.forEach(element => {
      const _e = streamInfoMap[element.uid];
      if (_e) {
        const { stream: { isLocal = undefined } = {} } = element;
        if (_e.type === StreamType.SCREEN_RELAY) return;
        temp[_e.accountId] = {
          role: _e.role,
          type: _e.type,
          uid: _e.uid,
          greenroom: true,
          isLocal,
        };
      }
    });
    setAccountStreamMap(temp);
  }, [streamsCount, greenroomStreamsCount, streamInfoMap]);

  if (!channelConfig) {
    return <div></div>;
  }

  const Items = [
    <GenericErrorBoundary showFallbackUI>
      <CurrentSessionTab
        sessionsData={sessionsData}
        userMoveFns={userMoveFns}
        accountStreamMap={accountStreamMap}
        refreshListOfPeople={throttledRefreshList}
        localAvConfig={localAvConfig}
      />
    </GenericErrorBoundary>,
    <GenericErrorBoundary showFallbackUI>
      <MediaTab
        channelConfig={channelConfig}
        videoMediaConfig={videoMediaConfig}
        presentationConfig={presentationConfig}
        currentVisibleMediaList={currentVisibleMediaList}
        setCurrentVisibleMediaList={setCurrentVisibleMediaList}
      />
    </GenericErrorBoundary>,
    <GenericErrorBoundary showFallbackUI>
      <ExtrasTab
        channelId={channelConfig.channelId}
        liveStreamConfig={liveStreamConfig}
        timerConfig={timerConfig}
        confettiConfig={confettiConfig}
        breakoutConfig={breakoutConfig}
      />
    </GenericErrorBoundary>,
  ];

  return (
    <GenericErrorBoundary>
      <div
        data-testid="green_room_action_panel_menu"
        className={styles.greenRoomActionPanelMenu}
      >
        <TabMenu
          key="greenRoomActionPanelMenu"
          menuType={ITabMenuTypes.PRIMARY_UNDERLINE}
          options={greenRoomActionPanelOptions}
          selectedTabIndex={currentActionPanelTab}
          setCurrentTab={setCurrentActionPanelTab}
        >
          {Items}
        </TabMenu>
      </div>
    </GenericErrorBoundary>
  );
};

export default GreenRoomActionPanel;
