import React, { useMemo } from 'react';
import { ArrowDown } from 'react-feather';
// components
import ProfilePicture from '@/components/ui/ProfilePicture';
import Text, { ITextSizes, ITextColors, ITextStyles } from '@/components/ui/content/Text';
// config + styles + types
import styles from './styles.module.scss';
import { IContentColors, IGeneralSizeTypes } from '@/types';
import MicOnIcon from '@/components/ui/new-icons/MicOn';
import MicOffIcon from '@/components/ui/new-icons/MicOff';
import VideoOnIcon from '@/components/ui/new-icons/VideoOn';
import VideoOffIcon from '@/components/ui/new-icons/VideoOff';
import { IGeneralSizeTypes } from '@/types';
import ConfirmExitModal from '@/components/PublishedStreamDisplay/StreamOptionsBar/ConfirmExitModal';
import { getFullName } from '@/utils/helpers';
import useAudioVolume from '@/services/video/useAudioVolume';
import { useVideoState } from '@/services/video/video-context';

const SpeakersOnStageRow = (props) => {
  const { speaker, onDelete, frame, userMoveFns, localAvConfig = {} } = props;
  const {
    accountId,
    firstName,
    hexColor,
    imageUrl,
    lastName,
    isRaisedHand,
    // status = ISpeakerGreenRoomStatus.AT_BACKSTAGE,
    streamInfo: { uid = null, isLocal = undefined } = {},
  } = speaker;

  const { audioTrackMap = {}, videoTrackMap = {} } = useVideoState();
  const audioTrack = audioTrackMap[uid];
  const videoTrack = videoTrackMap[uid];

  const {
    audioEnabled: localAudioEnabled = false,
    videoEnabled: localVideoEnabled = false,
  } = localAvConfig;

  const {
    handleUserKickout,
    moveUserToGreenRoom,
    completeRaisedHandRequest,
  } = userMoveFns;

  const videoOn = isLocal ? localVideoEnabled : !!videoTrack;
  const audioOn = isLocal ? localAudioEnabled : !!audioTrack;

  // const { isAudioEnabled: audioOn } = useAudioVolume({
  //   audioTrack,
  //   isLocalStream: isLocal,
  //   isLocalAudioEnabled: localAudioEnabled,
  // });

  const avIconProps = {
    color: IContentColors.NEUTRAL_MID_2,
    size: 20
  };

  const showAvIndicators = !isLocal;
  const fullName = getFullName(firstName, lastName);

  // const status = useMemo(() => {
    // if(type === StreamType.MAIN_RELAY){
    //   return ISpeakerGreenRoomStatus.AT_BACKSTAGE;
    // }else if(type === StreamType.MAIN){
    //   return ISpeakerGreenRoomStatus.AT_STAGE;
    // }
    // return ISpeakerGreenRoomStatus.AT_STAGE;
  // }, [type])

  const getSpeakersOnStageButton = () => {
    // if (status === ISpeakerGreenRoomStatus.AT_BACKSTAGE) {
    //   return (
    //     <button className={styles.speakersOnStageActionButton} type="button" onClick={() => moveUserToStage(uid)}>
    //       <Check className={styles.speakersOnStageIcon} size={10} />
    //       <Text
    //         text="Put on stage"
    //         textColor={ITextColors.MONOCHROME_CONTRAST}
    //         textSize={ITextSizes.XXSMALL}
    //       />
    //     </button>
    //   );
    // }
    // if (status === ISpeakerGreenRoomStatus.AT_STAGE) {
    return (
      <ConfirmExitModal
        trigger={({ setShow }) => (
          <button
            className={styles.speakersOnStageActionButton}
            type="button"
            onClick={() => setShow(true)}
          >
            <ArrowDown className={styles.speakersOnStageIcon} size={10} />
            <Text
              text="off Stage"
              textColor={ITextColors.WHITE}
              textSize={ITextSizes.XXSMALL}
              clickable={true}
            />
          </button>
        )}
        handleConfirm={({ setShow }) => {
          if (isRaisedHand) {
            completeRaisedHandRequest(accountId);
          } else if (!videoOn) {
            handleUserKickout(uid);
          } else {
            moveUserToGreenRoom(uid);
          }
          setShow(false);
        }}
        exitText={`Are you sure you want to remove ${fullName} from stage?`}
        confirmButtonText="Yes"
      />
    );
    // }
    // return null;
    // if (status === ISpeakerGreenRoomStatus.SEND_REMINDER) {
    //   return (
    //     <button
    //       className={styles.speakersOnStageSendReminderButton}
    //       type="button"
    //     >
    //       <ArrowRight className={styles.speakersOnStageIcon} size={10} />
    //       <Text
    //         text="Send Reminder"
    //         textColor={ITextColors.WHITE}
    //         textSize={ITextSizes.XXSMALL}
    //       />
    //     </button>
    //   );
    // }
    // if (status === ISpeakerGreenRoomStatus.REMINDER_SENT) {
    //   return (
    //     <button
    //       className={styles.speakersOnStageReminderSentButton}
    //       type="button"
    //     >
    //       <Loader className={styles.speakersOnStageIcon} size={10} />
    //       <Text
    //         text="Reminder Sent"
    //         textColor={ITextColors.WHITE}
    //         textSize={ITextSizes.XXSMALL}
    //       />
    //     </button>
    //   );
    // }
  };

  return (
    <div className={styles.speakersOnStageRowContainer}>
      <div className={styles.speakersOnStageRowLeftContent}>
        {/* {getSpeakersOnStageButton()} */}
        <div className={styles.speakersOnStageProfilePicture}>
          <ProfilePicture
            hexColor={hexColor}
            name={fullName}
            iconSize={IGeneralSizeTypes.XSMALL}
            imageUrl={imageUrl}
            disableTooltip
          />
        </div>
        <div className={styles.speakersOnStageName}>
          <Text
            text={fullName}
            textColor={ITextColors.MONOCHROME_CONTRAST}
            textSize={ITextSizes.XSMALL}
            textStyle={ITextStyles.SEMI_BOLDED}
          />
        </div>
      </div>
      <div className={styles.speakersOnStageRowRightContent}>
      {/* <IconButton
          key="greemroomButton"
          tooltip="Remove Speaker"
          activeIcon={IUIIcons.NEW_MIC_OFF}
          defaultIcon={IUIIcons.NEW_MIC_ON}
          onClick={audioTrack ? () => audioTrack.setVolume(0) : undefined}
          // iconBG={IContentBGColorTypes.NEUTRAL_LIGHT_3}
          showDefault={!!audioTrack}
          type={IIconButtonTypes.ROUND_NEUTRAL_BASE_3}
          iconSize={15}
          style={{height: '32px', width: '32px'}}
        /> */}
        {/* <IconButton
          key="greemroomButton"
          tooltip="Remove Speaker"
          activeIcon={IUIIcons.ARROW_DOWN}
          defaultIcon={IUIIcons.ARROW_DOWN}
          onClick={() => moveUserToGreenRoom(uid)}
          // iconBG={IContentBGColorTypes.NEUTRAL_LIGHT_3}
          showDefault={true}
          type={IIconButtonTypes.ROUND_NEUTRAL_BASE_3}
          iconSize={15}
          style={{height: '32px', width: '32px'}}
        /> */}
        {
          showAvIndicators && (
            <div className={styles.avIndicators}>
              {audioOn ? <MicOnIcon {...avIconProps} /> : <MicOffIcon {...avIconProps} />}
              {videoOn ? <VideoOnIcon {...avIconProps} /> : <VideoOffIcon {...avIconProps} />}
            </div>
          )
        }
        {getSpeakersOnStageButton()}
      </div>
    </div>
  );
};

export default SpeakersOnStageRow;
