import { EngagementEmptyStateTypes } from "./types";
import { MessageSquare, HelpCircle, BarChart2 } from 'react-feather';

const EngagementEmptyStateConfig = {
  [EngagementEmptyStateTypes.CHAT]: {
    IconComponent: MessageSquare,
    text: 'No messages yet!',
    subtext: 'Get the conversation started below!',
    className: 'chatEmptyStateIcon'
  },
  [EngagementEmptyStateTypes.QUESTION]: {
    IconComponent: HelpCircle,
    text: 'No questions yet!',
    subtext: 'Get the discussion started below!',
  },
  [EngagementEmptyStateTypes.POLL]: {
    IconComponent: BarChart2,
    text: 'No poll yet!',
    subtext: ''
  },
};

export { EngagementEmptyStateConfig };
