import { generateSelectFieldOptionsFromStringArray } from '@/utils/helpers';

export const timeRangeSelectOptions = (() => {
  const timeIntervals = [] as string[];
  const numReps = 121;
  let i = 1;
  while (i < numReps) {
    timeIntervals.push((i * 1).toString());
    i += 1;
  }
  return generateSelectFieldOptionsFromStringArray(timeIntervals, undefined, ' mins');
})();

const numMsInSec = 1000;
export const numMsInMins = 60 * numMsInSec;
