import * as React from 'react';

import { ImagePreview, PdfPreview, VideoPreview } from '@/components/ChannelStreamsContainer/GreenRoomActionPanel/MediaTab/MediaPreview';
import { IUploadType } from '@/hooks/use-uploader';
import Text, { ITextSizes } from '@/components/ui/content/Text';
import { IContentTextColorTypes } from '@/types';

import styles from './styles.module.scss'

const SelectedItem = (props) => {
  const { mediaType, selectedItem, moveToCurrent, selectedId } = props;
  const PreviewComp = (function () {
    if (mediaType === "VIDEO") {
      return VideoPreview;
    }
    if (mediaType === "PRESENTATION") {
      return PdfPreview;
    }
    return ImagePreview;
  })();
  
  return (
    <>
      {mediaType === selectedItem?.type &&
        <div className={styles.floatingWrapper} onClick={(e) => {
          e.preventDefault();
          moveToCurrent(selectedId)
        }}>
          <PreviewComp url={selectedItem?.url} styleClass="floatingVideoPreview"/>
          <div className={styles.floatingText}>
            <Text text={"PRESENTING"} textColor={IContentTextColorTypes.NEUTRAL_LIGHT_3} textSize={ITextSizes.XXSMALL} textLineHeight='12px' clickable={true} />
            <Text text={selectedItem?.name} textColor={IContentTextColorTypes.NEUTRAL_LIGHT_3} textSize={ITextSizes.SMALL} textLineHeight='12px' clickable={true} />
          </div>
        </div>}
    </>
  )
}

export default SelectedItem;