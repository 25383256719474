import { IContentTextSizeTypes } from '@/types';
import React from 'react';
import Text from '../ui/content/Text';
import { ITextColors } from '../ui/ExpandableContent';
import styles from './styles.module.scss';
import { IFloatingToastState } from './useFloatingToast';

interface Props {
  toastList: IFloatingToastState[];
}

const FloatingToast = ({ toastList }: Props) => {
  if (!toastList?.length) {
    return null;
  }

  return (
    <div className={styles.listContainer}>
      {toastList.map(({ startIcon: StartIcon, text, id }) => (
        <div className={styles.toast} key={id}>
          {!!StartIcon && <span className={styles.startIcon}>{StartIcon}</span>}
          <Text
            className={styles.toastText}
            textColor={ITextColors.MONOCHROME_CONTRAST}
            text={text}
            textSize={IContentTextSizeTypes.SMALL}
            textLineHeight="23px"
          />
        </div>
      ))}
    </div>
  );
};

export default FloatingToast;
