import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/stage`;
export default {
  // Create
  addStageToEvent(eventId) {
    return axios.post(`${SERVICE_URL}/event/${eventId}/stage`);
  },
  // List
  getStageListForEvent(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/list`);
  },
  getStageListForEventAdmin(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/o/list`);
  },
  // Add Stage
  addStage(eventId: string, stageObject) {
    return axios.post(`${BASE_URL}/${eventId}/create`, stageObject);
  },
  // emoji
  getEmojiListByStageId(stageId: string) {
    return axios.get(`${BASE_URL}/${stageId}/emoji/list`);
  },
  updateStageName(stageId: string, name) {
    return axios.post(`${BASE_URL}/${stageId}/update/name`, { name });
  },
  deleteStage(stageId: string) {
    return axios.delete(`${BASE_URL}/${stageId}`);
  },
  getStagesSegment(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/segment`);
  },
  // Get Stage
  getStageDetailsByStageId(stageId: string) {
    return axios.get(`${BASE_URL}/${stageId}/details`);
  },
  // Update Stage
  updateStage(stageId: string, stage) {
    return axios.post(`${BASE_URL}/${stageId}/update`, stage);
  },
  // update Stages Orders
  updateStageOrders(eventId: string, sortedList) {
    return axios.post(`${BASE_URL}/${eventId}/order`, sortedList);
  },
  // remind speaker to turn on av
  remindSpeakerToTurnOnAv(stageId: string, speakerId: string) {
    return axios.post(`${BASE_URL}/${stageId}/speaker/av_reminder`, {
      stageId,
      speakerId,
    });
  },
  getStageSettingsDetails(eventId: string, stageId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${stageId}/settings`);
  },
  updateStageRecordingStatus(stageId: string, status: boolean) {
    return axios.post(`${BASE_URL}/${stageId}/recording/update/${status}`);
  },
  updateDryRunStatus(stageId: string, status: boolean) {
    return axios.post(`${BASE_URL}/${stageId}/dry_run/update/${status}`);
  },
  updateSettingsDetails(stageId: string, settingDetails: object) {
    return axios.post(`${BASE_URL}/${stageId}/settings/update`, settingDetails);
  },
  getSlotChannel(stageId: string, slotNumber: number) {
    return axios.get(`${BASE_URL}/${stageId}/slot/${slotNumber}/token`);
  },
  getSlotMixedMinusChannel(stageId: string, slotNumber: number) {
    return axios.get(`${BASE_URL}/${stageId}/slot/${slotNumber}/audio/token`);
  },
  startDryRun(stageId: string) {
    return axios.post(`${BASE_URL}/${stageId}/dry_run/start`);
  },
  stopDryRun(stageId: string) {
    return axios.post(`${BASE_URL}/${stageId}/dry_run/stop`);
  },
  // Fetch StudioId
  getStudioIdForStage(stageId: string) {
    return axios.get(`${BASE_URL}/${stageId}/studio`);
  },
  addStudioProfile(stageId: string) {
    return axios.post(`${BASE_URL}/${stageId}/add-studio-profile`);
  },
  enableLiteMode_track(stageId: string, eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/${stageId}/lite_mode/enable/track`);
  },
  disableLiteMode_track(stageId: string, eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/${stageId}/lite_mode/disable/track`);
  },
  getActiveLiteMode(eventId: string, studioId: string) {
    return axios.get<{liteModeId: string, playback: string}>(`${BASE_URL}/${eventId}/studio/${studioId}/lite_mode`);
  },
  updateLiteModeViewer(eventId: string, studioId: string) {
    return axios.post(`${BASE_URL}/${eventId}/studio/${studioId}/lite_mode/viewing`);
  },
  updateIsViewingClosedCaptions(eventId: string, studioId: string) {
    return axios.post(
      `${BASE_URL}/${eventId}/studio/${studioId}/captions/viewing`,
    );
  },
};
