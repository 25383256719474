import React, { useState } from 'react';
// components
import Modal from '@/components/ui/modals/Modal';
// Styles
import styles from './styles.module.scss';
import { X, Play } from 'react-feather';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';
import CheckBoxField from '@/components/ui/fields/CheckBoxField';

const ConfirmationPopup = props => {
  const { handleConfirmModal, showPopup, handleCancelModal } = props;
  const [isFillerVideo, setIsFillerVideo] = useState(false);
  return (
    <Modal
      disableClose={true}
      autoTrigger={showPopup}
      noHeader={true}
      noBodyPadding
      render={({ setShow }) => (
        <div className={styles.modalContainer}>
          <div className={styles.modalHeaderLayout}>
            <div className={styles.headerTitle}>Push video to stage?</div>
            <X
              className={styles.modalCloseIcon}
              size={20}
              color={getCssVar(IContentColors.WHITE)}
              onClick={() => {
                handleCancelModal()({ setShow });
              }}
            />
          </div>
          <div className={styles.modalText}>
            Please select if this is a filler video or session video.
          </div>
          <div className={styles.modalSubText}>
            Filler video will be directly broadcasted to attendee without the
            need to start any session
          </div>
          <div>
            <CheckBoxField
              checked={isFillerVideo}
              label="Yes, this is a filler video"
              onCheckBoxToggle={() => setIsFillerVideo(!isFillerVideo)}
              labelClassName={styles.modalCheckBoxLabel}
            />
          </div>

          <div className={styles.modalButtonRow}>
            <button
              type="button"
              onClick={() => {
                handleCancelModal()({ setShow });
              }}
              className={styles.modalCancelButton}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                handleConfirmModal()({ setShow }, isFillerVideo);
              }}
              className={styles.modalConfirmButton}
            >
              {isFillerVideo ? (
                <>
                  <Play
                    size={14}
                    color={getCssVar(IContentColors.WHITE)}
                    fill={getCssVar(IContentColors.WHITE)}
                  />
                  &nbsp; Start Broadcast
                </>
              ) : (
                'Proceed'
              )}
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default ConfirmationPopup;
