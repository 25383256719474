import React from 'react';

const BrowserPending = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 55.5236C16.1925 55.5236 4.99997 44.3311 4.99997 30.5236C4.99997 16.7161 16.1925 5.52356 30 5.52356C43.8075 5.52356 55 16.7161 55 30.5236C55 44.3311 43.8075 55.5236 30 55.5236ZM24.275 49.6911C21.8085 44.4593 20.3797 38.7991 20.0675 33.0236H10.155C10.6414 36.8708 12.2348 40.494 14.7414 43.4528C17.248 46.4117 20.56 48.5789 24.275 49.6911V49.6911ZM25.075 33.0236C25.4525 39.1211 27.195 44.8486 30 49.9036C32.8808 44.7149 34.5636 38.9473 34.925 33.0236H25.075V33.0236ZM49.845 33.0236H39.9325C39.6203 38.7991 38.1914 44.4593 35.725 49.6911C39.4399 48.5789 42.7519 46.4117 45.2586 43.4528C47.7652 40.494 49.3585 36.8708 49.845 33.0236V33.0236ZM10.155 28.0236H20.0675C20.3797 22.248 21.8085 16.5878 24.275 11.3561C20.56 12.4683 17.248 14.6354 14.7414 17.5943C12.2348 20.5532 10.6414 24.1763 10.155 28.0236V28.0236ZM25.0775 28.0236H34.9225C34.5619 22.1001 32.8799 16.3324 30 11.1436C27.1192 16.3322 25.4364 22.0999 25.075 28.0236H25.0775ZM35.725 11.3561C38.1914 16.5878 39.6203 22.248 39.9325 28.0236H49.845C49.3585 24.1763 47.7652 20.5532 45.2586 17.5943C42.7519 14.6354 39.4399 12.4683 35.725 11.3561V11.3561Z"
      fill="white"
    />
  </svg>
);

const BrowserSuccess = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.95694 30.4797C4.95694 44.2872 16.1494 55.4797 29.9569 55.4797C32.859 55.4797 35.6456 54.9853 38.2369 54.0761C35.3793 51.5752 33.5879 47.8901 33.6237 43.795C33.6887 36.3395 39.7854 30.3483 47.241 30.4133C50.0711 30.438 52.6902 31.3318 54.8471 32.8392C54.9198 32.0625 54.9569 31.2755 54.9569 30.4797C54.9569 16.6722 43.7644 5.47974 29.9569 5.47974C16.1494 5.47974 4.95694 16.6722 4.95694 30.4797ZM20.0244 32.9797C20.3366 38.7553 21.7655 44.4155 24.2319 49.6472C20.517 48.535 17.205 46.3679 14.6984 43.409C12.1917 40.4501 10.5984 36.827 10.1119 32.9797H20.0244ZM29.9569 49.8597C27.1519 44.8047 25.4094 39.0772 25.0319 32.9797H34.8819C34.5206 38.9034 32.8377 44.6711 29.9569 49.8597ZM20.0244 27.9797H10.1119C10.5984 24.1325 12.1917 20.5093 14.6984 17.5505C17.205 14.5916 20.517 12.4244 24.2319 11.3122C21.7655 16.544 20.3366 22.2042 20.0244 27.9797ZM29.9569 11.0997C32.8369 16.2886 34.5188 22.0562 34.8794 27.9797H25.0344H25.0319C25.3933 22.056 27.0761 16.2884 29.9569 11.0997ZM39.8894 27.9797C39.5773 22.2042 38.1484 16.544 35.6819 11.3122C39.3969 12.4244 42.7089 14.5916 45.2155 17.5505C47.7221 20.5093 49.3155 24.1325 49.8019 27.9797H39.8894Z"
      fill="white"
    />
    <path
      d="M46.5403 53.408C41.0175 53.3598 36.5797 48.8439 36.6279 43.3212C36.6761 37.7984 41.192 33.3606 46.7148 33.4088C52.2376 33.457 56.6753 37.9729 56.6272 43.4957C56.579 49.0185 52.0631 53.4562 46.5403 53.408ZM45.5957 47.3996L52.7271 40.3905L51.3255 38.9642L45.6203 44.5717L42.8161 41.7181L41.3899 43.1197L45.5957 47.3996Z"
      fill="white"
    />
  </svg>
);

const BrowserFail = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.95694 30.4797C4.95694 44.2872 16.1494 55.4797 29.9569 55.4797C32.859 55.4797 35.6456 54.9853 38.2369 54.0761C35.3793 51.5752 33.5879 47.8901 33.6237 43.795C33.6887 36.3395 39.7854 30.3483 47.241 30.4133C50.0711 30.438 52.6902 31.3318 54.8471 32.8392C54.9198 32.0625 54.9569 31.2755 54.9569 30.4797C54.9569 16.6722 43.7644 5.47974 29.9569 5.47974C16.1494 5.47974 4.95694 16.6722 4.95694 30.4797ZM20.0244 32.9797C20.3366 38.7553 21.7655 44.4155 24.2319 49.6472C20.517 48.535 17.205 46.3679 14.6984 43.409C12.1917 40.4501 10.5984 36.827 10.1119 32.9797H20.0244ZM29.9569 49.8597C27.1519 44.8047 25.4094 39.0772 25.0319 32.9797H34.8819C34.5206 38.9034 32.8377 44.6711 29.9569 49.8597ZM20.0244 27.9797H10.1119C10.5984 24.1325 12.1917 20.5093 14.6984 17.5505C17.205 14.5916 20.517 12.4244 24.2319 11.3122C21.7655 16.544 20.3366 22.2042 20.0244 27.9797ZM29.9569 11.0997C32.8369 16.2886 34.5188 22.0562 34.8794 27.9797H25.0344H25.0319C25.3933 22.056 27.0761 16.2884 29.9569 11.0997ZM39.8894 27.9797C39.5773 22.2042 38.1484 16.544 35.6819 11.3122C39.3969 12.4244 42.7089 14.5916 45.2155 17.5505C47.7221 20.5093 49.3155 24.1325 49.8019 27.9797H39.8894Z"
      fill="white"
    />
    <path
      d="M46.6678 33.4084C41.18 33.3605 36.676 37.8074 36.6279 43.3212C36.5798 48.8349 41.0265 53.3599 46.5403 53.408C52.0541 53.4562 56.579 49.0095 56.6272 43.4957C56.6753 37.9819 52.2076 33.4567 46.6678 33.4084ZM47.5839 48.417L45.5839 48.3995L45.6014 46.3996L47.6013 46.417L47.5839 48.417ZM47.6188 44.4171L45.6188 44.3997L45.6712 38.3999L47.6711 38.4173L47.6188 44.4171Z"
      fill="white"
    />
  </svg>
);

const WifiPending = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.72504 17.4925C9.72277 11.0133 19.7072 7.48482 30 7.50005C40.7125 7.50005 50.55 11.2425 58.275 17.4925L53.5625 23.325C46.8975 17.9264 38.5772 14.9867 30 15C21.075 15 12.875 18.12 6.43754 23.325L1.72504 17.4925ZM9.57754 27.2175C15.354 22.5375 22.5656 19.9889 30 20C37.735 20 44.84 22.7025 50.4225 27.215L45.71 33.0501C41.2663 29.4506 35.7187 27.4907 30 27.5001C24.05 27.5001 18.585 29.5801 14.29 33.0501L9.57754 27.215V27.2175ZM17.4325 36.9426C20.9872 34.0623 25.425 32.4936 30 32.5001C34.76 32.5001 39.1325 34.1626 42.5675 36.9401L37.855 42.7751C35.633 40.9755 32.8593 39.9956 30 40.0001C27.1407 39.9956 24.367 40.9755 22.145 42.7751L17.4325 36.9401V36.9426ZM25.2875 46.6675C26.6202 45.5868 28.2843 44.998 30 45.0001C31.785 45.0001 33.425 45.6251 34.7125 46.6651L30 52.5L25.2875 46.6651V46.6675Z"
      fill="white"
    />
  </svg>
);

const WifiSuccess = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 7.50005C19.7071 7.48482 9.7227 11.0133 1.72498 17.4925L6.43748 23.325C12.875 18.12 21.075 15 30 15C38.5771 14.9867 46.8975 17.9264 53.5625 23.325L58.275 17.4925C50.55 11.2425 40.7125 7.50005 30 7.50005ZM9.5787 27.2166C9.57829 27.2169 9.57788 27.2172 9.57748 27.2175V27.215L9.5787 27.2166ZM46.888 31.5914L50.4225 27.215C44.84 22.7025 37.735 20 30 20C22.5661 19.9889 15.355 22.5372 9.5787 27.2166L14.29 33.0501C18.585 29.5801 24.05 27.5 30 27.5C34.5175 27.4927 38.9282 28.7142 42.7724 31.002C42.8481 31.0007 42.924 31.0001 43 31.0001C44.3544 31.0001 45.6603 31.2072 46.888 31.5914ZM30.0379 45.0001C30.1867 46.9564 30.7685 48.7917 31.6877 50.4103L30 52.5L25.2887 46.6666C26.6211 45.5865 28.2847 44.998 30 45C30.0126 45 30.0253 45.0001 30.0379 45.0001ZM35.6161 33.2992C33.2862 34.91 31.5079 37.2621 30.6223 40.0146C30.4154 40.0046 30.2079 39.9997 30 40C27.1407 39.9956 24.367 40.9755 22.145 42.7751L17.4337 36.9416C20.9881 34.0619 25.4254 32.4936 30 32.5C31.9496 32.5 33.8343 32.779 35.6161 33.2992ZM17.4337 36.9416L17.4325 36.9401V36.9426C17.4329 36.9422 17.4333 36.9419 17.4337 36.9416ZM25.2887 46.6666L25.2875 46.6651V46.6675C25.2879 46.6672 25.2883 46.6669 25.2887 46.6666Z"
      fill="white"
    />
    <path
      d="M43 54C37.477 54 33 49.523 33 44C33 38.477 37.477 34 43 34C48.523 34 53 38.477 53 44C53 49.523 48.523 54 43 54ZM42.003 48L49.073 40.929L47.659 39.515L42.003 45.172L39.174 42.343L37.76 43.757L42.003 48Z"
      fill="white"
    />
  </svg>
);

const WifiFail = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 7.50005C19.7071 7.48482 9.7227 11.0133 1.72498 17.4925L6.43748 23.325C12.875 18.12 21.075 15 30 15C38.5771 14.9867 46.8975 17.9264 53.5625 23.325L58.275 17.4925C50.55 11.2425 40.7125 7.50005 30 7.50005ZM9.5787 27.2166C9.57829 27.2169 9.57788 27.2172 9.57748 27.2175V27.215L9.5787 27.2166ZM46.888 31.5914L50.4225 27.215C44.84 22.7025 37.735 20 30 20C22.5661 19.9889 15.355 22.5372 9.5787 27.2166L14.29 33.0501C18.585 29.5801 24.05 27.5 30 27.5C34.5175 27.4927 38.9282 28.7142 42.7724 31.002C42.8481 31.0007 42.924 31.0001 43 31.0001C44.3544 31.0001 45.6603 31.2072 46.888 31.5914ZM30.0379 45.0001C30.1867 46.9564 30.7685 48.7917 31.6877 50.4103L30 52.5L25.2887 46.6666C26.6211 45.5865 28.2847 44.998 30 45C30.0126 45 30.0253 45.0001 30.0379 45.0001ZM35.6161 33.2992C33.2862 34.91 31.5079 37.2621 30.6223 40.0146C30.4154 40.0046 30.2079 39.9997 30 40C27.1407 39.9956 24.367 40.9755 22.145 42.7751L17.4337 36.9416C20.9881 34.0619 25.4254 32.4936 30 32.5C31.9496 32.5 33.8343 32.779 35.6161 33.2992ZM17.4337 36.9416L17.4325 36.9401V36.9426C17.4329 36.9422 17.4333 36.9419 17.4337 36.9416ZM25.2887 46.6666L25.2875 46.6651V46.6675C25.2879 46.6672 25.2883 46.6669 25.2887 46.6666Z"
      fill="white"
    />
    <path
      d="M42.953 34C37.465 34 33 38.486 33 44C33 49.514 37.486 54 43 54C48.514 54 53 49.514 53 44C53 38.486 48.493 34 42.953 34ZM44 49H42V47H44V49ZM44 45H42V39H44V45Z"
      fill="white"
    />
  </svg>
);

export {
  BrowserPending,
  BrowserSuccess,
  BrowserFail,
  WifiPending,
  WifiSuccess,
  WifiFail,
};
