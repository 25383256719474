import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import AnimatedLoader from '@/components/ui/AnimatedLoader';
import DisconnectedIndicator from './DisconnectedIndicator';

// "DISCONNECTED" | "CONNECTING" | "RECONNECTING" | "CONNECTED" | "DISCONNECTING"

const ConnectionStatusIndicator = ({ stateCtx }) => {
  const { state, previousState, reason } = stateCtx.connectionState;
  const [showError, setShowError] = useState(false);
  const lastUpdatedState = useRef();

  // check in 1000 second if the status is still the same and then show the message

  useEffect(() => {
    // This shows the error message after 1 second of the error messsage being thrown.
    lastUpdatedState.current = state;
    if (state !== 'RECONNECTING') {
      return;
    }

    const t = setTimeout(() => {
      if (lastUpdatedState.current === 'RECONNECTING') {
        setShowError(true);
      }
    }, 2000);

    return () => {
      clearTimeout(t);
      setShowError(false);
    };
  }, [state]);

  return (
    <>
      {showError && state === 'RECONNECTING' && (
        <div className={styles.disconnectedContainer}>
          <DisconnectedIndicator />
        </div>
      )}
      {state === 'CONNECTING' && (
        <div className={styles.connectingContainer}>
          <AnimatedLoader />
        </div>
      )}
    </>
  );
};

export default ConnectionStatusIndicator;
