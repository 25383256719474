import React, { useMemo } from 'react';
import classnames from 'classnames';
// components
import RollCallRow from './RollCallRow';
// config + styles + types
import styles from './styles.module.scss';
import types from './types';
import {IAccountRoleTypeOptions} from '@/models/account/types'

function sortFunction(a, b){
  const {streamInfo: {role: aRole, greenroom: aGreenroom = false}, firstName: aFirstName} = a;
  const {streamInfo: {role: bRole, greenroom: bGreenroom = false}, firstName: bFirstName} = b;
  if(!aGreenroom){
    if(bGreenroom){
      return 1;
    }
  }
  if(!bGreenroom){
    if(aGreenroom){
      return -1
    }
  }
  if (aRole === bRole){
    return aFirstName < bFirstName ? -1 : 1
  }
  if(aRole === IAccountRoleTypeOptions.SPEAKER){
    return -1;
  }
  if(bRole === IAccountRoleTypeOptions.SPEAKER){
    return 1;
  }
  if(aRole === IAccountRoleTypeOptions.MODERATOR){
    return -1;
  }
  if(bRole === IAccountRoleTypeOptions.MODERATOR){
    return 1;
  }
  if(aRole === IAccountRoleTypeOptions.ORGANIZER){
    return -1;
  }
  if(bRole === IAccountRoleTypeOptions.ORGANIZER){
    return 1;
  }
  if(aRole === IAccountRoleTypeOptions.SPONSOR){
    return -1;
  }
  if(bRole === IAccountRoleTypeOptions.SPONSOR){
    return 1;
  }
  if(aRole === IAccountRoleTypeOptions.ATTENDEE){
    return -1;
  }
  if(bRole === IAccountRoleTypeOptions.ATTENDEE){
    return 1;
  }
  if(aFirstName < bFirstName){
    return -1;
  }
  if(aFirstName > bFirstName){
    return 1;
  }
  return 0;
}

const RollCall = props => {
  const { userMoveFns, backstageSpeakers, unavailableSpeakers, localAvConfig } = props;

  const sortedSpeakers = useMemo(() => {
    const _backstage = [].concat(backstageSpeakers);
    _backstage.sort(sortFunction);
    const _unavailable = [].concat(unavailableSpeakers);
    _unavailable.sort(sortFunction);
    return _backstage.concat(_unavailable);
  }, [backstageSpeakers, unavailableSpeakers])

  return (
    <div className={classnames(styles.rollCallContainer, {[styles.scrollbarPadding]: sortedSpeakers.length > 4})}>
      {sortedSpeakers.map(s => <RollCallRow speaker={s} userMoveFns={userMoveFns} key={s.accountId} localAvConfig={localAvConfig} />)}
    </div>
  );
};

export default RollCall;
