import type { AnyObject } from 'react-final-form';
import axios from '../axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/message`;

export default {
  addEmojiReactions(messageId: number, payload: AnyObject) {
    return axios.post(`${BASE_URL}/${messageId}/react`, payload);
  },
  getReactionsList(messageId: number) {
    return axios.get(`${BASE_URL}/${messageId}/reactions/list`);
  },
  removeEmojiReaction(messageId: number, reactionId: string) {
    return axios.delete(`${BASE_URL}/${messageId}/reactions/${reactionId}`);
  },
  getMessageById(messageId: number) {
    return axios.get(`${BASE_URL}/messages/${messageId}`);
  },
};
