import React, { useEffect, useState } from 'react';
import IconTextButton, {
  IUIIcons,
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import styles from './styles.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import api from '@/api';
import Modal from '@/components/ui/modals/Modal';
import { IModalTypes } from '@/components/ui/modals/Modal/types';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import RoomIcon from '@/components/ui/new-icons/Room';
import { IContentColors } from '@/types';
import { useDispatch } from 'react-redux';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectBreakoutRoomsConfigById } from '@/models/event';
import queryString from 'query-string';
import { STUDIO_URL } from '@/config';

const Breakout = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { eventId, discussionTableId: selectedTableId, stageId } = useParams<{
    eventId: string;
    discussionTableId: string;
    stageId: string;
  }>();
  const { stageId: breakoutConfigStageId } = props;
  const searchParams = queryString.parse(search);
  const { parentRef = 'discussions' } = searchParams;

  // below api call will fire if user refresh the page while joining the table/room.
  useEffect(() => {
    if (!stageId && breakoutConfigStageId) {
      dispatch({
        type: 'event/getBreakoutRoomsConfig',
        payload: {
          eventId,
          stageId: breakoutConfigStageId,
        },
      });
    }
  }, [eventId, stageId, breakoutConfigStageId]);

  const breakoutConfig = useMemoizedSelector(
    makeSelectBreakoutRoomsConfigById,
    breakoutConfigStageId,
  );
  const {
    inBreakout,
    breakoutType,
    breakoutId,
    breakoutRooms: propBreakoutRooms,
  } = breakoutConfig;

  const [isInBreakout, setIsInBreakout] = useState(inBreakout);
  const [breakoutUnassigned, setBreakoutUnassigned] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [breakoutRooms, setBreakoutRooms] = useState(propBreakoutRooms);

  useEffect(() => {
    setBreakoutRooms(propBreakoutRooms);
  }, [propBreakoutRooms]);

  useEffect(() => {
    setActionInProgress(false);
    setIsInBreakout(inBreakout);
  }, [inBreakout]);

  const breakoutStage = () => {
    setActionInProgress(true);
    api.breakout
      .breakoutStage(eventId, breakoutId, breakoutUnassigned)
      .then(({ data }) => {
        if (!data) return;
        setBreakoutRooms(data);
        setIsInBreakout(true);
        setActionInProgress(false);
        dispatch({
          type: 'event/updateBreakoutRoomsConfigStatus',
          payload: {
            stageId,
            status: true,
          },
        });
      })
      .catch(e => {
        // TODO: display error
        setActionInProgress(false);
      });
  };
  const bringbackStage = () => {
    setActionInProgress(true);
    api.breakout
      .bringBack(eventId, breakoutId)
      .then(() => {
        setIsInBreakout(false);
        setActionInProgress(false);
        dispatch({
          type: 'event/updateBreakoutRoomsConfigStatus',
          payload: {
            stageId,
            status: false,
          },
        });
      })
      .catch(e => {
        // TODO: display error
        setActionInProgress(false);
      });
  };

  const handleJoinRoom = (discussionTableId: string) => {
    if (parentRef?.includes('studio/')) {
      history.push(
        `/l/event/${eventId}/discussions/${discussionTableId}?parentRef=${parentRef}`,
      );
      return;
    }
    history.push(
      `/l/event/${eventId}/discussions/${discussionTableId}?parentRef=stages/${breakoutConfigStageId}`,
    );
  };

  const handleLeaveRoom = () => {
    if (parentRef?.includes('studio/')) {
      window.location.assign(`${STUDIO_URL}/${parentRef}`);
      return;
    }
    history.push(`/l/event/${eventId}/stages/${breakoutConfigStageId}`);
  };

  if (!isInBreakout) {
    return (
      <div className={styles.container}>
        <div className={styles.breakoutSettings}>
          <div className={styles.statusText}>
            {breakoutType === 'PRE_ASSIGNED'
              ? 'You have pre-assigned attendees'
              : 'We will randomly assign attendees'}
          </div>
          <hr className={styles.seperator} />
          {breakoutType === 'PRE_ASSIGNED' && (
            <div className={styles.finalChoice}>
              How would you like to assign new/unassigned attendees?
              <div className={styles.options}>
                <div
                  className={styles.option}
                  onClick={() => setBreakoutUnassigned(true)}
                >
                  <input type="radio" checked={breakoutUnassigned === true} />
                  Randomly assign to empty seats
                </div>
                <div
                  className={styles.option}
                  onClick={() => setBreakoutUnassigned(false)}
                >
                  <input type="radio" checked={breakoutUnassigned === false} />
                  Do not assign to any room
                </div>
              </div>
            </div>
          )}
          <Modal
            styleClass={IModalTypes.NEUTRAL_LIGHTEST}
            disableClose={true}
            noHeader={true}
            noBodyPadding={true}
            trigger={({ setShow }) =>
              actionInProgress ? (
                <IconTextButton
                  showDefault
                  defaultLabel="Starting Breakout Rooms"
                  defaultIcon={IUIIcons.LOADER}
                  buttonType={IIconTextButtonTypes.BCKSTG_INPROGRESS_DISABLE}
                />
              ) : (
                <IconTextButton
                  showDefault
                  defaultLabel="Start Breakout Rooms"
                  defaultIcon={IUIIcons.CHECK}
                  onClick={() => setShow(true)}
                  buttonType={IIconTextButtonTypes.BCKSTG_SUCCESS_SQUARE}
                />
              )
            }
            render={({ setShow }) => (
              <div className={styles.confirmModal}>
                <div className={styles.text}>
                  Are you sure you want to start breakout rooms
                </div>
                <div className={styles.actions}>
                  <Button
                    label="Confirm"
                    className={styles.confirm}
                    onClick={e => {
                      e.stopPropagation();
                      setShow(false);
                      breakoutStage();
                    }}
                    styleClass={IButtonTypes.DANGER_PRIMARY}
                    type="button"
                  />
                  <Button
                    className={styles.cancel}
                    label="Cancel"
                    onClick={e => {
                      e.stopPropagation();
                      setShow(false);
                    }}
                    styleClass={IButtonTypes.DANGER_NO_BG}
                    type="button"
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
  const breakoutFilteredRooms =
    breakoutRooms && breakoutRooms.filter(item => item.count > 0);
  return (
    <div className={styles.container}>
      <div className={styles.brokenStageSettings}>
        <div className={styles.heading}>
          <div className={styles.roomCount}>{`Rooms (${
            breakoutFilteredRooms ? breakoutFilteredRooms.length : 0
          })`}</div>
          <Modal
            styleClass={IModalTypes.NEUTRAL_LIGHTEST}
            disableClose={true}
            noHeader={true}
            noBodyPadding={true}
            trigger={({ setShow }) => (
              <div
                className={styles.bringBack}
                onClick={() => !actionInProgress && setShow(true)}
              >
                {actionInProgress ? 'Ending All Rooms' : 'End All Rooms'}
              </div>
            )}
            render={({ setShow }) => (
              <div className={styles.confirmModal}>
                <div className={styles.text}>
                  Are you sure you want to end breakout rooms
                </div>
                <div className={styles.actions}>
                  <Button
                    label="Confirm"
                    className={styles.confirm}
                    onClick={e => {
                      e.stopPropagation();
                      setShow(false);
                      bringbackStage();
                    }}
                    styleClass={IButtonTypes.DANGER_PRIMARY}
                    type="button"
                  />
                  <Button
                    className={styles.cancel}
                    label="Cancel"
                    onClick={e => {
                      e.stopPropagation();
                      setShow(false);
                    }}
                    styleClass={IButtonTypes.DANGER_NO_BG}
                    type="button"
                  />
                </div>
              </div>
            )}
          />
        </div>
        {breakoutFilteredRooms && breakoutFilteredRooms.length > 0 && (
          <div className={styles.rooms}>
            {breakoutFilteredRooms.map(room => {
              const { roomNumber, count, discussionTableId } = room;
              return (
                <div className={styles.room}>
                  <RoomIcon size={13} color={IContentColors.WHITE} />
                  <div
                    className={styles.roomName}
                  >{`Room ${roomNumber} (${count})`}</div>
                  {selectedTableId && selectedTableId === discussionTableId ? (
                      <div
                        className={styles.joinButton}
                        onClick={handleLeaveRoom}
                      >
                      Leave Room
                      </div>
                    ) :
                    (
                      <div
                        className={styles.joinButton}
                        onClick={() => handleJoinRoom(discussionTableId)}
                      >
                      Join Room
                      </div>
                    )
                  }
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Breakout;
