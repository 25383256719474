import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import localforage from 'localforage';

// utils
import loadable from '@/utils/loadable';

// layouts
import PublicMenuLayout from '@/layouts/PublicMenuLayout';
// pages
const EventLandingPage = loadable(() => import('@/pages/public/EventLandingPage'));
import WidgetPage from '@/pages/WidgetPage';
import CertificateWidget from '@/components/custom/widgets/CertificateWidget';
import SlugLandingPage from '@/pages/public/SlugLandingPage';
import HealthCheck from '@/pages/public/HealthCheck';
import WorkOSAuthPage from '@/pages/public/WorkOSAuthPage';
// components
import ErrorPage from '@/components/ErrorPage';
import ManualLayout from '@/components/custom/layouts/ManualLayout';
import Toast from '@/components/ui/toast';
// helpers + utils
import useInjectModel from '@/core/useInjectModel';
// api + models
import { setGlobalErrorResponseInterceptor } from '@/api/axios';
import globalModel from '@/models/global';
import notificationsModel from '@/models/notifications';
import accountModel, {
  selectIsCheckingAuth,
  selectCurrentUser,
} from '@/models/account';
import eventModel from '@/models/event';
import eventFeedModel from '@/models/eventFeed';
import integrationModel from '@/models/integration';
import questionModel from '@/models/question';

//hooks
import { useMediaQuery } from 'react-responsive';
// styles
import '@/global.scss';
import { _isEmpty, _isUndefined } from '@/utils/jsHelpers/obj';
import LoginPage from '@/pages/public/LoginPage';

const LiveEventApp = loadable(() => import('./LiveEventApp'));
const EventDashboardApp = loadable(() => import('./EventDashboardApp'));
const AuthApp = loadable(() => import('./AuthApp'));
import Version from '@/pages/public/Version';

const RecordingStagePage = loadable(() => import('@/pages/live-event/RecordingStagePage'));
const AudienceGalleryPage = loadable(() => import('@/pages/live-event/AudienceGalleryPage'));
const ContributorSlotPage = loadable(() => import('@/pages/live-event/ContributorSlotPage'));
const ContributorAudioSlotPage = loadable(() => import('@/pages/live-event/ContributorAudioSlotPage'));

import { SETUP_BASE_URL } from '@/config';
import MagicLinkPage from '@/pages/public/MagicLinkPage';
const EditRegistrationPage = loadable(() => import('@/pages/live-event/EditRegistrationPage'));
const Embed = loadable(() => import('@/components/Embed'));
const EmbedAuth = loadable(() => import('@/components/EmbedAuth'));
const RecordingRoomPage = loadable(() => import('@/pages/live-event/RecordingRoomPage'));
const AuthFlow = loadable(() => import('@/components/AuthFlow'));
const RegistrationDeclinePage =loadable(() => import('@/pages/public/RegistrationDeclinePage'));


const AppInner = () => {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  // added this state to keep track of beamer button
  const [showBeamerButton, setShowBeamerButton] = useState(false);

  useLayoutEffect(() => {
    if (location.pathname.includes('payByCheck/checkout')) {
      let myParent = window.top;
      if (window.parent !== window.top) {
        myParent = window.parent;
      }
      myParent?.postMessage(
        {
          action: 'PAY_BY_CHECK_VIEW_LOADING',
        },
        '*',
      );
    }
  }, []);


  const currentUser = useSelector(selectCurrentUser);

  setGlobalErrorResponseInterceptor((error) => {
    const authPageRoutes = [
      '/a/signin',
      '/a/signup',
      '/a/reset/request',
      '/a/reset/',
      // WorkOS (SSO) related
      '/auth/sso',
      '/a/sso',
      // LinkedIn Redirect URI
      '/a/linkedin',
      '/p/event/',
      '/p/a/event/',
      '/p/m/event/',
      '/p/m/a/event/',
      '/s/',
      '/s/a/',
      '/embed/a/event',
      '/embed/auth/event',
      '/embed/vivenu',
    ];

    const isAuthPage = authPageRoutes.some(authRoute => {
      return location.pathname.includes(authRoute);
    });

    const liveEventPageRouts = [
      '/l/event'
    ];

    const isLiveEventPage = liveEventPageRouts.some(authRoute => {
      return location.pathname.includes(authRoute);
    });

    const statusCode = error.response ? error.response.status : null;
    const requestUrl = error && error.response && error.response.config && error.response.config.url;
    const shouldRedirect = (statusCode === 401 || statusCode === 403) && !isAuthPage && location.pathname !== '/' && (requestUrl.includes('/account/details') || requestUrl.endsWith('/role'));

    // this is for mobile iframe login. authentication will happening in LiveEventLayout component only.
    // No need to redirect to login page for this.
    const isMobileWebview =
      location.search.startsWith('?auth_token=') &&
      (location.pathname.endsWith('/lobby') ||
        location.pathname.includes('/booth/'));

    if (shouldRedirect && !isMobileWebview) {
      if (isLiveEventPage) {
        let eventId = location.pathname.split('/')[3]
        const path = history.push(`/p/a/event/${eventId}?ref=${location.pathname}${location.search}`);
        window.location.reload(true);
      } else if (
        location.search.startsWith('?s=') &&
        (location.pathname.startsWith('/l/a/r/') ||
          location.pathname.startsWith('/l/r/'))
      ) {
        // this is for webpage recording login. authentication will happening in RecordingStagePage/RecordingRoomPage component only.
        // No need to redirect to login page for this.
      } else {
        history.push(`/a/signin?ref=${location.pathname}`);
      }
    }
  });

  localforage.config({
    name: 'zuddl',
    version: 1.0,
  });

  const checkingAuth = useSelector(selectIsCheckingAuth);
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch({
        type: 'account/checkAuth',
        payload: {
          ref: location.pathname,
        },
      });
  }, []);

  useEffect(() => {
    const handleUsingMouse = () => {
      document.body.classList.add('using-mouse');
    }

    document.addEventListener('mousedown', handleUsingMouse);
    return () => {
      document.removeEventListener('mousedown', handleUsingMouse);
    }
  },[])

  useEffect(() => {
    if (!currentUser || !currentUser.accountId) {
      return;
    }

    const aapIds = [
      '167d1a80-83d3-40c5-8639-c8f4ac04da58',
      '9bedbfc2-6d93-4c51-981f-1e934638c584',
      '68cdb19c-9428-4728-886d-e3e8df576368',
      '83b1b6e4-7f3d-4a48-9a60-6d105fa098ef',
      '7c93253d-ab2e-4053-9bff-8d19f531115a',
      '854bd7e1-1573-4ab4-a33c-929de7f9cd5f',
      '6ab06821-8e85-46a3-9543-50cbbf3d3824',
      '5a09e21a-385a-4ed5-baa7-4a6c2b63c8a5',
      'dc223809-a6f9-4ae6-972a-27cba6c28e99',
      'e8226ddf-d33d-45dd-acc8-b6b81bdd8d99',
      '854bd7e1-1573-4ab4-a33c-929de7f9cd5f',
      'cdaebd92-37bf-46e7-98f8-063b82e84870',
      '323aaa05-f530-4ab2-9c15-60fa9803fe26',
      '09aca1fa-a3ae-4cd3-ad71-37503e5542da',
      '542d41b3-1578-483f-b8e9-d6ac438bc01a',
      '246edfec-7a68-452a-bf43-e8c4a3b9ac6a',
      'ab75979b-72fb-4943-a71e-99cb9011c6ba',
      'f96b083b-f685-4ac0-8516-368ed81a3d9e',
      '9f3101a6-6fef-4761-820b-b4cbb24cf2ab',
      '61b0cba0-2080-4a8b-b83a-7d9af3bd9c23',
      '3e75d4eb-c579-44bc-b4cb-fa890b5b38c4',
      '928a8488-03e1-4b59-b59f-ca91bf69b852',
      'eb5c9a67-e6e9-4d40-a824-745c51a3ed31',
      'cd38a606-0e11-4cb3-bad9-8e7648bc6e39',
      'd5f170ee-7a85-4cea-a1b5-ece503252415',
      '2f316c93-baeb-4d09-b8f2-98ee869f244a',
      '23fc530e-efcf-4d3d-a552-3fea92ecb519',
      'b5184e86-cbc6-4357-929b-bc6107e167cc',
      '74f03ae2-45a8-46b7-8dd1-c9083804ecc8',
      'c7c11eea-f0bd-43da-9570-d651d4e2622e',
      '048c88e9-24d9-4142-99bb-d5a41ff191f9',
      '0d507a40-8dd1-4334-acf0-8c76997916f5',
      '85631067-3e29-4a56-8eb1-962612926a97',
      '572743b2-1d9b-4882-bf9c-f708ee383d28',
      '5429492f-7b18-4d8c-bc06-0f858ef5c051',
      '2d51757a-d7c9-4924-b99e-4a0c161719bb',
      '09a73866-d3f0-47e0-8f7f-0c2462614644',
      '8a42a0a0-b79e-4e1d-aee3-8e2f50297da0',
      '27a40586-8226-4eac-af6c-77350fa7d0dd',
      '6699b2bb-c541-42fd-9758-cf3ede67a7a9',
      '66ac0253-7aec-41a6-8267-567576b57ff0',
      '8840dad0-67b8-4fd4-b56c-e30713a62c50',
      '643ee2d6-6954-4bfe-9ab6-5fb1722e6e2e',
      'a3c40a78-a1fb-40a1-a4cb-7d1ca0d4c947',
      '5fc38c80-2a65-43d6-86db-96ceced24530',
      'e85913d6-2713-4f31-b310-ca81d45ae98d',
      '3a16a762-1d96-4817-b341-c36de940bb7f',
      '005b8a2b-352b-42fc-a16a-cb6d2515f92b',
      'a598e2ec-49c9-40c2-a3ac-55ac0c23375f',
      'b243fefa-5804-42dc-a4ec-91eab856f43c',
      '0857fbe0-d705-4393-a8ed-4b9ddb76c175',
      '6107b029-b9ba-4548-bf48-1062954e7256',
      'bc2e4b1c-f72b-4d2c-a5d6-e4ac531ddf31',
      'e8b92feb-4dc8-47b1-be29-05fae897ff4b',
      '45a6bde9-83b2-454f-ab70-ea59407bd1de',
      '628dcf66-93d7-422e-b856-5ae948505e48',
      '00fbe821-9a58-49b8-bcf7-6de6c0614327',
      'e3d67d76-805d-4d27-b2c8-f28c6ea2fcbd',
      'b4de5301-8a5e-4b96-b4d8-a33ccd471e09',
      '1ad787d6-5f9c-47eb-a973-74249ddb7c61', // local test event
      '33c1f4b1-fdfa-4f10-8dbc-741f24bdfcdb', // preprod event
      'b3b709cd-6012-4b33-a65f-6881bd006971',
      '23982f2d-ed21-49dd-9518-e226cec7331f',
      '90d108ce-8911-4786-a1ba-d8d5a445a738',
    ];
    const aapEmailDomains = [
      'aap.com',
      'advance-auto.com',
      'motoshop.com',
      'worldpac.com',
      'autopartintl.com',
      'carquest.com',
      'advance-taipei.com',
      'stores.carquest.com',
      'zuddl.com',
    ];
    aapIds.forEach(eventId => {
      if (location.pathname.includes(`/p/a/event/${eventId}`)) {
        let isAAPDomain = false;
        aapEmailDomains.forEach(domain => {
          if (currentUser?.email?.includes(domain)) {
            isAAPDomain = true;
          }
        });
        if (!isAAPDomain) {
          // Logout the user
          dispatch({
            type: 'account/logoutNoRedirect',
          });
        }
      }
    });
  }, [currentUser]);

  if (checkingAuth) {
    return <div></div>;
  }

  return (
    <>
      {/** This is for chrome checkup modal in login page, to fix issue with lazyloading as the modal divs are inside child layouts */}
      <div id="app-modal" />
      <Switch>
        {/* Organizer Routes */}
        {/* <Redirect exact from="/" to={{ pathname: '/a/signup/new', search: '?redirect=true' }} /> */}
        {!currentUser ||
          (_isEmpty(currentUser) && (
            <Redirect
              exact
              from="/"
              to={{ pathname: '/a/signin', search: '?redirect=true' }}
            />
          ))}
        {currentUser && (
          <Redirect exact from="/" to={{ pathname: SETUP_BASE_URL }} />
        )}

        {/* Recording Page */}
        <Route
          exact
          path="/l/r/event/:eventId/discussions/:discussionTableId"
          component={RecordingRoomPage}
        />
        <Route
          exact
          path="/l/r/event/:eventId/stages/:stageId"
          component={RecordingStagePage}
        />
        {/* Recording Page */}
        <Route
          exact
          path="/l/a/r/event/:eventId/stages/:stageId"
          component={RecordingStagePage}
        />

        {/* Recording Page */}
        <Route
          exact
          path="/l/event/:eventId/stages/:stageId/gallery"
          component={AudienceGalleryPage}
        />
        <Route
          exact
          path="/l/event/:eventId/stages/:stageId/slot/:slotNumber/av"
          component={ContributorAudioSlotPage}
        />
        <Route
          exact
          path="/l/event/:eventId/stages/:stageId/slot/:slotNumber"
          component={ContributorSlotPage}
        />

        <Route exact path="/widgets" component={WidgetPage} />
        <Route exact path="/manual" component={ManualLayout} />
        <Route exact path="/certificate" component={CertificateWidget} />
        {/* Live Event Pages */}
        <Route path="/l/event/:eventId">
          {/* Find all routes, layouts and component definitions inside below loadable app.*/}
          <LiveEventApp />
        </Route>

        {/* Event Dashboard Pages */}
        <Route path="/event/:eventId">
          {/* Find all routes, layouts and component definitions inside below loadable app.*/}
          <EventDashboardApp showBeamerButton={showBeamerButton} toggleBeamerButton={(val) => setShowBeamerButton(val)} />
        </Route>

        <Route path="/login" component={LoginPage} />

        {/* WorkOS Authentication - SSO Code Verification route */}
        <Route path="/auth/sso/" component={WorkOSAuthPage} />

        {/* Auth Pages */}
        <Route path="/a">
          {/* Find all routes, layouts and component definitions inside below loadable app.*/}
          <AuthApp />
        </Route>

        {/* Public Pages */}
        <Route path="/">
          <PublicMenuLayout>
            <Route exact path="/p/healthCheck" component={HealthCheck} />
            {/* Speaker Landing Page */}
            <Route
              exact
              path="/p/event/:eventId"
              render={() => <EventLandingPage isSpeakerPage={true} />}
            />
            <Route
              exact
              path="/p/m/event/:eventId"
              render={() => <MagicLinkPage isSpeakerPage={true} />}
            />

            <Route exact path="/version" component={Version} />
            <Route
              exact
              path="/s/:eventSlug"
              component={SlugLandingPage}
            />
            <Route
              exact
              path="/s/a/:eventSlug"
              component={SlugLandingPage}
            />
            {/* Attendee Landing Page */}
            <Route
              exact
              path="/p/a/event/:eventId"
              component={EventLandingPage}
            />
            <Route
              exact
              path="/p/m/a/event/:eventId"
              component={MagicLinkPage}
            />
            <Route exact path="/error" component={ErrorPage} />

            <Route
              exact
              path="/:eventSlug"
              component={SlugLandingPage}
            />

            <Route exact path="/p/a/event/:eventId/invite/rsvp-status" component={RegistrationDeclinePage} />

            {/* User and Ticketing Mapping Page */}
            <Route
              exact
              path="/p/event/:eventId/transaction/:transactionId"
              component={EditRegistrationPage}
            />
            <Route
              exact
              path="/p/event/:eventId/registration/modify/:registrationId"
              component={EditRegistrationPage}
            />

            {/* {Embeddable Registration Page} */}
            <Route
              exact
              path="/embed/a/event/:eventId"
              component={Embed}
            />

            {/* {Embeddable Auth Component} */}
            <Route
              exact
              path="/embed/auth/event/:eventId"
              component={EmbedAuth}
            />

            <Route exact path="/auth/p/event/:eventId" component={AuthFlow} />
          </PublicMenuLayout>
        </Route>
      </Switch>
      <Toast position={isMobile ? 'topRight' : 'bottomRight'} />
    </>
  );
};

const App = () => {
  useInjectModel(globalModel);
  useInjectModel(accountModel);
  useInjectModel(eventModel);
  useInjectModel(eventFeedModel);
  useInjectModel(notificationsModel);
  useInjectModel(integrationModel);
  useInjectModel(questionModel);

  return <AppInner />;
}

export default App;
