import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import ImageAddIcon from '@/components/ui/new-icons/ImageAdd';
import PlayCircle from '@/components/ui/new-icons/PlayCircle';
import StopCircle from '@/components/ui/new-icons/StopCircle';
import Video from '@/components/ui/media/Video';

import styles from './styles.module.scss';
import { IContentColors } from '@/types';
import { IUploadType } from '@/hooks/use-uploader';
import Modal from '@/components/ui/modals/Modal';
import { IModalTypes } from '@/components/ui/modals/Modal/types';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import ConfirmExitModal from '@/components/PublishedStreamDisplay/StreamOptionsBar/ConfirmExitModal';
import ConfirmationPopup from './ConfirmationPopup';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectRecordingSessionObj } from '@/models/event';
import { SessionBroadcastStatus } from '@/components/PublishedStreamDisplay/SessionSelectBar/types';
import Options from '@/components/ui/new-icons/Options';
import Dropdown from '../Dropdown';
import CloseLine from '@/components/ui/new-icons/CloseLine';
import GridIcon from '@/components/ui/icons/GridIcon';
import SharedFilePreview from '@/components/PublishedStreamDisplay/StreamsDisplay/SharedFileView';
import { IStageParent } from '@/components/Stage/types';

export const ImagePreview = ({ url, styleClass }) => <img className={classnames(styles.image,styles[styleClass])} src={url} />;

export const VideoPreview = ({ url, styleClass= "mediaVideo" }) => (
  <Video
    styleClass={styleClass}
    loop={true}
    url={url}
    autoplay={false}
    playsinline={true}
    controls={true}
    showLoader={false}
    muted={false}
    deletable={false}
  />
);

export const PdfPreview = ({ url, styleClass }) => <img className={classnames(styles.image,styles[styleClass])} src={`${url}?fm=jpg`} />;

const MediaPreview = ({
  selected,
  onDelete,
  onClick,
  name,
  url,
  mediaType,
  channelId,
  mediaId,
  currentlySelectedMediaId,
  isItemClickDisabled=false,
}) => {
  const PreviewComp = (function () {
    if (mediaType === IUploadType.VIDEO) {
      return VideoPreview;
    }
    if (mediaType === IUploadType.PDF_FILE) {
      return PdfPreview;
    }
    return ImagePreview;
  })();

  const { broadcastString, BroadCastIcon } = (function () {
    if (isItemClickDisabled) {
      return {
        broadcastString: 'Another overlay image/video is already broadcasting',
        BroadCastIcon: StopCircle,
      };
    }
    if (selected) {
      return {
        broadcastString: 'Stop Broadcasting',
        BroadCastIcon: StopCircle,
      };
    }
    if (mediaType === IUploadType.VIDEO) {
      return {
        broadcastString: 'Broadcast Video Now',
        BroadCastIcon: PlayCircle,
      };
    }

    if (mediaType === IUploadType.PDF_FILE) {
      return {
        broadcastString: 'Broadcast Presentation Now',
        BroadCastIcon: PlayCircle,
      };
    }

    return {
      broadcastString: 'Broadcast Image Now',
      BroadCastIcon: ImageAddIcon,
    };
  })();

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const { eventId = '', stageId = '' } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const currentSession = useMemoizedSelector(
    makeSelectRecordingSessionObj,
    stageId,
  );

  const handleVideoPushConfirm = () => (
    { setShow },
    isFillerVideo: boolean,
  ) => {
    setShowConfirmationPopup(false);
    setShow(false);
    onClick(isFillerVideo);
  };

  const handleVideoPushCancel = () => ({ setShow }) => {
    setShowConfirmationPopup(false);
    setShow(false);
  };

  const handleMediaClick = () => ({ setShow }) => {
    if (selected) {
      setShow(true);
      return;
    }
    if (mediaType === IUploadType.IMAGE) {
      onClick();
      return;
    }
    if (mediaType === IUploadType.PDF_FILE) {
      setShow(true);
      return;
    }
    // if session already started no need to ask confirmation
    if (
      currentSession &&
      currentSession.broadcastStatus === SessionBroadcastStatus.STARTED
    ) {
      onClick();
      return;
    }
    setShowConfirmationPopup(true);
  };

  const {
    confirmationPopupExitText,
    confimationPopopConfirmButtonText,
  } = (() => {
    let typeString = 'Image';
    if (mediaType === IUploadType.VIDEO) {
      typeString = 'Video';
    } else if (mediaType === IUploadType.PDF_FILE) {
      typeString = 'Presentation';
    }
    // Default
    let _confirmationPopupExitText = `Are you sure you want to start broadcasting ${name}(${typeString})?`;
    let _confimationPopopConfirmButtonText = 'Yes';

    // If the broadacasted video is selected
    if (currentlySelectedMediaId) {
      // And, it's the one that is already playing on stage
      if (currentlySelectedMediaId === mediaId) {
        _confirmationPopupExitText = `Are you sure you want to stop broadcasting ${name}(${typeString})?`;
        _confimationPopopConfirmButtonText = 'Yes';
      } else {
        let suffix = 'broadcasted image';
        if (mediaType === IUploadType.VIDEO) {
          suffix = 'push video';
        } else if (mediaType === IUploadType.PDF_FILE) {
          suffix = 'presentaion';
        }
        _confirmationPopupExitText = `Are you sure you want to change the selected ${suffix}?`;
        _confimationPopopConfirmButtonText = 'Yes, Change';
      }
    }

    return {
      confirmationPopupExitText: _confirmationPopupExitText,
      confimationPopopConfirmButtonText: _confimationPopopConfirmButtonText,
    };
  })();

  // Dropdown
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const [showDeleteModal , setShowDeleteModal] = useState(false);
  const [showViewModal , setShowViewModal] = useState(false);
  const dropdownOptions = [
    {
      label: 'View File', onClick: (e) => {
        e.stopPropagation();
        setShowViewModal(true)
      }
    },
    {
      label: 'Delete File',
      onClick: (e) => {
        e.stopPropagation();
        setShowDeleteModal(true);
      }
    },
  ];

  const dropdownContainerRef = useRef();
  const handleClickOutside = () => {
    setShowOptionDropdown(false);
  };

  const [totalPages,setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
    <ConfirmationPopup
      showPopup={showConfirmationPopup}
      handleConfirmModal={handleVideoPushConfirm}
      handleCancelModal={handleVideoPushCancel}
    />
      {showViewModal && (
        <div className={styles.viewModalWrapper}>
          <Modal
            styleClass={IModalTypes.NEUTRAL_LIGHTEST}
            autoTrigger
            onModalClose={() => {
              setShowViewModal(false);
            }}
            noHeader
            customBodyPaddingStyles={styles.bodyPaddingStyles}
            render={({ setShow: setDisplayViewModal }) => (
              <div className={styles.viewBody}>
                <div className={styles.closeButton} onClick={() => {
                  setDisplayViewModal(false);
                  setShowViewModal(false);
                }}>
                  <CloseLine color={IContentColors.WHITE} />
                </div>
                {mediaType === IUploadType.PDF_FILE ?
                  <SharedFilePreview
                    fileUrl={url}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    hasControl
                    stopPresentation={() => undefined}
                    parent={IStageParent.LIVE_BACKSTAGE}
                    dims={{
                      height: 500,
                      width: 500
                    }}
                    forceShowStopBroadcastIcon={false}
                    isLoading={false}
                    /> :
                <PreviewComp url={url} styleClass='modalPreview' />}
              </div>
            )}
          />
        </div>
      )}
      {showDeleteModal && (<Modal
        title={`Are you sure, you want to delete ${(() => {
          if (mediaType === IUploadType.VIDEO) {
            return 'Video';
          }
          if (mediaType === IUploadType.PDF_FILE) {
            return 'Presentation';
          }
          return 'Image';
        })()}?`}
        styleClass={IModalTypes.NEUTRAL_LIGHTEST}
        disableClose={true}
        noHeader={false}
        autoTrigger
        onModalClose={() => {
          setShowDeleteModal(false);
        }}
        render={({ setShow: setDisplayDeleteModal }) => (
          <div className={styles.btnMenu}>
            <Button
              label="Cancel"
              className={styles.cancelButton}
              onClick={e => {
                e.stopPropagation();
                setDisplayDeleteModal(false);
                setShowDeleteModal(false);
              }}
              styleClass={IButtonTypes.PRIMARY_NO_BG_INLINE}
              type="button"
            />
            <Button
              className={styles.confirmButton}
              label="Delete"
              onClick={e => {
                e.stopPropagation();
                setDisplayDeleteModal(false);
                setShowDeleteModal(false);
                onDelete();
              }}
              styleClass={IButtonTypes.PRIMARY}
              type="button"
            />
          </div>
        )}
      />)}

    <ConfirmExitModal
      trigger={({ setShow }) => (
        <div
          className={classnames({[styles.container]: !isItemClickDisabled ,[styles.disabledClick]:isItemClickDisabled})}
          onClick={() => handleMediaClick()({ setShow })}
        >
          <div className={styles.nameWrapper}>
            <Text
              text={name}
              textColor={ITextColors.NEUTRAL_DARKEST}
              textSize={ITextSizes.XSMALL}
            />
            {!selected && (
              <Text
                text="(Click to Broadcast)"
                textColor={ITextColors.NEUTRAL}
                textSize={ITextSizes.XSMALL}
              />
            )}
              {!selected && (<GridIcon size={12} />)}
            </div>
            <div
              className={classnames(styles.imageWrapper, {
                [styles.selectedImageWrapper]: selected,
              })}
            >
              <PreviewComp url={url} />
              <div className={classnames(styles.actionWrapper,{[styles.disabledActionWrapper]: isItemClickDisabled})}>
                {!selected && (<div className={styles.optionDropdown}>
                  <div
                    aria-hidden
                    className={styles.deleteAction}
                    onClick={e => {
                      e.stopPropagation();
                      setShowOptionDropdown(!showOptionDropdown);
                    }}
                  >
                    <Options
                      size={12}
                      color={IContentColors.NEUTRAL_CONTRAST_3}
                    />
                  </div>
                  {showOptionDropdown && (
                    <div className={styles.dropDownOptions}>
                    <Dropdown
                      dropdownRef={dropdownContainerRef}
                      options={dropdownOptions}
                      onClick={handleClickOutside}
                      id={mediaId}
                    />
                    </div>
                  )
                  }
                </div>)}
                <div className={styles.broadcastText}>
                {!isItemClickDisabled && <BroadCastIcon size={12} color={IContentColors.DANGER} />}
                  <Text
                    text={broadcastString}
                    textSize={ITextSizes.XSMALL}
                    textColor={ITextColors.DANGER}
                  />
                </div>
              </div>
            </div>
          </div>
      )}
      handleConfirm={({ setShow }) => {
        onClick();
        setShow(false);
      }}
      exitText={confirmationPopupExitText}
      confirmButtonText={confimationPopopConfirmButtonText}
    />
    </>
  );
};

export default MediaPreview;
