import React, { useEffect, useMemo, useState } from 'react';
import api from '@/api';
import classnames from 'classnames';
import styles from './styles.module.scss';
import MediaList from './MediaList';
import AddMedia from './AddMedia';
import { makeSelectCurrentOverlayForChannel } from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { useDispatch } from 'react-redux';
import { IUploadType } from '@/hooks/use-uploader';
import ExpandableContent, {
  IUIIcons,
  IBGColors,
} from '@/components/ui/ExpandableContent';
import { IUseChannelPresentationResponse } from '@/services/messaging/useChannelPresentation.types';
import GenericErrorBoundary from '@/components/GenericErrorBoundary';
import SelectedItem from './SelectedItem';
import Modal from '@/components/ui/modals/Modal';
import { IModalTypes } from '@/components/ui/modals/Modal/types';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';

const InitialSelection = {
  OVERLAY: null,
  VIDEO: null,
  PRESENTATION: null,
};

enum MediaTypes {
  OVERLAY = 'OVERLAY',
  VIDEO = 'VIDEO',
  PRESENTATION = 'PRESENTATION',
}

enum CompareKeys {
  'OVERLAY' = 'channelImageId',
  'VIDEO' = 'channelVideoId',
  'PRESENTATION' = 'channelPresentationId',
}

const MediaTab = ({
  channelConfig,
  videoMediaConfig,
  presentationConfig,
  currentVisibleMediaList,
  setCurrentVisibleMediaList,
}) => {
  const { channelId } = channelConfig;

  const [overlayImages, setOverlayImages] = useState([]);
  const [currentlyBroadcasting, setCurrentlyBroadcasting] = useState(
    InitialSelection,
  );
  const [tempSelection, setTempSelection] = useState({
    type: '',
    media: {},
  });
  const [displayOverlapPopup, setDisplayOverlapPopup] = useState(false);
  const [, setBackgroundImages] = useState([]);
  const [addImageConfig, setAddImageConfig] = useState<object | undefined>();
  const [moveToId, setMoveToId] = useState(-1);
  const currentOverlay = useMemoizedSelector(
    makeSelectCurrentOverlayForChannel,
    channelId,
  );

  useEffect(() => {
    if (!channelConfig || !channelConfig.channelId) {
      return;
    }
    getChannelImagesByChannel(channelConfig.channelId);

    // eslint-disable-next-line consistent-return
    return () => {
      setCurrentVisibleMediaList(undefined);
    };
  }, [channelConfig]);

  const getChannelImagesByChannel = id => {
    api.channel
      .getChannelImagesByChannel(id)
      .then(({ data: channelImages }) => {
        // setChannelImages(data);
        const backgroundImages = [];
        const overlayImages = [];

        channelImages.sort().forEach(image => {
          if (image.type === MediaTypes.OVERLAY) {
            overlayImages.push(image);
          } else {
            backgroundImages.push(image);
          }
        });

        setOverlayImages(overlayImages);
        setBackgroundImages(backgroundImages);
      });
  };

  const dispatch = useDispatch();

  const handlePresentation = media => {
    if (media.channelPresentationId === currentPresentation?.presentationId) {
      stopPresentation();
      emptySelection(MediaTypes.PRESENTATION);
      return;
    }
    setCurrentlyBroadcasting(prev => ({
      ...prev,
      [MediaTypes.PRESENTATION]: { ...media, type: MediaTypes.PRESENTATION },
    }));
    startPresentation(media.channelPresentationId);
  };

  const emptySelection = type => {
    setCurrentlyBroadcasting(prev => ({ ...prev, [type]: null }));
  };

  const handleBroadCast = (selected, type) => {
    let currentSelectedCopy = { ...currentlyBroadcasting };
    delete currentSelectedCopy[type];
    //when nothing is being broadcasted or same media item is clicked
    if (
      Object.values(currentSelectedCopy).every(item => !item) ||
      (currentlyBroadcasting[type] &&
        currentlyBroadcasting[type][CompareKeys[type]] ===
          selected[CompareKeys[type]])
    ) {
      triggerBroadCast(selected, type);
      return;
    }
    //already presentation is being broadcasted
    if (currentlyBroadcasting?.PRESENTATION) {
      stopPresentation();
      emptySelection(MediaTypes.PRESENTATION);
      triggerBroadCast(selected, type);
      return;
    }
    setTempSelection({
      type,
      media: selected,
    });

    setDisplayOverlapPopup(true);
  };

  const replaceMedia = () => {
    // remove all other media broadcasting
    Object.keys(currentlyBroadcasting).forEach(key => {
      if (currentlyBroadcasting[key]) {
        triggerBroadCast(currentlyBroadcasting[key], key);
      }
    });
    // trigger required broadcast
    triggerBroadCast(tempSelection.media, tempSelection.type);
    setDisplayOverlapPopup(false);
  };

  const continueBroadcast = () => {
    setDisplayOverlapPopup(false);
    // trigger required broadcast
    triggerBroadCast(tempSelection.media, tempSelection.type);
  };

  const triggerBroadCast = (media, type) => {
    if (type === MediaTypes.OVERLAY) {
      handleToggleOverlay(media);
      return;
    }
    if (type === MediaTypes.VIDEO) {
      handleVideoClick(media);
      return;
    }
    handlePresentation(media);
  };

  const handleToggleOverlay = image => {
    if (
      currentOverlay &&
      currentOverlay.channelImageId === image.channelImageId
    ) {
      api.channel
        .updateChannelImageStatus(channelConfig.channelId, {
          channelImageId: image.channelImageId,
          isLive: false,
        })
        .then(() => {
          dispatch({
            type: 'event/clearCurrentOverlay',
            payload: {
              channelId: channelConfig.channelId,
            },
          });
          emptySelection(MediaTypes.OVERLAY);
        });
    } else {
      api.channel
        .updateChannelImageStatus(channelConfig.channelId, {
          channelImageId: image.channelImageId,
          isLive: true,
        })
        .then(() => {
          dispatch({
            type: 'event/setCurrentOverlay',
            payload: {
              channelId: channelConfig.channelId,
              overlayImage: image,
            },
          });
          setCurrentlyBroadcasting(prev => ({
            ...prev,
            [MediaTypes.OVERLAY]: image,
          }));
        });
    }
  };

  const handleDeleteImage = ({ channelImageId }) => {
    api.channel
      .deleteChannelImage(channelId, channelImageId)
      .then(_ => {
        getChannelImagesByChannel(channelId);
      })
      .catch(_ => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Error deleting file',
          },
        });
      });
  };

  const {
    videoList,
    markVideoDone,
    playVideo,
    currentlyPlayingVideo,
    refreshVideoList,
  } = videoMediaConfig;
  const {
    presentationList,
    currentPresentation,
    refreshPresentationList,
    startPresentation,
    stopPresentation,
  } = presentationConfig as IUseChannelPresentationResponse;

  const handleDeletePresentation = ({ channelPresentationId }) => {
    api.channel
      .deletePresentation(channelId, channelPresentationId)
      .then(() => refreshPresentationList())
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Error deleting file',
          },
        });
      });
  };

  const handleDeleteVideo = ({ channelVideoId }) => {
    api.channel
      .deleteChannelVideo(channelId, channelVideoId)
      .then(_ => {
        refreshVideoList();
      })
      .catch(_ => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Error deleting file',
          },
        });
      });
  };

  const videos = useMemo(
    () =>
      videoList.map(v => ({
        ...v,
        url: v.videoLink,
        name: v.videoName,
      })),
    [videoList],
  );

  const presentations = useMemo(
    () =>
      presentationList.map(v => ({
        ...v,
        url: v.presentationUrl,
        name: v.presentationName,
      })),
    [presentationList],
  );

  const handleVideoClick = async video => {
    if (currentlyPlayingVideo?.channelVideoId === video.channelVideoId) {
      markVideoDone(video);
      emptySelection(MediaTypes.VIDEO);
      return;
    }

    if (currentlyPlayingVideo) {
      // Mark current video as done before playing the selected video
      await markVideoDone(currentlyPlayingVideo);
      emptySelection(MediaTypes.VIDEO);
    }
    setCurrentlyBroadcasting(prev => ({
      ...prev,
      [MediaTypes.VIDEO]: { ...video, type: MediaTypes.VIDEO },
    }));
    playVideo(video);
  };

  const isImagesExpanded = currentVisibleMediaList === 0;
  const isVideosExpanded = currentVisibleMediaList === 1;
  const isPresentationExpanded = currentVisibleMediaList === 2;

  const moveToCurrent = selectedMediaId => {
    setMoveToId(selectedMediaId);
  };

  const updateCurrentBroadcast = (key, value) => {
    setCurrentlyBroadcasting(prev => ({
      ...prev,
      [key]: { ...value, type: key },
    }));
  };
  useEffect(() => {
    if (currentPresentation && !currentlyBroadcasting?.PRESENTATION) {
      updateCurrentBroadcast(MediaTypes.PRESENTATION, currentPresentation);
    }
    if (currentlyPlayingVideo && !currentlyBroadcasting?.VIDEO) {
      updateCurrentBroadcast(MediaTypes.VIDEO, currentlyPlayingVideo);
    }
    if (currentOverlay && !currentlyBroadcasting?.OVERLAY) {
      updateCurrentBroadcast(MediaTypes.OVERLAY, currentOverlay);
    }
    if (!currentOverlay && currentlyBroadcasting?.OVERLAY) {
      emptySelection(MediaTypes.OVERLAY);
    }
    if (!currentlyPlayingVideo && currentlyBroadcasting?.VIDEO) {
      emptySelection(MediaTypes.VIDEO);
    }
    if (!currentPresentation && currentlyBroadcasting?.PRESENTATION) {
      emptySelection(MediaTypes.PRESENTATION);
    }
  }, [currentPresentation, currentlyPlayingVideo, currentOverlay]);

  const [
    isOverlayOrVideoBroadcasting,
    setIsOverlayOrVideoBroadcasting,
  ] = useState(false);

  // Hotfix for RocketLane
  // useEffect(()=>{
  //   if(!!currentlyBroadcasting?.VIDEO || !!currentlyBroadcasting?.OVERLAY){
  //     setIsOverlayOrVideoBroadcasting(true);
  //     return;
  //   }
  //   setIsOverlayOrVideoBroadcasting(false);
  // },[currentlyBroadcasting?.VIDEO, currentlyBroadcasting?.OVERLAY])

  return (
    <>
      {displayOverlapPopup && (
        <Modal
          title="Another media is already playing. Do you want to stop it and play a new content?"
          styleClass={IModalTypes.NEUTRAL_LIGHTEST}
          disableClose={true}
          noHeader={false}
          autoTrigger
          render={({ setShow }) => (
            <div className={styles.btnMenu}>
              <Button
                label="Yes, Replace Media"
                className={styles.cancelButton}
                onClick={e => {
                  e.stopPropagation();
                  replaceMedia();
                  setShow(false);
                }}
                styleClass={IButtonTypes.PRIMARY_NO_BG_INLINE}
                type="button"
              />
              <Button
                className={styles.confirmButton}
                label="No, Play both"
                onClick={e => {
                  e.stopPropagation();
                  continueBroadcast();
                  setShow(false);
                }}
                styleClass={IButtonTypes.PRIMARY}
                type="button"
              />
            </div>
          )}
        />
      )}
      <div className={styles.container}>
        {addImageConfig && (
          <AddMedia
            config={addImageConfig}
            channelId={channelConfig.channelId}
            onCancel={() => setAddImageConfig(undefined)}
            onSuccess={() => {
              addImageConfig.onSuccess();
              setAddImageConfig(undefined);
            }}
          />
        )}
        {!addImageConfig && (
          <>
            <ExpandableContent
              icon={IUIIcons.IMAGE}
              title="Image Overlays"
              subtitle="Session images"
              bgColor={IBGColors.NEUTRAL_LIGHTEST}
              hideDivider
              classNames={{
                container: isImagesExpanded
                  ? styles.expandedContainer
                  : undefined,
                header: styles.expandableHeader,
                headingIconWrap: styles.iconWrap,
                headingExpandIconWrap: styles.expandIconWrap,
                contentWrap: isImagesExpanded
                  ? styles.expandedContentWrap
                  : undefined,
              }}
              strict
              open={isImagesExpanded}
              setOpen={isOpen =>
                setCurrentVisibleMediaList(isOpen ? 0 : undefined)
              }
            >
              <GenericErrorBoundary>
                <SelectedItem
                  mediaType={MediaTypes.OVERLAY}
                  selectedItem={currentOverlay}
                  selectedId={currentOverlay && currentOverlay.channelImageId}
                  moveToCurrent={moveToCurrent}
                />
                <div
                  className={classnames(
                    styles.overlayImages,
                    currentOverlay &&
                      currentOverlay.channelImageId &&
                      styles.stickyMedia,
                  )}
                >
                  <MediaList
                    channelId={channelConfig.channelId}
                    mediaType={IUploadType.IMAGE}
                    mediaList={overlayImages}
                    title="Image Overlay"
                    onMediaClick={image => {
                      handleBroadCast(image, MediaTypes.OVERLAY);
                    }}
                    onDelete={handleDeleteImage}
                    selected={currentOverlay && currentOverlay.channelImageId}
                    onAdd={() =>
                      setAddImageConfig({
                        type: MediaTypes.OVERLAY,
                        heading: 'Add Overlay Image',
                        uploadType: IUploadType.IMAGE,
                        onSuccess: () =>
                          getChannelImagesByChannel(channelConfig.channelId),
                      })
                    }
                    filterOnBroadCast={false}
                    moveToId={moveToId}
                    setMoveToId={setMoveToId}
                  />
                </div>
              </GenericErrorBoundary>
            </ExpandableContent>
            <hr className={styles.divider} />
            <ExpandableContent
              icon={IUIIcons.VIDEO}
              title="Videos"
              subtitle="Session videos"
              bgColor={IBGColors.NEUTRAL_LIGHTEST}
              hideDivider
              classNames={{
                container: isVideosExpanded
                  ? styles.expandedContainer
                  : undefined,
                header: styles.expandableHeader,
                headingIconWrap: styles.iconWrap,
                headingExpandIconWrap: styles.expandIconWrap,
                contentWrap: isVideosExpanded
                  ? styles.expandedContentWrap
                  : undefined,
              }}
              strict
              open={isVideosExpanded}
              setOpen={isOpen =>
                setCurrentVisibleMediaList(isOpen ? 1 : undefined)
              }
            >
              <GenericErrorBoundary>
                {currentlyBroadcasting?.VIDEO && (
                  <SelectedItem
                    mediaType={MediaTypes.VIDEO}
                    selectedItem={currentlyBroadcasting?.VIDEO}
                    selectedId={currentlyBroadcasting?.VIDEO?.channelVideoId}
                    moveToCurrent={moveToCurrent}
                  />
                )}
                <div
                  className={classnames(
                    styles.videos,
                    currentlyBroadcasting?.VIDEO?.channelVideoId &&
                      styles.stickyMedia,
                  )}
                >
                  <MediaList
                    channelId={channelConfig.channelId}
                    mediaType={IUploadType.VIDEO}
                    mediaList={videos}
                    title="Video"
                    onMediaClick={video => {
                      handleBroadCast(video, MediaTypes.VIDEO);
                    }}
                    onDelete={handleDeleteVideo}
                    selected={
                      currentlyPlayingVideo &&
                      currentlyPlayingVideo.channelVideoId
                    }
                    onAdd={() =>
                      setAddImageConfig({
                        heading: 'Add Video',
                        uploadType: IUploadType.VIDEO,
                        onSuccess: () => refreshVideoList(),
                      })
                    }
                    filterOnBroadCast={false}
                    moveToId={moveToId}
                    setMoveToId={setMoveToId}
                  />
                </div>
              </GenericErrorBoundary>
            </ExpandableContent>
            <hr className={styles.divider} />
            <ExpandableContent
              icon={IUIIcons.PRESENTATION}
              title="Presentations"
              subtitle="Present and control PDF"
              bgColor={IBGColors.NEUTRAL_LIGHTEST}
              hideDivider
              classNames={{
                container: isPresentationExpanded
                  ? styles.expandedContainer
                  : undefined,
                header: styles.expandableHeader,
                headingIconWrap: styles.iconWrap,
                headingExpandIconWrap: styles.expandIconWrap,
                contentWrap: isPresentationExpanded
                  ? styles.expandedContentWrap
                  : undefined,
              }}
              strict
              open={isPresentationExpanded}
              setOpen={isOpen =>
                setCurrentVisibleMediaList(isOpen ? 2 : undefined)
              }
            >
              <GenericErrorBoundary>
                <SelectedItem
                  mediaType={MediaTypes.PRESENTATION}
                  selectedItem={currentlyBroadcasting?.PRESENTATION}
                  selectedId={
                    currentPresentation && currentPresentation.presentationId
                  }
                  moveToCurrent={moveToCurrent}
                />
                <div
                  className={classnames(
                    styles.presentations,
                    currentPresentation?.presentationId && styles.stickyMedia,
                  )}
                >
                  <MediaList
                    channelId={channelConfig.channelId}
                    mediaType={IUploadType.PDF_FILE}
                    mediaList={presentations}
                    title="Presentations"
                    onMediaClick={({ channelPresentationId }) => {
                      if (isOverlayOrVideoBroadcasting) return;
                      handleBroadCast(
                        presentations.find(
                          x => x.channelPresentationId == channelPresentationId,
                        ),
                        MediaTypes.PRESENTATION,
                      );
                    }}
                    onDelete={handleDeletePresentation}
                    selected={currentPresentation?.presentationId}
                    onAdd={() =>
                      setAddImageConfig({
                        heading: 'Add Presentation',
                        uploadType: IUploadType.PDF_FILE,
                        onSuccess: () => refreshPresentationList(),
                      })
                    }
                    filterOnBroadCast={false}
                    moveToId={moveToId}
                    setMoveToId={setMoveToId}
                    isItemClickDisabled={isOverlayOrVideoBroadcasting}
                  />
                </div>
              </GenericErrorBoundary>
            </ExpandableContent>
            <hr className={styles.divider} />
          </>
        )}
      </div>
    </>
  );
};

export default MediaTab;
