import React, { useState } from 'react';
import SelectField from 'react-select';

import styles from './styles.module.scss';
import { timeRangeSelectOptions, numMsInMins } from './config';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import IconTextButton, {IUIIcons, IIconTextButtonTypes} from '@/components/ui/buttons/IconTextButton';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';

const Timer = ({startTimer, stopTimer, timerDetails, timeLeftMs}) => {
    const [timeInterval, setTimeInterval] = useState(5*numMsInMins);

    const handleTimeIntervalSelect = option => {
        const { value } = option;
        const timeInMins = parseInt(value, 0);
        const timeInMs = timeInMins * numMsInMins;
        setTimeInterval(timeInMs);
    }

    const handleStartTimer = () => {
        if(timeInterval <= 0){
            return;
        }
        startTimer(timeInterval);
    }

    const isRunning = timeLeftMs !== undefined && timeLeftMs !== null && !!timerDetails;
    return (
        <div className={styles.container}>
            <Text 
                text="SET TIME"
                textSize={ITextSizes.XXSMALL}
                textColor={ITextColors.NEUTRAL_MEDIUM_2}
            />
            {
                !isRunning && <div className={styles.timeSelectWrapper}>
                    <SelectField 
                        className={styles.timeSelect}
                        options={timeRangeSelectOptions}
                        onChange={handleTimeIntervalSelect}
                        placeholder='Select'
                        defaultValue={timeRangeSelectOptions[4]}
                        styles={{
                            control: provided => ({
                                ...provided,
                                // backgroundColor: getCssVar(IContentColors.NEUTRAL_CONTRAST_3),
                                border: 'none'
                            }),
                            indicatorSeparator: () => ({
                                display: 'none'
                            }),
                            dropdownIndicator: provided => ({
                                ...provided,
                                // color: getCssVar(IContentColors.NEUTRAL_BASE_2)
                            }),
                            singleValue: provided => ({
                                ...provided,
                                // color: getCssVar(IContentColors.NEUTRAL_BASE_1)
                            })
                        }}
                    />
                </div>
            }
            <div className={styles.buttonWrapper}>
                {
                    !isRunning && <IconTextButton 
                        showDefault
                        defaultLabel="Start Timer"
                        defaultIcon={IUIIcons.TIMER_LINE}
                        onClick={handleStartTimer}
                        buttonType={IIconTextButtonTypes.BCKSTG_SUCCESS_SQUARE}
                    />
                }
                {
                    isRunning && <IconTextButton 
                        showDefault
                        defaultLabel="Stop Timer"
                        defaultIcon={IUIIcons.STOP}
                        onClick={stopTimer}
                        buttonType={IIconTextButtonTypes.BCKSTG_DANGER_SQUARE}
                />
                }
            </div>
        </div>
    );
}

export default Timer;