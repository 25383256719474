const reactionKeys = {
  base: ['reactions'] as const,
  reactionsList: (messageId: number) =>
    [...reactionKeys.base, messageId, 'reactions_list'] as const,
  reaction: (messageId: number) =>
    [...reactionKeys.base, messageId, 'reaction'] as const,
  messageById: (messageId: number) =>
    [...reactionKeys.base, messageId, 'message'] as const,
};

export default reactionKeys;
