export enum IGreenRoomActionPanelTabs {
  ANNOUNCEMENTS = 'announcements',
  CURRENT_SESSION = 'currentSession',
  MEDIA = 'media',
}

export enum ISpeakerGreenRoomStatus {
  AT_STAGE = 'atStage',
  AT_BACKSTAGE = 'atBackstage',
  PUT_ON_STAGE = 'putOnStage',
  REMINDER_SENT = 'reminderSent',
  SEND_REMINDER = 'sendReminder',
}
