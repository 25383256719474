import React, { useEffect, useRef } from 'react';
// styles + types
import styles from './styles.module.scss';
import DailyIframe from '@daily-co/daily-js';
import { useSelector } from 'react-redux';
import { selectCurrentUserName } from '@/models/account';
import { useMediaQuery } from 'react-responsive';
import { IStageParent } from '../Stage/types';
import useBreakoutAttendeeChannel from '@/services/messaging/use-breakout-attendee-channel';
import { useHistory, useParams } from 'react-router-dom';
import RedirectOverlay from '../PublishedStreamDisplay/RedirectOverlay';
import { makeSelectIsOrganizer } from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';

const DailyCall = ({ roomName, attendeeChannel }) => {
  const { eventId = '' } = useParams<{ eventId: string }>();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const currentUserName = useSelector(selectCurrentUserName);
  const containerRef = useRef();
  const history = useHistory();
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);

  useEffect(() => {
    if (!containerRef || !containerRef.current) {
      return;
    }

    if (!currentUserName) {
      return;
    }
    let scrollParams = {};
    if (isMobile) {
      scrollParams = { scrolling: 'auto' };
    }

    const dailyCoParent = containerRef.current;
    const callFrame = DailyIframe.createFrame(dailyCoParent, {
      activeSpeakerMode: false, // Show Grid view by default
      iframeStyle: {
        maxWidth: '100vw',
        width: '100%',
        height: isMobile ? '70vh' : '100%',
        ...scrollParams,
      },
      cssText: isOrganizer ? '' : '.record-controls {display: none}',
    });
    callFrame.join({
      url: `https://zuddl.daily.co/${roomName}`,
      userName: currentUserName,
    });
  }, [containerRef, currentUserName]);

  const {
    redirectType,
    redirectTo,
    setRedirectType,
  } = useBreakoutAttendeeChannel({
    attendeeChannel,
    parent: IStageParent.LIVE_DISCUSSION,
  });

  const redirect = () => {
    if (!redirectTo || redirectTo.length === 0) return;
    setRedirectType(null);
    if (redirectTo.includes('studio/')) {
      window.location.assign(redirectTo);
      return;
    }
    history.push(redirectTo);
  };

  return (
    <>
      {!!redirectType && (
        <RedirectOverlay onDone={redirect} redirectType={redirectType} />
      )}
      <div ref={containerRef} className={styles.callContainer}></div>
    </>
  );
};

export default DailyCall;
