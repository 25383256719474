import React from 'react';
import IconTextButton, {IUIIcons, IIconTextButtonTypes} from '@/components/ui/buttons/IconTextButton';

import styles from './styles.module.scss';

const Confetti = ({startConfetti, stopConfetti, isConfettiDisplayed}) => {

    return (
        <div className={styles.container}>
            {
                !!isConfettiDisplayed && <div>
                    <IconTextButton
                        showDefault
                        defaultLabel="Stop now"
                        defaultIcon={IUIIcons.STOP}
                        onClick={stopConfetti}
                        buttonType={IIconTextButtonTypes.BCKSTG_DANGER_SQUARE}
                    />
                </div>
            }
            {
                !isConfettiDisplayed && <div>
                    <IconTextButton
                        showDefault
                        defaultLabel="Start confetti"
                        defaultIcon={IUIIcons.ASTERISK}
                        onClick={startConfetti}
                        buttonType={IIconTextButtonTypes.BCKSTG_SUCCESS_SQUARE}
                    />
                </div>
            }
        </div>
    );
}

export default Confetti;