import { IVideoView, IVideoViewEvent } from '@/components/ui/media/Video/ReactPlayerWrapper/types';
import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/video`;
export default {
  getMuxUrl(eventId) {
    return axios.get(`${BASE_URL}/upload/${eventId}`);
  },
  getVideoById(videoId) {
    return axios.get(`${BASE_URL}/${videoId}`);
  },
  insertVideoViewLogs(eventId: string, data: IVideoViewEvent) {
    return axios.post(`${BASE_URL}/${eventId}/view/logs`, data);
  },
  insertVideoImpression(data: IVideoView) {
    return axios.post(`${BASE_URL}/impression`, data);
  },
};