import React from 'react';
import styles from './styles.module.scss';
import { IContentTextSizeTypes } from '@/types';
import Text, { ITextColors, ITextStyles, ITextMargins } from '../ui/content/Text';
import { X, Trash, Trash2, Save } from 'react-feather';
import { ICON_SIZE_LARGE, SMALL_ICON_SIZE, EMOJI_ICON_SIZE, HOVER_NAV_ICON_SIZE } from '@/styles/iconSizes';

const BusinessCard = ({ accountDetails, onSave, onDelete }) => {
  const { accountId, firstName, lastName, email, bio, headline } = accountDetails;
  const name = `${firstName} ${lastName}`;

  const handleDelete = () => {
    onDelete && onDelete(accountId);
  };

  return (
    <div id={`businessCard-${accountId}`} className={styles.businessCard} data-private>
      {
        onSave && (
          <div className={styles.deleteIcon}>
            <Save
              size={SMALL_ICON_SIZE}
              color={ITextColors.WHITE}
              onClick={onSave}
            />
          </div>
        )
      }
      {onDelete && (
        <div className={styles.deleteIcon}>
          <Trash2
            size={SMALL_ICON_SIZE}
            color={ITextColors.WHITE}
            onClick={handleDelete}
          />
        </div>
      )}
      <Text text={name} textStyle={ITextStyles.BOLDED} textColor={ITextColors.WHITE} textSize={IContentTextSizeTypes.MEDIUM} /> <br />
      {/* <Text text={email} textColor={ITextColors.WHITE} textSize={IContentTextSizeTypes.SMALL} /> <br /> */}
      <Text text={headline} textColor={ITextColors.WHITE} textSize={IContentTextSizeTypes.SMALL} /> <br />
      <Text textMargin={ITextMargins.SMALL_MARGIN_TOP} text={bio} textStyle={ITextStyles.ITALICIZED} textColor={ITextColors.WHITE} textSize={IContentTextSizeTypes.XSMALL} block={true} />
    </div >
  );
};

export default BusinessCard;
