// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__callContainer___2nf62{position:relative;width:100%;height:100%}\n", ""]);
// Exports
exports.locals = {
	"callContainer": "styles-module__callContainer___2nf62"
};
module.exports = exports;
