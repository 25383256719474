import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Check, ArrowRight, ArrowUp, Loader } from 'react-feather';
// components
import MessageIcon from '@/components/ui/new-icons/Message';
import ProfilePicture from '@/components/ui/ProfilePicture';
import Text, { ITextSizes, ITextColors, ITextMargins, ITextStyles } from '@/components/ui/content/Text';
// config + styles + types
import styles from './styles.module.scss';
import { ISpeakerGreenRoomStatus } from '@/components/ChannelStreamsContainer/GreenRoomActionPanel/types';
import { IGeneralSizeTypes, IContentColors } from '@/types';
import api from '@/api';
import { useParams } from 'react-router-dom';
import MicOnIcon from '@/components/ui/new-icons/MicOn';
import MicOffIcon from '@/components/ui/new-icons/MicOff';
import VideoOnIcon from '@/components/ui/new-icons/VideoOn';
import VideoOffIcon from '@/components/ui/new-icons/VideoOff';
import ConfirmExitModal from '@/components/PublishedStreamDisplay/StreamOptionsBar/ConfirmExitModal';
import ConfirmExitPromptsModal from '@/components/PublishedStreamDisplay/StreamOptionsBar/ConfirmExitPromptsModal';
import { getFullName, getBackstageToStageAVStatus } from '@/utils/helpers';
import useAudioVolume from '@/services/video/useAudioVolume';
import { useVideoState } from '@/services/video/video-context';
import ReactTooltip from 'react-tooltip';

const RollCallRow = (props) => {
  const {eventId = '', stageId = ''} = useParams();
  const { speaker, onDelete, userMoveFns, localAvConfig = {} } = props;
  const {
    name,
    imageUrl,
    streamInfo: { greenroom = false, uid = null, isLocal = undefined },
  } = speaker;
  const [reminderSent, setReminderSent] = useState(false);
  const timeoutRef = useRef<any>();
  const dispatch = useDispatch();

  const { audioTrackMap = {}, videoTrackMap = {} } = useVideoState();
  const audioTrack = audioTrackMap[uid];
  const videoTrack = videoTrackMap[uid];

  const {
    audioEnabled: localAudioEnabled = false,
    videoEnabled: localVideoEnabled = false,
  } = localAvConfig;

  const {
      handleUserKickout,
      moveUserToStage,
      moveUserToGreenRoom,
    } = userMoveFns;

  const videoOn = isLocal ? localVideoEnabled : !!videoTrack;
  const audioOn = isLocal ? localAudioEnabled : !!audioTrack;
  // const { isAudioEnabled: audioOn } = useAudioVolume({
  //   audioTrack,
  //   isLocalStream: isLocal,
  //   isLocalAudioEnabled: localAudioEnabled,
  // });

  const avIconProps = {
    color: IContentColors.NEUTRAL_MID_2,
    size: 20
  };

  const status = useMemo(() => {
    if(greenroom){
      return ISpeakerGreenRoomStatus.AT_BACKSTAGE;
    }

    return ISpeakerGreenRoomStatus.SEND_REMINDER;
  }, [speaker])

  const showAvIndicators = status === ISpeakerGreenRoomStatus.AT_BACKSTAGE && !isLocal;

  const handleReminder = () => {
    api.event.speakerRollCall({eventId, stageId, speakerId: speaker.accountId});
    setReminderSent(true);
    timeoutRef.current = setTimeout(() => {
      setReminderSent(false);
    }, 30000)
  }

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    }
  }, []);

  // const bothAVDisabled = !audioOn && !videoOn;
  const fullName = getFullName(speaker.firstName, speaker.lastName);

  const handleAvReminder = () => {
    if (isLocal) {
      dispatch({
        type: 'global/setMessageModal',
        payload: {
          title: 'Please turn on your audio/video to go to the stage',
        },
      });
      return;
    }
    api.stage.remindSpeakerToTurnOnAv(stageId, speaker.accountId);
    dispatch({
      type: 'global/setMessageModal',
      payload: {
        title: `You cannot push ${fullName} to stage as their audio and video is turned off.`,
        subtitle: 'Please ask them to turn their audio and video on.',
      },
    });
  };

  const backstageToStageModalText = useMemo(
    () => getBackstageToStageAVStatus(audioOn, videoOn, fullName),
    [audioOn, videoOn, fullName],
  );

  const getRollCallButton = () => {
    if (status === ISpeakerGreenRoomStatus.AT_BACKSTAGE) {
      return (
        <ConfirmExitPromptsModal
          trigger={({ setShow }) => (
            <button
              className={styles.rollCallPutOnStageButton}
              type="button"
              onClick={() => setShow(true)}
            >
              <ArrowUp className={styles.rollCallIconActive} size={10} />
              <Text
                text="on Stage"
                textColor={ITextColors.MONOCHROME_CONTRAST}
                textSize={ITextSizes.XXSMALL}
                clickable={true}
                // textMargin={ITextMargins.SMALL_MARGIN_LEFT}
              />
            </button>
          )}
          handleConfirm={({ setShow }) => {
            moveUserToStage(uid);
            setShow(false);
          }}
          title={backstageToStageModalText.title}
          exitText={backstageToStageModalText.description}
          confirmButtonText="Yes"
        />
      );
    }
    if (!reminderSent) {
      return (
        <button className={styles.rollCallSendReminderButton} type="button" onClick={handleReminder}>
          {/* <ArrowRight className={styles.rollCallIconInactive} size={10} /> */}
          <MessageIcon color={IContentColors.MONOCHROME_CONTRAST} size={10} />
          <Text
            text="Remind"
            textColor={ITextColors.NEUTRAL_MEDIUM}
            textSize={ITextSizes.XSMALL}
            textMargin={ITextMargins.SMALL_MARGIN_LEFT}
            clickable={true}
          />
        </button>
      );
    }
    if (reminderSent) {
      return (
        <button className={styles.rollCallReminderSentButton} type="button">
          <Check className={styles.rollCallIconActive} size={10} />
          <Text
            text="Sent"
            textColor={ITextColors.NEUTRAL_MEDIUM}
            textSize={ITextSizes.XSMALL}
            textMargin={ITextMargins.SMALL_MARGIN_LEFT}
            clickable={true}
          />
        </button>
      );
    }
    return null;
    // if (status === ISpeakerGreenRoomStatus.PUT_ON_STAGE) {
    //   return (
    //     <button className={styles.rollCallPutOnStageButton} type="button">
    //       <ArrowUp className={styles.rollCallIcon} size={12} />
    //       <Text
    //         text="Put on Stage"
    //         textColor={ITextColors.NEUTRAL_MEDIUM}
    //         textSize={ITextSizes.XXSMALL}
    //         textMargin={ITextMargins.SMALL_MARGIN_LEFT}
    //       />
    //     </button>
    //   );
    // }
  };

  return (
    <div className={styles.rollCallRowContainer}>
      <div className={styles.rollCallRowLeftContent}>
        <div className={styles.rollCallProfilePicture}>
          <ProfilePicture
            hexColor={speaker.hexColor}
            name={`${speaker.firstName} ${speaker.lastName}`}
            iconSize={IGeneralSizeTypes.XSMALL}
            imageUrl={imageUrl}
            disableTooltip
          />
        </div>
        <div
          className={styles.rollCallName}
          data-tip
          data-for={`roll_call_${speaker.eventRoleId}`}
        >
          <Text
            text={`${speaker.firstName} ${speaker.lastName}`}
            textColor={ITextColors.MONOCHROME_CONTRAST}
            textSize={ITextSizes.XSMALL}
            textStyle={ITextStyles.SEMI_BOLDED}
          />
          <ReactTooltip
            id={`roll_call_${speaker.eventRoleId}`}
            place="top"
            effect="solid"
          >
            {`${speaker.firstName} ${speaker.lastName}`}
          </ReactTooltip>
        </div>
      </div>
      <div className={styles.rollCallRowRightContent}>
        {
          showAvIndicators && (
            <div className={styles.avIndicators}>
              {audioOn ? <MicOnIcon {...avIconProps} /> : <MicOffIcon {...avIconProps} />}
              {videoOn ? <VideoOnIcon {...avIconProps} /> : <VideoOffIcon {...avIconProps} />}
            </div>
          )
        }
        {getRollCallButton()}
        {/* <IconButton
          key="deleteButton"
          tooltip="Remove Speaker"
          activeIcon={IUIIcons.DELETE}
          defaultIcon={IUIIcons.DELETE}
          iconBG={IContentBGColorTypes.NEUTRAL_MEDIUM}
          iconSize={14}
          onClick={() => onDelete(speaker.accountId)}
          showDefault={true}
          type={IIconButtonTypes.SMALL_CUSTOM_BG}
        /> */}
      </div>
    </div>
  );
};

export default RollCallRow;
