import { IVideoModeType } from '@/types/video';
import { IStageParent } from '@/components/Stage/types';

export enum IRaisedHandRequestStatus {
  REQUESTED = 'REQUESTED',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  DISMISSED = 'DISMISSED',
}

export enum StreamType {
  MAIN = 'MAIN',
  VIEWER = 'VIEWER',
  SCREEN = 'SCREEN',
  MAIN_RELAY = 'MAIN_RELAY',
  SCREEN_RELAY = 'SCREEN_RELAY',
}

export interface IBroadcastPermissionsObj {
  audio: boolean;
  video: boolean;
}

export type IChannelStreamsContainerShowTabState = null | number;

export interface IChannelStreamsContainerProps {
  mode: IVideoModeType;
  parent: IStageParent;
  refId: string;
  eventConfig: any;

  ready: any;
  isBackstage: any;
  isBroadcasting: any;
  stageConfig: any;
  stageSettings: any;
  breakoutConfig: any;

  chatPanelOpen: any;
  setChatPanelOpen: any;

  showTabIndex: any;
  setShowTabIndex: any;

  // pusher
  attendeeChannel: any;
  hostChannel: any;

  // Video
  videoHost: any;
  localClient: any;
  mutationCtx: any;
  setStreamsDisplayMode: any;
  stateCtx: any;
  streamsDisplayMode: any;
  broadcastPermissions: any;
  setBroadcastPermissions: any;
  raisedHandStatus: any;

  // Screenshare
  handleScreenShareButtonClick: any;
  pendingScreen: any;

  // Raised Hand Requests
  approveRaisedHandRequest: any;
  completeMyRaisedHandRequest: any;
  completeRaisedHandRequest: any;
  dismissAllRaisedHandRequests: any;
  dismissRaisedHandRequest: any;
  handleRaiseHandToggle: any;
  raisedHandRequestList: any;
  completeRaisedHandRequests: any;

  handleBroadcastButtonClick: any;
  handleEmojiClick: any;
  streamInfo: any;
  streamNamesMap: any;
  hideStreamOptions: any;

  timeLeftMs: any;
  timerDetails: any;
  startTimer: any;
  stopTimer: any;

  // Table
  selectedTable: any;

  // Booth
  isBoothLiveView: boolean;
  toggleBoothLiveView: () => void;
  localAvConfig: any;
}
