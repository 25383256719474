import React, { useState } from 'react';

import ExpandableContent, {
  IUIIcons,
  IBGColors,
} from '@/components/ui/ExpandableContent';
import Timer from './Timer';
import Confetti from './Confetti';
import Breakout from './Breakout';
import styles from './styles.module.scss';
import GenericErrorBoundary from '@/components/GenericErrorBoundary';
import LiveStreamConfig from './LiveStreamConfig';

enum Extras {
  LIVE_STREAM = 'LiveStream',
  TIMER = 'Timer',
  CONFETTII = 'Confetti',
  BREAKOUT = 'Breakout',
}

const ExtrasTab = ({
  channelId,
  liveStreamConfig,
  timerConfig,
  confettiConfig,
  breakoutConfig,
}) => {
  const [showOnly, setShowOnly] = useState(null);

  const toggleShowOnly = showComponent => {
    if (showOnly === showComponent) {
      setShowOnly(null);
    } else {
      setShowOnly(showComponent);
    }
  };

  return (
    <div className={styles.container}>
      {(!showOnly || showOnly === Extras.LIVE_STREAM) && (
        <>
          <ExpandableContent
            icon={IUIIcons.SIGNAL_TOWER}
            title="Live Stream"
            subtitle="Configure your RTMP Playback url here"
            bgColor={IBGColors.NEUTRAL_LIGHTEST}
            hideDivider
            classNames={{
              headingIconWrap: styles.iconWrap,
              headingExpandIconWrap: styles.expandIconWrap,
            }}
          >
            <GenericErrorBoundary>
              <LiveStreamConfig {...liveStreamConfig} />
            </GenericErrorBoundary>
          </ExpandableContent>
          <hr className={styles.divider} />
        </>
      )}
      {(!showOnly || showOnly === Extras.TIMER) && (
        <>
          <ExpandableContent
            icon={IUIIcons.TIMER_LINE}
            title="Begin Timer"
            bgColor={IBGColors.NEUTRAL_LIGHTEST}
            hideDivider
            classNames={{
              headingIconWrap: styles.iconWrap,
              headingExpandIconWrap: styles.expandIconWrap,
            }}
          >
            <GenericErrorBoundary>
              <Timer {...timerConfig} />
            </GenericErrorBoundary>
          </ExpandableContent>
          <hr className={styles.divider} />
        </>
      )}
      {(!showOnly || showOnly === Extras.CONFETTII) && (
        <>
          <ExpandableContent
            icon={IUIIcons.ASTERISK}
            title="Control Confetti"
            bgColor={IBGColors.NEUTRAL_LIGHTEST}
            hideDivider
            classNames={{
              headingIconWrap: styles.iconWrap,
              headingExpandIconWrap: styles.expandIconWrap,
            }}
          >
            <GenericErrorBoundary>
              <Confetti {...confettiConfig} />
            </GenericErrorBoundary>
          </ExpandableContent>
          <hr className={styles.divider} />
        </>
      )}
      {breakoutConfig &&
        Object.keys(breakoutConfig).length > 0 &&
        (!showOnly || showOnly === Extras.BREAKOUT) && (
        <>
          <ExpandableContent
            icon={IUIIcons.GRID_LAYOUT}
            title="Breakout Rooms"
            subtitle="Split your stage into rooms"
            bgColor={IBGColors.NEUTRAL_LIGHTEST}
            hideDivider
            classNames={{
              headingIconWrap: styles.iconWrap,
              headingExpandIconWrap: styles.expandIconWrap,
            }}
            toggleCompleteExpand={() => toggleShowOnly(Extras.BREAKOUT)}
          >
            <GenericErrorBoundary>
              <Breakout {...breakoutConfig} />
            </GenericErrorBoundary>
          </ExpandableContent>
          <hr className={styles.divider} />
        </>
      )}
    </div>
  );
};

export default ExtrasTab;
