const PRESENTATION_LAYOUT_V2_EVENT_IDS: string[] = [
  '0a011f31-404d-4a04-928b-67544051dbc8', // [PRE-PROD] Rooms Test Event
  '051a899a-697b-42f0-810d-9865174609ed', // [VEDHA-LOCAL] Local Test Event
  '088292c0-c298-4266-9f31-8ac59de45a36', // [PRE-PROD] Abhi Event
  'eb925bbe-5bf6-4bd9-9a77-672152157317', // [PRE-PROD] Bayer Test Event
  '1e41084f-fb08-4ecc-800a-2162f4c968e2', // [PROD] Zuddl Bayer Testing Event - 29th May
  //'8c3ff25e-2818-4982-bd66-b2cf2095093e', // [PROD] Zuddl Virtual Office
  'e89589ce-5845-4a50-a2a5-efea101d8271', // [PROD] Billion Dollar Seller Summit 8 Virtual
];

const PRESENTATION_LAYOUT_V2_ORG_IDS: string[] = [];

export const isPresentationLayoutV2Enabled = (
  eventId: string,
  orgId?: string,
) =>
  PRESENTATION_LAYOUT_V2_EVENT_IDS.includes(eventId) ||
  PRESENTATION_LAYOUT_V2_ORG_IDS.includes(orgId as string);
