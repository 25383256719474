import classnames from 'classnames';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// components
import AutoplayClickOverlay from '../ui/AutoplayClickOverlay';
import ConnectionStatusIndicator from './ConnectionStatusIndicator';
import GreenRoomActionPanel from './GreenRoomActionPanel';
import PublishedStreamDisplay, {
  IVideoFrameTypes,
} from '@/components/PublishedStreamDisplay';
import StreamOptionsBar from '@/components/PublishedStreamDisplay/StreamOptionsBar';
// helpers
import { _isEmpty } from '@/utils/jsHelpers/array';
// hooks
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { useMediaQuery } from 'react-responsive';

// models
import {
  makeSelectIsOrganizer,
  makeSelectEventRoleForEvent,
  makeSelectEventStageIsBroadcasting,
  makeSelectEventChannelSettings,
  makeSelectPresentationLayoutByEventId,
} from '@/models/event';
import {
  selectIsStageFull,
  selectUseVirtualBackdrop,
  selectRecordingMode,
  selectChannelSidePanelState,
} from '@/models/global';
// styles + types
import styles from './styles.module.scss';
import { IChannelStreamsContainerProps } from './types';
import { IEventChannelSettingsType } from '@/types/channel';
import {
  IScreenCalcOrientationOptions,
  IStreamsDisplayMode,
} from '../PublishedStreamDisplay/types';
import { IStageParent, StreamType } from '../Stage/types';
import { IStageLayoutTemplate } from '@/custom-config/types';
// config
import { VJ_15FPS, VJ_STREAM_IDS } from '@/config/vj';
import configByEvent, { configByStage } from '@/custom-config';
import { getDisplayModeByTemplate } from '@/components/PublishedStreamDisplay/utils';
// services
import useVideoQueue from '@/services/messaging/use-video-queue';
import useUserChannelChanges from '@/services/video/useUserChannelChanges';
import DeepARCamera from '../DeepARCamera';
import useAnimationEffects from '@/services/messaging/use-animation-effects';
import { useResizeWindowEvent } from '@/services/useWindowEvent';
import useChannelImages from '@/services/messaging/use-channel-images';
import useChannelLiveStream from '@/services/messaging/useChannelLiveStream';
import { interpreterStreamIds } from '../PublishedStreamDisplay/StreamsDisplay/interpreter';
import useChannelPresentation from '@/services/messaging/useChannelPresentation';
import zuddlLogger from '@/utils/zuddlLogger';
import { selectCurrentUser } from '@/models/account';
import useStageVolumeToggle from '@/services/video/useStageVolumeToggle';
import useStageSettingsChanges from '@/hooks/use-stage-settings-changes';
import useSessionOrderChanges from '@/hooks/use-session-order-changes';
import BottomSheet from '../ui/BottomSheet';
import useChannelPlugin from '@/services/messaging/useChannelPlugin';
import StreamsLimitExceedPopup from '../StreamsLimitExceedPopup';
import { isPresentationLayoutV2Enabled } from './config';

const AGORA_CHANNEL_STREAMS_LIMIT = 16;

const EMPTY_ARRAY = [];

// TODO(VS): make sure isBroadcasting is handled well in both rtc and live channel profiles
const ChannelStreamsContainer = (props: IChannelStreamsContainerProps) => {
  const { eventId, stageId } = useParams() as any;
  const [toggleLeftPanel, setToggleLeftPanel] = useState(0);
  const isRecordingMode = useSelector(selectRecordingMode);

  const {
    mode,
    parent,
    refId,
    stageConfig,
    stageSettings,
    eventConfig,
    hideStreamOptions,
    breakoutConfig,

    setShowTabIndex,

    // Interaction Sidebar
    setChatPanelOpen,

    // pusher
    attendeeChannel,
    hostChannel,

    // Video
    videoHost,
    localClient,
    mutationCtx,
    setStreamsDisplayMode,
    stateCtx,
    streamsDisplayMode,
    broadcastPermissions,
    setBroadcastPermissions,

    // Screenshare
    handleScreenShareButtonClick,
    pendingScreen,
    stopScreenShare,

    // Raised Hand Requests
    raisedHandStatus,
    completeMyRaisedHandRequest,
    completeRaisedHandRequest,
    handleRaiseHandToggle,
    raisedHandRequestList,

    // Audience Gallery Requests
    audienceGalleryRequestStatus,
    completeMyAudienceGalleryRequest,
    completeAudienceGalleryRequest,
    handleAudienceGalleryRequest,
    audienceGalleryRequestList,

    // Contributor View Requests
    contributorViewRequestStatus,
    completeMyContributorViewRequest,
    completeContributorViewRequest,
    handleContributorViewRequest,
    contributorViewRequestList,

    handleBroadcastButtonClick,
    handleEmojiClick,
    streamInfo,
    streamNamesMap,

    // Timer
    timeLeftMs,
    timerDetails,
    startTimer,
    stopTimer,

    // Table
    selectedTable,

    // If user is viewing the live expo
    isBoothLiveView = false,
    toggleBoothLiveView,
    localAvConfig,
  } = props;

  const dispatch = useDispatch();
  const eventRole = useMemoizedSelector(makeSelectEventRoleForEvent, eventId);
  const currentUser = useSelector(selectCurrentUser);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const isBroadcasting = useMemoizedSelector(
    makeSelectEventStageIsBroadcasting,
    { eventId, stageId },
  );

  const [stageContainerHeight, setStageContainerHeight] = useState() as any;
  const [stageContainerWidth, setStageContainerWidth] = useState() as any;

  const stageCustomConfig = stageId && configByStage[stageId];
  const ref = React.useRef<SheetRef>();
  const snapTo = (i: number) => ref.current?.snapTo(i);
  const [selectedSnapPoint, setSnapPoint] = useState(0);

  const eventChannelSettings: IEventChannelSettingsType = useSelector(
    makeSelectEventChannelSettings,
  );

  const {
    videoList,
    playVideo,
    markVideoDone,
    refreshVideoList,
    currentlyPlayingVideo,
  } = useVideoQueue({
    stageConfig,
    attendeeChannel,
  });

  const presentationConfig = useChannelPresentation({
    channelId: stageConfig?.channelId,
    attendeeChannel,
  });

  const pluginConfig = useChannelPlugin({
    channelId: stageConfig?.channelId,
    attendeeChannel,
  });

  const {
    currentLiveStream,
    startLiveStream,
    stopLiveStream,
  } = useChannelLiveStream({
    channelConfig: stageConfig,
    attendeeChannel,
  });

  useChannelImages({
    channelConfig: stageConfig,
    attendeeChannel,
  });

  const { startConfetti, stopConfetti, showConfetti } = useAnimationEffects({
    attendeeChannel,
    channelConfig: stageConfig,
  });

  const useVirtualBackdrop = useSelector(selectUseVirtualBackdrop);

  useResizeWindowEvent(event => {
    const getHeight = htmlElement =>
      htmlElement.item &&
      htmlElement.item(0) &&
      htmlElement.item(0).clientHeight;
    const getWidth = htmlElement =>
      htmlElement.item &&
      htmlElement.item(0) &&
      htmlElement.item(0).clientWidth;

    let div = document.getElementsByClassName(
      styles.videoStreamContainerFullWidth,
    );
    if (!div) {
      div = document.getElementsByClassName(
        styles.videoStreamContainerSidePanelOpen,
      );
    }
    setStageContainerHeight(getHeight(div));
    setStageContainerWidth(getWidth(div));
  });

  const {
    localStream,
    audioPlayFailed,
    streams: baseStreams,
    greenRoomStreams: baseGreenRoomStreams,
    primaryStream: basePrimaryStream,
    secondaryStreams: baseSecondaryStreams,
    setAudioPlayFailed,
    dialInStreams,
  } = streamInfo;

  const canSeeVideo =
    ![IStageParent.LIVE_BACKSTAGE, IStageParent.LIVE_DISCUSSION, IStageParent.CONTRIBUTOR_SLOT_AUDIO].includes(
      parent,
    ) || broadcastPermissions || (isRecordingMode && parent === IStageParent.LIVE_DISCUSSION);
  zuddlLogger.staging.debug(
    'debugChannelStreamsContainer',
    canSeeVideo,
    parent,
    broadcastPermissions,
    baseStreams,
  );

  const streams = canSeeVideo ? baseStreams : EMPTY_ARRAY;
  const greenRoomStreams = canSeeVideo ? baseGreenRoomStreams : EMPTY_ARRAY;
  const secondaryStreams = canSeeVideo ? baseSecondaryStreams : EMPTY_ARRAY;
  const primaryStream = canSeeVideo ? basePrimaryStream : undefined;

  const isStreamsEmpty = _isEmpty(streams);
  const streamDetailsMap = useMemo(() => stateCtx.streamInfoMap, [stateCtx]);

  const isStageFull = useSelector(selectIsStageFull);
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);

  const isPresentationLayoutEnabled = useMemoizedSelector(
    makeSelectPresentationLayoutByEventId,
    eventId,
  );

  const [
    currentGreenRoomActionPanelTab,
    setCurrentGreenRoomActionPanelTab,
  ] = useState(0);
  const [currentVisibleMediaList, setCurrentVisibleMediaList] = useState<
    number
  >();

  const openMediaTabInGreenroomPanel = () => {
    setCurrentGreenRoomActionPanelTab(1);
    setCurrentVisibleMediaList(2);
  };

  const channelSidePanelState = useSelector(selectChannelSidePanelState);

  useEffect(() => {
    const streamId = streams[0]?.uid;
    const singleStreamHasVjAccess: boolean =
      VJ_STREAM_IDS.includes(streamId) ||
      VJ_15FPS.includes(streamId) ||
      streamDetailsMap[streamId]?.hasVjAccess;

    if (
      streams.length === 1 &&
      ((['2056e6cf-4592-4b20-be02-4e9f5e5bc14e'].includes(eventId) &&
        [
          IStageParent.LIVE_STAGE,
          IStageParent.LIVE_BACKSTAGE,
          IStageParent.STAGE_PREVIEW,
        ].includes(parent)) ||
        singleStreamHasVjAccess)
    ) {
      dispatch({
        type: 'global/setStageFull',
        payload: true,
      });
    } else {
      dispatch({
        type: 'global/setStageFull',
        payload: false,
      });
    }

    return () => {
      dispatch({
        type: 'global/setStageFull',
        payload: false,
      });
    };
  }, [eventId, streams, streamDetailsMap]);

  useEffect(() => {
    const hasScreenshareStream =
      streams
        .map(
          stream =>
            streamDetailsMap[stream.uid] && streamDetailsMap[stream.uid].type,
        )
        .filter(type =>
          [StreamType.SCREEN, StreamType.SCREEN_RELAY].includes(type),
        ).length > 0;

    const hasChannelPresentationOrPlugin =
      !!presentationConfig?.currentPresentation ||
      !!pluginConfig?.currentPlugin;

    if (
      parent === IStageParent.LIVE_DISCUSSION &&
      isPresentationLayoutV2Enabled(eventId)
    ) {
      setStreamsDisplayMode(IStreamsDisplayMode.PRESENTATION_V2);
      return;
    }

    if (
      parent === IStageParent.LIVE_DISCUSSION &&
      isPresentationLayoutEnabled
    ) {
      setStreamsDisplayMode(IStreamsDisplayMode.PRESENTATION);
      return;
    }

    if (
      parent === IStageParent.LIVE_DISCUSSION ||
      parent === IStageParent.BOOTH ||
      parent === IStageParent.BOOTH_OWNER
    ) {
      if (hasChannelPresentationOrPlugin) {
        setStreamsDisplayMode(IStreamsDisplayMode.SPOTLIGHT);
      } else if (!hasScreenshareStream) {
        setStreamsDisplayMode(IStreamsDisplayMode.GRID);
      } else if (streams.length === 2) {
        setStreamsDisplayMode(IStreamsDisplayMode.CONDENSED);
      } else {
        setStreamsDisplayMode(IStreamsDisplayMode.SPOTLIGHT);
      }
      return;
    }

    if (
      parent === IStageParent.AUDIENCE_GALLERY ||
      parent === IStageParent.CONTRIBUTOR_SLOT ||
      parent === IStageParent.CONTRIBUTOR_SLOT_AUDIO
    ) {
      setStreamsDisplayMode(IStreamsDisplayMode.GRID);
      return;
    }

    let interpreterStream;
    const nonInterpreterStreams = [];

    streams.forEach((st: any) => {
      if (!interpreterStream && interpreterStreamIds.includes(st.uid)) {
        interpreterStream = st;
      } else {
        nonInterpreterStreams.push(st);
      }
    });
    if (hasChannelPresentationOrPlugin) {
      setStreamsDisplayMode(IStreamsDisplayMode.SPOTLIGHT);
    } else if (stageSettings?.stageLayoutTemplate != null) {
      // Check if there's a screen layout configuration set in the database
      // Get the specific stage layout configuration
      const displayMode = getDisplayModeByTemplate(
        stageSettings.stageLayoutTemplate,
        nonInterpreterStreams.length,
        hasScreenshareStream,
      );
      setStreamsDisplayMode(displayMode);
    } else if (eventChannelSettings?.stageLayoutTemplate != null) {
      // Get the event level stage layout configuration
      const displayMode = getDisplayModeByTemplate(
        eventChannelSettings.stageLayoutTemplate,
        nonInterpreterStreams.length,
        hasScreenshareStream,
      );
      setStreamsDisplayMode(displayMode);
    } else if (stageCustomConfig && stageCustomConfig.getDisplayMode) {
      const displayMode = stageCustomConfig.getDisplayMode(
        nonInterpreterStreams.length,
        hasScreenshareStream,
      );
      setStreamsDisplayMode(displayMode);
    } else if (eventConfig && eventConfig.getDisplayMode) {
      const displayMode = eventConfig.getDisplayMode(
        nonInterpreterStreams.length,
        hasScreenshareStream,
      );
      setStreamsDisplayMode(displayMode);
    } else {
      // We default to template 1 if no database or file configurations are present.
      const displayMode = getDisplayModeByTemplate(
        IStageLayoutTemplate.TEMPLATE_1,
        nonInterpreterStreams.length,
        hasScreenshareStream,
      );
      setStreamsDisplayMode(displayMode);
    }
  }, [
    streams,
    parent,
    streamsDisplayMode,
    streamDetailsMap,
    eventChannelSettings,
    stageSettings,
    presentationConfig?.currentPresentation,
    pluginConfig?.currentPlugin,
  ]);

  const {
    handleUserKickout,
    moveUserToStage,
    moveUserToGreenRoom,
  } = useUserChannelChanges({
    channelConfig: stageConfig,
    localClient,
    attendeeChannel,
    hostChannel,

    stopScreenShare,

    raisedHandStatus,
    completeMyRaisedHandRequest,

    audienceGalleryRequestStatus,
    completeMyAudienceGalleryRequest,

    contributorViewRequestStatus,
    completeMyContributorViewRequest,

    setBroadcastPermissions,
    videoHost,
    eventId,
    stageId,
  });

  const { stageSessionSettings } = useStageSettingsChanges({
    eventId,
    stageId,
    hostChannel,
  });

  // below hook for session ordering changes pusher listeners
  useSessionOrderChanges({ hostChannel, eventId });

  let hasFilteredGreenRoomStreams: boolean = false;
  const showGreenRoomActionPanel =
    isOrganizer && parent === IStageParent.LIVE_BACKSTAGE;
  const HYBRID_EVENT_IDS = [
    '40e1f5a6-546a-4b79-8162-b1c722781e1d', // MICROSOFT
    '1f62f2fc-f78d-462b-8a9d-e883a0387c38', // Local
  ];

  useEffect(() => {
    let setVol = true;
    if (
      [IStageParent.STAGE_PREVIEW, IStageParent.LIVE_BACKSTAGE].includes(
        parent,
      ) ||
      (HYBRID_EVENT_IDS.includes(eventId) && isMobile)
    ) {
      setVol = false;
    }
    dispatch({
      type: 'global/setStageVolume',
      payload: setVol,
    });

    return () => {
      dispatch({
        type: 'global/setStageVolume',
        payload: false,
      });
    };
  }, [parent, eventId, isMobile]);

  const {
    showVolumeToggleInStreamOptionsBar,
    showVolumeToggleInPublishedStreams,
    showStageVolumeToggle,
  } = useMemo(() => {
    let showInStreamOptionsBar = false;
    let showInPublishedStreams = false;
    let _showStageVolumeToggle = true;

    const inGreenRoom = greenRoomStreams.filter(s => !!s).length > 0;

    if (
      inGreenRoom &&
      parent === IStageParent.LIVE_BACKSTAGE &&
      (!isStreamsEmpty || currentlyPlayingVideo || currentLiveStream)
    ) {
      showInStreamOptionsBar = true;
    } else if (parent === IStageParent.LIVE_BACKSTAGE && !inGreenRoom) {
      // Do not show the mute button when on stage from the backstage
      _showStageVolumeToggle = false;
    } else if (parent === IStageParent.STAGE_PREVIEW) {
      showInPublishedStreams = true;
    } else if (parent === IStageParent.LIVE_STAGE) {
      showInStreamOptionsBar = true;
    } else if (parent === IStageParent.BOOTH) {
      if (isBoothLiveView) {
        showInPublishedStreams = true;
      } else {
        showInStreamOptionsBar = true;
      }
    } else if (isBroadcasting && !isStreamsEmpty) {
      showInStreamOptionsBar = true;
    } else {
      _showStageVolumeToggle = false;
    }

    return {
      showVolumeToggleInStreamOptionsBar: showInStreamOptionsBar,
      showVolumeToggleInPublishedStreams: showInPublishedStreams,
      showStageVolumeToggle: _showStageVolumeToggle,
    };
  }, [
    parent,
    greenRoomStreams,
    streams,
    currentlyPlayingVideo,
    currentLiveStream,
    isBoothLiveView,
    isBroadcasting,
    isStreamsEmpty,
  ]);

  const [streamsLimitExceedError, setStreamsLimitExceedError] = useState<
    'stage' | 'backstage' | 'stage & backstage' | undefined
  >(undefined);

  useEffect(() => {
    if (
      parent !== IStageParent.LIVE_BACKSTAGE ||
      !isOrganizer ||
      isRecordingMode
    ) {
      return;
    }
    const isBackstageStreamsExceeded =
      greenRoomStreams.filter(s => !!s).length >= AGORA_CHANNEL_STREAMS_LIMIT;

    const streamsWithoutScreenShares = streams.filter(
      stream =>
        streamDetailsMap[stream.uid] &&
        streamDetailsMap[stream.uid].type &&
        ![StreamType.SCREEN, StreamType.SCREEN_RELAY].includes(
          streamDetailsMap[stream.uid].type,
        ),
    );

    const isStageStreamsExceeded =
      streamsWithoutScreenShares.filter(s => !!s).length >=
      AGORA_CHANNEL_STREAMS_LIMIT;
    if (isBackstageStreamsExceeded && isStageStreamsExceeded) {
      setStreamsLimitExceedError('stage & backstage');
      return;
    }
    if (isBackstageStreamsExceeded) {
      setStreamsLimitExceedError('backstage');
      return;
    }
    if (isStageStreamsExceeded) {
      setStreamsLimitExceedError('stage');
      return;
    }
    setStreamsLimitExceedError(undefined);
  }, [
    parent,
    greenRoomStreams,
    streams,
    isRecordingMode,
    isOrganizer,
    streamDetailsMap,
  ]);

  const { volumeOn, toggleVolume } = useStageVolumeToggle({
    showVolumeToggle: showStageVolumeToggle,
    parent,
  });

  const { audioEnabled, videoEnabled } = localAvConfig;

  const { filteredStreams, filteredGreenRoomStreams } = useMemo(() => {
    const filterStreams = (unfilteredStreams: any[]) =>
      unfilteredStreams
        .filter(s => !!s)
        .map(s => ({
          key: s.uid,
          local:
            videoHost && localStream && primaryStream
              ? primaryStream.uid === localStream.uid
              : false, // TODO: what is this doing?
          main: true,
          stream: s,
          uid: s.uid,
        }));

    const filteredStreams = filterStreams(streams);

    const filteredGreenRoomStreams = filterStreams(greenRoomStreams);

    return { filteredStreams, filteredGreenRoomStreams }

  }, [streams, greenRoomStreams, videoHost, localStream, primaryStream]);

  const getRenderedStream = () => {

    hasFilteredGreenRoomStreams = filteredGreenRoomStreams.length > 0;

    const PublishedStreamDisplayComponent = (
      <PublishedStreamDisplay
        eventRole={eventRole}
        currentUser={currentUser}
        showConfetti={showConfetti}
        channelConfig={stageConfig}
        attendeeChannel={attendeeChannel}
        broadcastPermissions={broadcastPermissions}
        eventConfig={eventConfig}
        fillerImageUrl={stageSettings.fillerImageUrl}
        frame={IVideoFrameTypes.PETAL}
        greenRoomStreams={filteredGreenRoomStreams}
        handleUserKickout={handleUserKickout}
        localClient={localClient}
        localStream={localStream}
        moveUserToGreenRoom={moveUserToGreenRoom}
        moveUserToStage={moveUserToStage}
        mutationCtx={mutationCtx}
        onBroadcastButtonClick={handleBroadcastButtonClick}
        onScreenShareButtonClick={handleScreenShareButtonClick}
        parent={parent}
        parentHeight={stageContainerHeight}
        parentWidth={stageContainerWidth}
        primaryScreenOrientation={IScreenCalcOrientationOptions.WIDTH}
        primaryScreenOrientationSize={100}
        primaryStream={primaryStream}
        secondaryStreams={secondaryStreams}
        setBroadcastPermissions={setBroadcastPermissions}
        setStreamsDisplayMode={setStreamsDisplayMode}
        streamDetailsMap={streamDetailsMap}
        streamNamesMap={streamNamesMap}
        streams={filteredStreams}
        streamsDisplayMode={streamsDisplayMode}
        timeLeftMs={timeLeftMs}
        presentationConfig={presentationConfig}
        pluginConfig={pluginConfig}
        breakoutConfig={breakoutConfig}
        localAvConfig={{ audioEnabled, videoEnabled }}
        volumeOn={volumeOn}
        toggleVolume={toggleVolume}
        showVolumeToggle={showVolumeToggleInPublishedStreams}
        refId={refId}
        raisedHandRequestList={raisedHandRequestList}
        completeRaisedHandRequest={completeRaisedHandRequest}
        localAvConfig={{ audioEnabled, videoEnabled }}
        myRaisedHandStatus={raisedHandStatus}
        dialInStreams={dialInStreams}
        setShowTabIndex={setShowTabIndex}
      />
    );

    if (showGreenRoomActionPanel) {
      const GreenRoomComp = (
        <div className={styles.greenRoomOptionsDisplay}>
          {isMobile && (
            <BottomSheet
              ref={ref}
              isOpen={toggleLeftPanel}
              onSnap={snapPoints => setSnapPoint(snapPoints)}
              onClose={() => setToggleLeftPanel(0)}
              initialSnap={0}
              snapPoints={[0.9, 0]}
              showCloseButton
              onCloseClick={() => setToggleLeftPanel(0)}
              onBackdropTouch={() => snapTo(1)}
              backdropStyle={{ left: selectedSnapPoint == 0 ? 0 : '-100vw' }}
              id="channelStreamsContainerBottomSheet"
            >
              <GreenRoomActionPanel
                channelConfig={stageConfig}
                currentActionPanelTab={currentGreenRoomActionPanelTab}
                setCurrentActionPanelTab={setCurrentGreenRoomActionPanelTab}
                currentVisibleMediaList={currentVisibleMediaList}
                setCurrentVisibleMediaList={setCurrentVisibleMediaList}
                userMoveFns={{
                  handleUserKickout,
                  moveUserToStage,
                  moveUserToGreenRoom,
                  completeRaisedHandRequest,
                }}
                streams={filteredStreams}
                greenroomStreams={filteredGreenRoomStreams}
                liveStreamConfig={{
                  currentLiveStream,
                  startLiveStream,
                  stopLiveStream,
                }}
                timerConfig={{
                  startTimer,
                  stopTimer,
                  timerDetails,
                  timeLeftMs,
                }}
                confettiConfig={{
                  startConfetti,
                  stopConfetti,
                  isConfettiDisplayed: showConfetti,
                }}
                videoMediaConfig={{
                  videoList,
                  playVideo,
                  markVideoDone,
                  refreshVideoList,
                  currentlyPlayingVideo,
                }}
                raisedHandRequestList={raisedHandRequestList}
                breakoutConfig={breakoutConfig}
                localAvConfig={{ audioEnabled, videoEnabled }}
                presentationConfig={presentationConfig}
              />
            </BottomSheet>
          )}
          {!isMobile && (
            <GreenRoomActionPanel
              channelConfig={stageConfig}
              currentActionPanelTab={currentGreenRoomActionPanelTab}
              setCurrentActionPanelTab={setCurrentGreenRoomActionPanelTab}
              currentVisibleMediaList={currentVisibleMediaList}
              setCurrentVisibleMediaList={setCurrentVisibleMediaList}
              userMoveFns={{
                handleUserKickout,
                moveUserToStage,
                moveUserToGreenRoom,
                completeRaisedHandRequest,
              }}
              streams={filteredStreams}
              greenroomStreams={filteredGreenRoomStreams}
              liveStreamConfig={{
                currentLiveStream,
                startLiveStream,
                stopLiveStream,
              }}
              timerConfig={{
                startTimer,
                stopTimer,
                timerDetails,
                timeLeftMs,
              }}
              confettiConfig={{
                startConfetti,
                stopConfetti,
                isConfettiDisplayed: showConfetti,
              }}
              videoMediaConfig={{
                videoList,
                playVideo,
                markVideoDone,
                refreshVideoList,
                currentlyPlayingVideo,
              }}
              raisedHandRequestList={raisedHandRequestList}
              breakoutConfig={breakoutConfig}
              localAvConfig={{ audioEnabled, videoEnabled }}
              presentationConfig={presentationConfig}
            />
          )}
        </div>
      );
      return [
        <div className={styles.greenRoomStreamsContainer}>
          <div ref={refCallback} className={styles.greenRoomStreamDisplay}>
            {PublishedStreamDisplayComponent}
          </div>
        </div>,
        GreenRoomComp,
      ];
    }

    return [
      <div style={{ height: '100%', width: '100%' }} ref={refCallback}>
        {PublishedStreamDisplayComponent}
      </div>,
    ];
  };

  const refCallback = element => {
    if (element) {
      const { height, width } = element.getBoundingClientRect();
      const isHeightSame = height === stageContainerHeight;
      const isWidthSame = width === stageContainerWidth;

      if (!isHeightSame && !isWidthSame) {
        setStageContainerHeight(height);
        setStageContainerWidth(width);
      } else if (!isHeightSame) {
        setStageContainerHeight(height);
      } else if (!isWidthSame) {
        setStageContainerWidth(width);
      }
    }
  };

  const config = (eventId && configByEvent[eventId]) || configByEvent.default;

  const [streamContainer, greenRoomActionPanelComponent] = getRenderedStream(
    stateCtx,
  );

  const rockCentralIds = [
    '2a4229dc-38a7-4506-bed9-f40f34249d6e',
    '5e4ff825-78cc-44f1-9970-efb51d6417ee',
    '0223575d-58dc-4ede-b55b-da3c7f542778',
    'a90d8a62-c6d2-4296-ab16-8ac5ca074427',
    'e2d82314-5996-4e99-a8bd-6892f48da0df',
    '755f64cd-80de-43f9-b50c-f8f4a993aef5',
    '75ea2c12-0266-4f76-aaef-8c08a824b6a6',
    '4d7df0dc-fe7e-4263-9f5b-db3d119efc0b',
    '2c84af5a-dcd5-46a2-813b-c02c7056d2f2',
    '2e4c219e-e94b-4e1d-958c-9f2a5373fcc5',
    'd8d9226d-dc07-428f-90c8-1f4277cf25fd',
  ];

  const handleCloseStreamLimitErrorPopup = setShow => {
    setStreamsLimitExceedError(undefined);
    setShow(false);
  };

  return (
    <div className={styles.channelStreams}>
      {useVirtualBackdrop && (
        <div className={styles.virtualCamera}>
          <DeepARCamera hidden={true} />
        </div>
      )}

      <div
        className={classnames(styles.videoStreamChatContainer, {
          [styles.fullScreenStream]: isStageFull,
        })}
      >
        {!isRecordingMode && <ConnectionStatusIndicator stateCtx={stateCtx} />}
        {audioPlayFailed && (
          <AutoplayClickOverlay onClick={() => setAudioPlayFailed(false)} />
        )}
        {greenRoomActionPanelComponent && (
          <div className={styles.greenRoomActionPanel}>
            {greenRoomActionPanelComponent}
          </div>
        )}
        <div
          className={classnames(styles.videoStreamOptionsContainerFullWidth, {
            [styles.videoStreamOptionsContainerWidthWithSidePanelOpen]:
              channelSidePanelState &&
              !isBoothLiveView &&
              parent !== IStageParent.LIVE_DISCUSSION &&
              parent !== IStageParent.STAGE_PREVIEW,
            // { [styles.videoStreamOptionsContainerFullWidth]: !chatPanelOpen },
            // { [styles.videoStreamOptionsContainerSidePanelOpen]: chatPanelOpen },
          })}
        >
          <div
            className={classnames({
              [styles.organiserVideoStreamContainerFullWidth]: greenRoomActionPanelComponent,
              [styles.videoStreamContainerFullWidth]: !greenRoomActionPanelComponent,
            })}
          >
            {streamContainer}
            {/* {isOrganizer && organiserVideoStreamContainerFullWidth
              isBackstage &&
              ![IStageParent.BOOTH_OWNER, IStageParent.BOOTH].includes(parent) &&
              !announcementLauncherOpen ? (
                <GenericErrorBoundary showFallbackUI>
                  <SpeakerTimer
                    startConfetti={startConfetti}
                    stopConfetti={stopConfetti}
                    collapseMenu={setSpeakerTimerOpen}
                    menuExpanded={speakerTimerOpen}
                    startTimer={startTimer}
                    stopTimer={stopTimer}
                    timerDetails={timerDetails}
                    timeLeftMs={timeLeftMs}
                    videoList={videoList}
                    playVideo={playVideo}
                    markVideoDone={markVideoDone}
                    currentLiveStream={currentLiveStream}
                    startLiveStream={startLiveStream}
                    stopLiveStream={stopLiveStream}
                  />
                </GenericErrorBoundary>
              ) : null} */}
          </div>
          {!isRecordingMode &&
            parent !== IStageParent.CONTRIBUTOR_SLOT &&
            parent !== IStageParent.AUDIENCE_GALLERY &&
            !rockCentralIds.includes(refId) && (
              <StreamOptionsBar
                broadcastPermissions={broadcastPermissions}
                channelMode={mode}
                raisedHandStatus={raisedHandStatus}
                completeMyRaisedHandRequest={completeMyRaisedHandRequest}
                audienceGalleryRequestStatus={audienceGalleryRequestStatus}
                completeMyAudienceGalleryRequest={
                  completeMyAudienceGalleryRequest
                }
                contributorViewRequestStatus={contributorViewRequestStatus}
                completeMyContributorViewRequest={
                  completeMyContributorViewRequest
                }
                isStreamsEmpty={isStreamsEmpty}
                localClient={localClient}
                localStream={localStream}
                onToggleRaiseHand={handleRaiseHandToggle}
                onToggleAudienceGalleryRequest={handleAudienceGalleryRequest}
                onToggleContributorViewRequest={handleContributorViewRequest}
                onBroadcastButtonClick={handleBroadcastButtonClick}
                onEmojiClick={handleEmojiClick}
                onScreenShareButtonClick={handleScreenShareButtonClick}
                pendingScreen={pendingScreen}
                parent={parent}
                setBroadcastPermissions={setBroadcastPermissions}
                setChatPanelOpen={setChatPanelOpen}
                setShowTabIndex={setShowTabIndex}
                timeLeftMs={timeLeftMs}
                hideStreamOptions={hideStreamOptions}
                currentlyPlayingVideo={currentlyPlayingVideo}
                currentLiveStream={currentLiveStream}
                selectedTable={selectedTable}
                hasGreenRoomStreams={hasFilteredGreenRoomStreams}
                showGreenRoomActionPanel={showGreenRoomActionPanel}
                channelConfig={stageConfig}
                moveUserToGreenRoom={moveUserToGreenRoom}
                channelSidePanelState={channelSidePanelState}
                avControlsConfig={localAvConfig}
                volumeOn={volumeOn}
                toggleVolume={toggleVolume}
                showVolumeToggle={showVolumeToggleInStreamOptionsBar}
                presentationConfig={presentationConfig}
                pluginConfig={pluginConfig}
                openMediaTabInGreenroomPanel={openMediaTabInGreenroomPanel}
                toggleBoothLiveView={toggleBoothLiveView}
                stageSessionSettings={stageSessionSettings}
                setToggleLeftPanel={setToggleLeftPanel}
                toggleLeftPanel={toggleLeftPanel}
              />
            )}
        </div>
      </div>
      {streamsLimitExceedError && (
        <StreamsLimitExceedPopup
          errorType={streamsLimitExceedError}
          handleClosePopup={handleCloseStreamLimitErrorPopup}
        />
      )}
    </div>
  );
};

export default ChannelStreamsContainer;
