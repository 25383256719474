import { IStageParent } from '../Stage/types';
import { IUIIcons } from '@/types';
import { IRaisedHandRequestStatus } from '../ChannelStreamsContainer/types';

export enum ZoneSidePanels {
  CHAT = 'ZONE_CHAT',
  POLL = 'ZONE_POLL',
  QNA = 'ZONE_QNA',
  FILES = 'ZONE_FILES',
  RAISED_HANDS = 'ZONE_RAISED_HANDS',
  PEOPLE = 'ZONE_PEOPLE',
  SURVEYS = 'ZONE_SURVEYS',
  HAS_MESSAGE_REACTIONS = 'HAS_MESSAGE_REACTIONS',
}

export enum EventSidePanels {
  CHAT = 'EVENT_CHAT',
  PEOPLE = 'EVENT_PEOPLE',
  FEED = 'EVENT_FEED',
  SETUP_ROOM = 'SETUP_ROOM',
  SURVEYS = 'SURVEYS',
}

export enum CustomSidePanels {
  DISCUSSION_ROOM = 'DISCUSSION_ROOMS',
  NETWORKING = 'NETWORKING',
  LOCKED_ROOMS = 'LOCKED_ROOMS',
}

export enum QNATabs {
  NEW_QUESTIONS = 'NEW_QUESTIONS',
  ANSWERED = 'ANSWERED',
  ALL = 'ALL',
}

export interface ICustomSidePanel {
  type: CustomSidePanels;
  props?: any;
}

export interface IChannelSidePanelProps {
  attendeeChannel: any;
  channelConfig: any;
  handleSideMenuIconClick: (
    icon: ZoneSidePanels | EventSidePanels | CustomSidePanels | null,
  ) => void;
  handleToggle: (icon: IUIIcons | null) => void;
  hostChannel: any;
  isLiveBroadcastMode: boolean;
  parent: IStageParent;
  raisedHandRequest?: {
    broadcastPermissions: any;
    setBroadcastPermissions: any;
    localClient: any;
    localStream: any;
    onToggleRaiseHand: any;
    parent: IStageParent;
    raisedHandStatus: IRaisedHandRequestStatus;
  };
  raisedHandRequestConfig?:
    | {
        approveRaisedHandRequest: () => void;
        completeRaisedHandRequest: () => void;
        dismissAllRaisedHandRequests: () => void;
        dismissRaisedHandRequest: () => void;
        raisedHandRequestList: any;
      }
    | {};
  audienceGalleryRequest?: {
    broadcastPermissions: any;
    setBroadcastPermissions: any;
    localClient: any;
    localStream: any;
    onToggleAudienceGallery: any;
    parent: IStageParent;
    audienceGalleryStatus: IRaisedHandRequestStatus;
  };
  audienceGalleryRequestConfig?:
    | {
        approveAudienceGalleryRequest: () => void;
        completeAudienceGalleryRequest: () => void;
        dismissAllAudienceGalleryRequests: () => void;
        dismissAudienceGalleryRequest: () => void;
        audienceGalleryRequestList: any;
      }
    | {};
  contributorViewRequest?: {
    broadcastPermissions: any;
    setBroadcastPermissions: any;
    localClient: any;
    localStream: any;
    onToggleContributorView: any;
    parent: IStageParent;
    contributorViewStatus: IRaisedHandRequestStatus;
  };
  contributorViewRequestConfig?:
    | {
        approveContributorViewRequest: () => void;
        completeContributorViewRequest: () => void;
        dismissAllContributorViewRequests: () => void;
        dismissContributorViewRequest: () => void;
        contributorViewRequestList: any;
      }
    | {};
  zoneName?: string;
  customPanels?: ICustomSidePanel[];
  allowClose?: boolean;
  defaultZoneTab?: ZoneSidePanels | CustomSidePanels;
  defaultEventTab?: EventSidePanels;
  isBroadcasting?: boolean;
  channelEmitterRef: any;
  isMeetingRoom?: boolean;
  isEmbedded?: boolean;
  isStudioStage?: boolean;
}

export { IUIIcons };

export interface IBookDemoDetails {
  channelBannerId: string;
  content: string;
  btnText: string;
  btnType: 'REDIRECT' | 'IFRAME';
  btnRedirectionUrl: string;
}

export interface ISurveyNotificationPopupData {
  title: string;
  body: string;
  publishedSurveyId: string | null;
}
