import React from 'react';
import shortid from 'shortid';
// components
import Option from '@/components/PollList/PollResult/Dropdown/Option';
// styles + types
import styles from './styles.module.scss';

const Dropdown = props => {
  const { options, dropdownRef, onClick, id } = props;
  
  return (
    <div
      aria-hidden
      id={`dropdown-${id}`}
      ref={dropdownRef}
      className={styles.dropdownContainer}
      onClick={onClick}
    >
      {options.map((option, index) => (
        <Option key={option.uid} option={option} index={index} />
      ))}
    </div>
  );
};

export default Dropdown;
