import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/poll`;
export default {
  votePollOption(pollId, optionId) {
    return axios.post(`${BASE_URL}/${pollId}/option/${optionId}/vote`);
  },
  clearPollVotes(pollId) {
    return axios.post(`${BASE_URL}/${pollId}/clear`);
  },
  publish(pollId: string) {
    return axios.patch(`${BASE_URL}/${pollId}/publish`);
  },
  close(pollId: string) {
    return axios.post(`${BASE_URL}/${pollId}/close`);
  },
  closeAndHide(pollId: string) {
    return axios.post(`${BASE_URL}/${pollId}/close-n-hide`);
  },
  update(pollId: string, poll: any) {
    return axios.post(`${BASE_URL}/${pollId}`, poll);
  },
  delete(pollId: string) {
    return axios.delete(`${BASE_URL}/${pollId}`);
  },
  pinOnStage(pollId: string, stageId: string) {
    return axios.post(`${BASE_URL}/${pollId}/pin_engagement/stage/${stageId}`);
  },
  unpinOnStage(pollId: string, stageId: string) {
    return axios.delete(
      `${BASE_URL}/${pollId}/pin_engagement/stage/${stageId}`,
    );
  },
};
