import React from 'react';
// components
import Label from '@/components/ui/content/Label';
// styles + types
import { SMALL_EMPTY_STATE_ICON } from '@/styles/iconSizes';
import styles from './styles.module.scss';
import { EngagementEmptyStateConfig } from './config';
import { EngagementEmptyStateTypes } from './types';
import { IContentColors } from '@/types';

const EngagementEmptyState = ({ type = EngagementEmptyStateTypes.CHAT }) => {
  const {
    className,
    IconComponent,
    subtext,
    text,
  } = EngagementEmptyStateConfig[type];
  return (
    <div className={styles.chatEmptyStateContainer}>
      <IconComponent
        className={className && styles[className]}
        color={IContentColors.NEUTRAL_MID_2}
        size={SMALL_EMPTY_STATE_ICON}
      />
      <Label styleClass="chatEmptyStateHeader" value={text} />
      <Label styleClass="chatEmptyStateDescription" value={subtext} />
    </div>
  );
};

export { EngagementEmptyStateTypes };
export default EngagementEmptyState;
