import { useMutation } from '@tanstack/react-query';
import api from './apis';
import Keys from './mutation.keys';
import type { AnyObject } from 'react-final-form';

const addEmojiReactions = (messageId: number) =>
  useMutation(Keys.addEmojiReactions(messageId), (payload: AnyObject) =>
    api.addEmojiReactions(messageId, payload),
  );

const removeEmojiReaction = (messageId: number) =>
  useMutation(Keys.removeEmojiReaction(messageId), (reactionId: string) =>
    api.removeEmojiReaction(messageId, reactionId),
  );

export default {
  addEmojiReactions,
  removeEmojiReaction,
};
