import React from 'react';
import DisconnectedIcon from '@/components/ui/new-icons/Disconnected';
import { STANDARD_ICON_BUTTON_SIZE } from '@/styles/iconSizes';
import { IContentColors, IContentTextColorTypes } from '@/types';
import styles from './styles.module.scss';
import Text, { ITextSizes, ITextStyles } from '@/components/ui/content/Text';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';

const DisconnectedIndicator = () => (
  <div className={styles.disconnectNotification}>
    <div className={styles.iconContainer}>
      <DisconnectedIcon
        size={STANDARD_ICON_BUTTON_SIZE}
        color={IContentColors.NEUTRAL_MID_2}
      />
    </div>
    <div>
      <Text
        text="Your internet "
        textColor={IContentTextColorTypes.BLACK}
        textSize={ITextSizes.DEFAULT}
        textStyle={ITextStyles.DEFAULT}
      />
      <Text
        text="is unstable."
        textColor={IContentTextColorTypes.BLACK}
        textSize={ITextSizes.DEFAULT}
        textStyle={ITextStyles.BOLDED}
      />
    </div>
  </div>
);

export default DisconnectedIndicator;
