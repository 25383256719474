// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__businessCard___Q1IpX{position:relative;padding:10px;width:14vw;max-width:300px;height:7vw;max-height:180px;border-radius:3px;background-color:rgba(0,0,0,0.7);background:radial-gradient(106.2% 282.31% at 103.56% -3.28%, #5A5959 0%, rgba(0,0,0,0) 100%),linear-gradient(180deg, #212121 0%, #2E2D2D 100%);box-shadow:0px 4px 30px rgba(0,0,0,0.15);overflow:hidden;margin:8px;color:white;font-size:1vw !important}.styles-module__deleteIcon___3NMw2{position:absolute;top:4px;right:8px}.styles-module__deleteIcon___3NMw2 svg{stroke:var(--base-white-base)}.styles-module__deleteIcon___3NMw2:hover{cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"businessCard": "styles-module__businessCard___Q1IpX",
	"deleteIcon": "styles-module__deleteIcon___3NMw2"
};
module.exports = exports;
