import React, { useRef, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { useVideoState } from '@/services/video/video-context';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { makeSelectEventUserRole, makeSelectEventRoleForEvent } from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';

const DeepARCamera = ({ hidden, refCallback, onScreenshotTaken, effect, onLoaded, canvasWidth = 640, canvasHeight = 360 }) => {
  const stateCtx = useVideoState();
  const cameraId = (stateCtx && stateCtx.main && stateCtx.main.config && stateCtx.main.config.cameraId) || '';

  const [showLoader, setShowLoader] = useState(true);
  const canvasRef = useRef();

  const deepAR = useRef();

  useEffect(() => {
    refCallback && refCallback(deepAR);
  }, [deepAR]);

  const { eventId } = useParams();
  const eventRole = useMemoizedSelector(makeSelectEventRoleForEvent, eventId);

  const alreadyLoaded = useRef(false);

  useEffect(() => {
    if (!canvasRef) {
      return;
    }

    if (!eventRole) {
      return;
    }

    if (alreadyLoaded.current) {
      return;
    }

    alreadyLoaded.current = true;
    let effectPath = eventRole ? eventRole.eventRoleId : 'background_segmentation_final_360';
    if (eventId !== '926d7a78-63a6-4fb7-b0cd-410461e52e1b') {
      effectPath = 'background_segmentation';
    }
    if (effect) {
      effectPath = effect;
    }

    deepAR.current = DeepAR({
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      licenseKey: 'e187c2a41fe60c0fc702428d9927f43a4cbff4b3b7f6900abd61c5fae5057256058d13c3dbc65638',
      canvas: document.getElementById('deepar-canvas'),
      numberOfFaces: 1,
      libPath: 'https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/deepar-lib',
      onInitialize: function () {
        // start video immediately after the initalization, mirror = true
        deepAR.current.setCanvasSize(canvasWidth, canvasHeight);
        deepAR.current.startVideo(true);

        deepAR.current.switchEffect(0, 'slot', `https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/deepar-lib/effects/${effectPath}`, function () {
          // effect loaded
        });
      },
      onScreenshotTaken
    });

    deepAR.current.downloadFaceTrackingModel('https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/deepar-lib/models-68-extreme.bin');

    deepAR.current.onVideoStarted = function () {
      setShowLoader(false);
      onLoaded && onloadeddata();
    };


    return () => {
      if (deepAR && deepAR.current) {
        deepAR.current.shutdown();
      }
    };
  }, [canvasRef, eventRole]);

  useEffect(() => {
    if (!deepAR || !deepAR.current) {
      return;
    }

    if (!cameraId) {
      return;
    }

    deepAR.current.stopVideo();
    deepAR.current.startVideo(true, {
      deviceId: cameraId
    });
  }, [cameraId])

  return (
    <canvas ref={canvasRef} className={classnames(styles.deepar, {
      [styles.hidden]: hidden
    })
    } id="deepar-canvas" oncontextmenu="event.preventDefault()" > </canvas>
  );
};

export default React.memo(DeepARCamera);
