import React, {ChangeEvent, useCallback, useMemo, useState,useRef,useEffect} from 'react';
// components
import AccordionMenu from '@/components/ui/menus/AccordionMenu';
import NewLabel, {
  INewLabelColors,
  INewLabelSizes,
  INewLabelStyles,
} from '@/components/ui/content/NewLabel';
import { PlusCircle } from 'react-feather';
import ExpandableContent, {IBGColors, IContentColors} from '@/components/ui/ExpandableContent';
import SearchField from '@/components/ui/fields/SearchField';

import RollCall from './RollCall';
import SpeakersOnStage from './SpeakersOnStage';
import Text, { ITextSizes, ITextColors } from '@/components/ui/content/Text';
// config + styles + types
import styles from './styles.module.scss';
import { IUIIcons } from '@/types';
// helpers + hooks + models
import { useDebounced} from '@/utils/helpers';
import LinkAlt from '@/components/ui/new-icons/LinkAlt';
import ReactTooltip from 'react-tooltip';
const CurrentSessionTabItem = props => {
  const { addToRollCall, index, modifyStage, name, speakers, time, userMoveFns, accountStreamMap, refreshListOfPeople, localAvConfig, eventTz } = props;
  const [searchText, setSearchText] = useState('');
  const getAccordionMenuOptions = (numSpeakersOnStage = 0) => [
    {
      id: 'speakersOnStage',
      label: `${numSpeakersOnStage} Speakers on Stage`,
      icon: IUIIcons.USER,
      rightContentComponent: (
        <div className={styles.iconButton} onClick={modifyStage}>
          <PlusCircle size={10} className={styles.plusIcon} />
          <Text
            text="Modify Stage"
            textColor={ITextColors.NEUTRAL_MEDIUM}
            textSize={ITextSizes.XXSMALL}
          />
        </div>
      ),
    },
    {
      id: 'rollCall',
      label: 'Roll Call',
      icon: IUIIcons.FILE_TEXT,
      rightContentComponent: (
        <div className={styles.iconButton} onClick={addToRollCall}>
          <PlusCircle size={10} className={styles.plusIcon} />
          <Text
            text="Add to Roll Call"
            textColor={ITextColors.NEUTRAL_MEDIUM}
            textSize={ITextSizes.XXSMALL}
          />
        </div>
      ),
    },
  ];
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef<any>();
  const copyEventURL = () => {
    navigator.clipboard
      .writeText(`${window.location.href}`)
      .then(() => {
        setIsCopied(true);
      })
      .catch(err => {
        console.error(`Failed to copy event URL to clipboard = ${err}`);
      });
      timeoutRef.current = setTimeout(() => {
        setIsCopied(false);
      }, 3000)
  };
  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    }
  }, []);

  const stageSpeakers: [] = useMemo(() => {
    return speakers.filter(v => {
      let {firstName = '', lastName = ''} = v;
      if(!firstName){firstName = ''}
      if(!lastName){lastName = ''}
      const {greenroom} = accountStreamMap[v.accountId] || {};
      return greenroom === false && (() => {
        if(searchText){
          return `${firstName} ${lastName}`.toLowerCase().includes(searchText);
        }
        return true;
      })();
    }).map(v => {
      return {
        ...v,
        streamInfo: accountStreamMap[v.accountId]
      }
    });
  }, [accountStreamMap, speakers, searchText]);

  const backstageSpeakers = useMemo(() => {
    return speakers.filter(v => !v.isRaisedHand).filter(v => {
      let {firstName = '', lastName = ''} = v;
      if(!firstName){firstName = ''}
      if(!lastName){lastName = ''}
      const {greenroom} = accountStreamMap[v.accountId] || {};
      return greenroom === true && (() => {
        if(searchText){
          return `${firstName} ${lastName}`.toLowerCase().includes(searchText);
        }
        return true;
      })();
    }).map(v => {
      return {
        ...v,
        streamInfo: accountStreamMap[v.accountId]
      }
    });
  }, [accountStreamMap, speakers, searchText]);

  const unavailableSpeakers = useMemo(() => {
    return speakers.filter(v => !v.isRaisedHand).filter(v => {
      let {firstName = '', lastName = ''} = v;
      if(!firstName){firstName = ''}
      if(!lastName){lastName = ''}
      const {greenroom} = accountStreamMap[v.accountId] || {};
      return greenroom === undefined && (() => {
        if(searchText){
          return `${firstName} ${lastName}`.toLowerCase().includes(searchText);
        }
        return true;
      })();
    }).map(v => {
      return {
        ...v,
        streamInfo: accountStreamMap[v.accountId] || {}
      }
    }); 
  }, [speakers, searchText])

  const setAttendeeSearchText = useDebounced(setSearchText, 1000);

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) =>
    setAttendeeSearchText(e.target.value.toLowerCase());


  const totalStage = stageSpeakers.length;
  const totalRollcall = backstageSpeakers.length + unavailableSpeakers.length;

  return (
    <div className={styles.currentSessionTabItemContainer}>
       <div className={styles.stageLabelContainer}>
           <div className={styles.stageLabel}>
              Stage Name 
            </div>
                  <div>
                    <div 
                     data-tip
                     data-for={'backstage-copy-event'}
                     className={isCopied?styles.copiedButton:styles.copyButton}
                     onClick={copyEventURL}
                     data-testid = 'copy-backStage-url'>
                      <LinkAlt size = {15} />
                    </div>
                    <ReactTooltip
                      className={styles.tootipContainer}
                      id={'backstage-copy-event'}
                      place={'top'}
                      effect="solid"
                    >
                      {isCopied?'Copied':'Copy link to Backstage'}
                    </ReactTooltip>
                  </div>
         </div>
      <div className={styles.currentSessionTabItemHeading}>
        <div className={styles.numberOuterCircle}>
          <div className={styles.numberInnerCircle}>
            <NewLabel
              text={index+1}
              labelColor={INewLabelColors.NEUTRAL_MEDIUM}
              labelSize={INewLabelSizes.XSMALL}
              labelStyle={INewLabelStyles.BOLDED}
            />
          </div>
        </div>
        <div className={styles.sessionTabName}>
          <NewLabel
            text={name}
            labelColor={INewLabelColors.MONOCHROME_CONTRAST}
            labelSize={INewLabelSizes.XSMALL}
            labelStyle={INewLabelStyles.BOLDED}
          />
          <NewLabel
            text={time}
            labelColor={INewLabelColors.NEUTRAL_DARK}
            labelSize={INewLabelSizes.XSMALL}
          />
          <NewLabel
            text={`(${eventTz})`}
            labelColor={INewLabelColors.NEUTRAL_DARK}
            labelSize={INewLabelSizes.XSMALL}
          />
        </div>
      </div>
      <div className={styles.currentSessionTabItemSearch}>
        <SearchField
          handleSearchQueryChange={handleSearchTextChange}
          searchQuery={searchText}
          placeHolder="Search Speaker"
          id="greenroom-speaker-search"
          className={styles.speakerSearchBox}
        />
      </div>
      <div className={styles.currentSessionTabItemContents}>
        <ExpandableContent
          icon={IUIIcons.USER_SPEAKING_OUTLINED}
          title={`Speakers on stage (${totalStage})`}
        >
          {totalStage > 0 && <SpeakersOnStage speakers={stageSpeakers} userMoveFns={userMoveFns} localAvConfig={localAvConfig} />}
        </ExpandableContent>
        <ExpandableContent
          icon={IUIIcons.FILE_LIST}
          title={`Roll call (${totalRollcall})`}
          bgColor={IBGColors.NEUTRAL_LIGHT}
          expandIconColor={IContentColors.MONOCHROME_CONTRAST}
          refreshContent={refreshListOfPeople}
        >
          {totalRollcall > 0 && <RollCall backstageSpeakers={backstageSpeakers} unavailableSpeakers={unavailableSpeakers} userMoveFns={userMoveFns} localAvConfig={localAvConfig} />}
        </ExpandableContent>
        {/* <AccordionMenu
          showErrorIcon={false}
          menuName="greenRoomCurrentSession"
          menuType="accordion"
          options={getAccordionMenuOptions(stageSpeakers.length)}
        >
          {[
            <SpeakersOnStage speakers={stageSpeakers} userMoveFns={userMoveFns} />,
            <RollCall backstageSpeakers={backstageSpeakers} unavailableSpeakers={unavailableSpeakers} userMoveFns={userMoveFns} />,
          ]}
        </AccordionMenu> */}
      </div>
    </div>
  );
};

export default CurrentSessionTabItem;
