import React, { useState, useEffect } from 'react';
import api from "@/api";
import { useParams } from 'react-router';
import styles from './styles.module.scss';
import NewLabel, { INewLabelColors, INewLabelMargins, INewLabelSizes } from '@/components/ui/content/NewLabel';
import InputField from '@/components/ui/fields/InputField';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import IconTextButton, { IUIIcons, IIconTextButtonTypes } from '@/components/ui/buttons/IconTextButton';
import stream from '@/api/stream';


const LiveStreamVideoIcon = (props) => (
    <svg {...props} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.583 7.67708L15.708 5.33333V11.5833L12.583 9.23958V11.5833H5.29134V5.33333H12.583V7.67708ZM4.0028 14.7083H18.833V2.20833H2.16634V16.151L4.0028 14.7083ZM4.72363 16.7917L0.0830078 20.4375V1.16667C0.0830078 0.890399 0.192754 0.625447 0.388105 0.430097C0.583455 0.234747 0.848407 0.125 1.12467 0.125H19.8747C20.1509 0.125 20.4159 0.234747 20.6112 0.430097C20.8066 0.625447 20.9163 0.890399 20.9163 1.16667V15.75C20.9163 16.0263 20.8066 16.2912 20.6112 16.4866C20.4159 16.6819 20.1509 16.7917 19.8747 16.7917H4.72363Z" />
    </svg>
)

const CheckIcon = () => (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10.5C2.2385 10.5 0 8.2615 0 5.5C0 2.7385 2.2385 0.5 5 0.5C7.7615 0.5 10 2.7385 10 5.5C10 8.2615 7.7615 10.5 5 10.5ZM4.5015 7.5L8.0365 3.9645L7.3295 3.2575L4.5015 6.086L3.087 4.6715L2.38 5.3785L4.5015 7.5Z" fill="#77B903" />
    </svg>
)

const SwitchIcon = () =>(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33333 8.33329V5.99996L11.25 8.91663L8.33333 11.8333V9.49996H1.33333V8.33329H8.33333ZM3.66667 0.166626V2.49938L10.6667 2.49996V3.66663H3.66667V5.99996L0.75 3.08329L3.66667 0.166626Z" fill="#ABABAB"/>
</svg>
)

const LiveStreamConfig = ({ currentLiveStream, startLiveStream, stopLiveStream }) => {
    const { stageId } = useParams();
    const [currentStreamType, setCurrentStreamType] = useState('MAIN');
    const [selectedStreamType, setSelectedStreamType] = useState('MAIN');
    const [hasSwitchedStream , setHasSwitchedStream ] = useState(false);
    const [mainStreamId, setMainStreamId] = useState("");
    const [backupStreamId, setBackupStreamId] = useState("");

    useEffect(()=>{
        api.channel.getStageLiveStreams(stageId).then(({ data }) => {
            if(data){
                data.forEach(stream => {
                    if(stream.streamType === 'MAIN'){
                        setMainStreamId(stream.liveStreamId);
                    }else{
                        setBackupStreamId(stream.liveStreamId);
                    }
                });
            }
        });
    },[stageId]);
  
    useEffect(() => {
        api.channel.getCurrentPlayingStream(stageId).then(({ data }) => {
            if(data){
                setSelectedStreamType(data.streamType);
                setCurrentStreamType(data.streamType);
            }
        });
        setHasSwitchedStream(false);
    }, [currentLiveStream]);

    const onSwitchLiveStream = () => {
        let newStreamType = "MAIN";
        if(selectedStreamType === 'MAIN'){
            newStreamType = 'BACKUP'  
        }
        if(currentStreamType === newStreamType){
            setHasSwitchedStream(false);
        }else if(currentLiveStream ){
            setHasSwitchedStream(true);
        }
        setSelectedStreamType(newStreamType);
    }

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <div className={styles.videoIconContainer}>
                    <LiveStreamVideoIcon className={styles.videoIcon} />
                </div>
                <div className={styles.liveStreamTextContainer}>
                    <div className={styles.liveStreamText}>Livestream</div>
                    <div className={styles.liveStreamHitText}>for Hybrid Setups</div>
                </div>
            </div>

            <div className={styles.selectedStreamInfoContainer}>
                <div className={styles.selectedStreamText}>{selectedStreamType === 'BACKUP' ? 'Backup' : 'Main'} Link</div>
                <div className={styles.selectedStreamNormalText}>selected for streaming</div>
                <CheckIcon />
            </div>

            {
                currentLiveStream && !hasSwitchedStream?
                    <IconTextButton
                        showDefault
                        defaultLabel="Stop Livestream"
                        defaultIcon={IUIIcons.STOPSTREAM}
                        onClick={() => stopLiveStream(currentStreamType==='MAIN' ? mainStreamId:backupStreamId)}
                        buttonType={IIconTextButtonTypes.LIVE_STREAM_STOP_PRIMARY}
                    />
                    :
                    <IconTextButton
                        showDefault
                        defaultLabel="Start Livestream"
                        onClick={() => startLiveStream(hasSwitchedStream, currentStreamType==='MAIN'? mainStreamId : backupStreamId, selectedStreamType ==='MAIN' ? mainStreamId : backupStreamId)}
                        defaultIcon={IUIIcons.STARTSTREAM}
                        buttonType={IIconTextButtonTypes.BCKSTG_SUCCESS_SQUARE}
                    />
            }
            
            <div className={styles.switchContainer}  onClick={() => onSwitchLiveStream()}>
                <SwitchIcon />
                <div className={styles.switchText}>Switch to {selectedStreamType === 'BACKUP' ? 'Main' : 'Backup'} Link</div>
            </div>

        </div>
    );
}

export default LiveStreamConfig;
