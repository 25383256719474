import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import Text, { ITextSizes, ITextColors } from '@/components/ui/content/Text';
import InputField from '@/components/ui/fields/InputField';
import { IMAGE_URL } from '@/config';
import api from '@/api';
import useUploader, { IUploadType } from '@/hooks/use-uploader';
import { Loader } from 'react-feather';
import styles from './styles.module.scss';
import UploadIcon from '@/components/ui/new-icons/Upload';
import { IContentColors, IUIIcons } from '@/types';
import IconTextButton from '@/components/ui/buttons/IconTextButton';
import { IButtonTypes } from '@/components/ui/buttons/Button';
import Video from '@/components/ui/media/Video';

const ImagePreview = ({ url }) => (
  <img className={styles.image} src={url} alt="preview" />
);

const VideoPreview = ({ url }) => (
  <Video
    styleClass="mediaUploadVideo"
    loop={true}
    url={url}
    autoplay={false}
    playsinline={true}
    controls={true}
    showLoader={false}
    muted={false}
    deletable={false}
  />
);

const PdfPreview = ({ url }) => (
  <img className={styles.image} src={`${url}?fm=jpg`} alt="preview" />
);

const AddImage = ({ config, channelId, onCancel, onSuccess }) => {
  const { type, heading, uploadType } = config;
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: '',
    file: '',
  });
  const [formErr, setFormErr] = useState(false);
  const {
    startUpload,
    fileName: uploadedFileName,
    isUploading: isImageUploading,
    videoUploadingStatusText: videoStatusText,
    metadata,
  } = useUploader({
    type: uploadType,
  });
  const isFormValid = (function () {
    const { name, file } = form;
    if (
      new RegExp('[a-zA-Z0-9]').test(form.name) &&
      new RegExp('[a-zA-Z0-9]').test(form.file)
    ) {
      return true;
    }
    return false;
  })();

  const handleAddImage = () => {
    if (!isFormValid) {
      setFormErr(true);
      return false;
    }
    api.channel
      .addChannelImage(channelId, {
        name: form.name,
        url: form.file,
        channelImageType: type,
      })
      .then(_ => {
        onSuccess();
      })
      .catch(_ => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Error saving file',
          },
        });
      });
  };

  const handleAddVideo = () => {
    if (!isFormValid) {
      setFormErr(true);
      return false;
    }
    api.channel
      .addChannelVideo(channelId, {
        videoLink: form.file,
        videoName: form.name,
      })
      .then(_ => {
        onSuccess();
      })
      .catch(_ => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Error saving file',
          },
        });
      });
  };

  const handleAddPresentation = () => {
    if (!isFormValid) {
      setFormErr(true);
      return false;
    }
    api.channel
      .addPresentation({
        channelId,
        name: form.name,
        url: form.file,
        totalPages: metadata.totalPages,
      })
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Error saving file',
          },
        });
      });
  };

  const handleFormUpdate = (key, val) => {
    if (key === "name") {
      if (!!val.trim()) {
        setFormErr(false);
      }
      else {
        setFormErr(true);
      }
    }
    setForm(prev => ({
      ...prev,
      [key]: val,
    }));
  };

  useEffect(() => {
    if (uploadedFileName) {
      handleFormUpdate(
        'file',
        uploadedFileName.startsWith('https://stream')
          ? `${uploadedFileName}`
          : `${IMAGE_URL}${uploadedFileName}`,
      );
    } else {
      handleFormUpdate('file', '');
    }
  }, [uploadedFileName]);

  const {
    UploadTypeString,
    PreviewComp,
    handleAdd,
    UploadButtonLabel,
    InputLabel,
  } = (() => {
    let uts; // UploadTypeString
    let pc; // PreviewComp
    let ha; // handleAdd
    let ubl; // UploadButtonLabel
    let il; // InputLabel

    if (uploadType === IUploadType.IMAGE) {
      uts = 'Image';
      pc = ImagePreview;
      ha = handleAddImage;
      ubl = 'Add Overlay';
      il = 'Overlay title';
    }
    if (uploadType === IUploadType.VIDEO) {
      uts = 'Video';
      pc = VideoPreview;
      ha = handleAddVideo;
      ubl = 'Add Video';
      il = 'Video title';
    }
    if (uploadType === IUploadType.PDF_FILE) {
      uts = 'Presentation';
      pc = PdfPreview;
      ha = handleAddPresentation;
      ubl = 'Add Presentaion';
      il = 'Presentation title';
    }

    return {
      UploadTypeString: uts,
      PreviewComp: pc,
      handleAdd: ha,
      UploadButtonLabel: ubl,
      InputLabel: il,
    };
  })();

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <Text
          text={heading}
          textColor={ITextColors.MONOCHROME_CONTRAST}
          textSize={ITextSizes.XSMALL}
        />
        <div onClick={onCancel}>
          <Text
            className={styles.cancelText}
            text="Cancel"
            textColor={ITextColors.NEUTRAL_MEDIUM_2}
            textSize={ITextSizes.XXSMALL}
            clickable={true}
          />
        </div>
      </div>
      <div className={styles.form}>
        <Text
          text={`${InputLabel} *`}
          textColor={ITextColors.NEUTRAL_MEDIUM_2}
          textSize={ITextSizes.XXSMALL}
        />
        <InputField
          name="name"
          onChange={e => handleFormUpdate('name', e.target.value)}
          className={styles.input}
          isLastElement
          widthStyles="fullWidth"
          maxLength={200}
        />
        {!new RegExp('[a-zA-Z0-9]').test(form.name) && formErr && (
          <p className={styles.fieldError}>This is a mandatory field</p>
        )}
        {form.file && (
          <div
            className={classnames(styles.mediaWrapper)}
            onClick={startUpload}
          >
            <PreviewComp url={form.file} />
          </div>
        )}
        {!form.file && (
          <div
            className={classnames(styles.mediaPlaceholder)}
            onClick={startUpload}
          >
            {isImageUploading ? (
              <Loader className={styles.uploadingIcon} size={16} />
            ) : (
              <UploadIcon size={16} color={IContentColors.NEUTRAL_MID_3} />
            )}
            <Text
              text={
                uploadType === IUploadType.VIDEO
                  ? videoStatusText
                  : `Upload${isImageUploading ? 'ing' : ''} ${UploadTypeString}`
              }
              textColor={ITextColors.NEUTRAL_MEDIUM}
              textSize={ITextSizes.SMALL}
            />
          </div>
        )}
        {uploadType === IUploadType.VIDEO &&
          videoStatusText !== 'Upload Video' && (
            <Text
              className={styles.videoWarningText}
              text="Please stay on this tab till video is uploaded. Moving out will cancel the upload"
              textColor={ITextColors.NEUTRAL_MEDIUM_2}
              textSize={ITextSizes.XXSMALL}
            />
          )}
        {!new RegExp('[a-zA-Z0-9]').test(form.file) && formErr && (
          <p className={styles.fieldError}>This is a mandatory field</p>
        )}
        <div className={styles.submit}>
          <IconTextButton
            showDefault
            defaultIcon={IUIIcons.CHECK}
            defaultLabel={UploadButtonLabel}
            buttonType={IButtonTypes.NEW_PRIMARY}
            onClick={handleAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default AddImage;
