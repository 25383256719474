// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__deepar___1xRHZ{border:0px none;background-color:black;display:block;margin:auto;-webkit-tap-highlight-color:rgba(80,49,49,0)}.styles-module__hidden___3JjVH{visibility:hidden;position:absolute}\n", ""]);
// Exports
exports.locals = {
	"deepar": "styles-module__deepar___1xRHZ",
	"hidden": "styles-module__hidden___3JjVH"
};
module.exports = exports;
