import axios from './axios';
import { SERVICE_URL } from '@/config';
import {
  ChannlRefType,
  IEventChannelSettingsType,
  IChannelRequest,
  IChannelRequestType,
  IChannelRequestStatus,
} from '@/types/channel';
import { IEventRoleOptions } from '@/types/eventRole';
import {
  QuestionStatusTypes,
  ModerateQuestionStatus,
} from '@/components/QuestionList/QuestionV2/types';
import { IFilterType } from '@/components/PublishedQuestionSection/types';

const BASE_URL = `${SERVICE_URL}/channel`;
const CHANNEL_FILE_BASE_URL = `${SERVICE_URL}/channel_file`;
const CHANNEL_REQUEST_BASE_URL = `${SERVICE_URL}/channel-request`;

export default {
  startBroadcast(channelId) {
    return axios.post(`${BASE_URL}/${channelId}/broadcast`);
  },
  stopBroadcast(channelId) {
    return axios.post(`${BASE_URL}/${channelId}/unbroadcast`);
  },
  getChannelByRef(refId: string) {
    return axios.get(`${BASE_URL}`, {
      params: {
        refId,
      },
    });
  },
  getChannelToken({ channelId, channelName, uid }) {
    return axios.get(`${BASE_URL}/${channelId}/token/${channelName}/${uid}`);
  },
  getLiveStageList(eventId: string) {
    return axios.get(`${BASE_URL}/event/${eventId}/live/stages`);
  },
  getConfigByRef(refId: string) {
    return axios.get(`${BASE_URL}/config`, {
      params: {
        refId,
      },
    });
  },
  getStudioConfigByRef(studioId: string) {
    return axios.get(`${BASE_URL}/studio/${studioId}/config`);
  },
  // Chat Messages
  getMessageList(channelId, isHostChannel, isPinned) {
    return axios.get(`${BASE_URL}/${channelId}/message/list`, {
      params: {
        host: isHostChannel,
        isPinned: isPinned,
      },
    });
  },
  // Chat moderate pending messages
  getPendingMessageList(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/pendingMessage/list`);
  },
  updateMessageModerateStatus(channelId, messageId, status) {
    return axios.post(
      `${BASE_URL}/${channelId}/${messageId}/updateChatStatus?moderateChatStatus=${status}`,
    );
  },
  sendMessage(channelId, isHostChannel, message, mediaType) {
    return axios.post(
      `${BASE_URL}/${channelId}/message`,
      {
        content: message,
        mediaType,
      },
      {
        params: {
          host: isHostChannel,
        },
      },
    );
  },
  getAttendeeMessageList(channelId, receiverAccountId, eventId) {
    return axios.get(`${BASE_URL}/${channelId}/attendee/message/list`, {
      params: {
        receiverAccountId,
        eventId,
      },
    });
  },
  sendAttendeeMessage(channelId, receiverAccountId, message, mediaType) {
    return axios.post(
      `${BASE_URL}/${channelId}/attendee/message`,
      {
        content: message,
        mediaType,
      },
      {
        params: {
          receiverAccountId,
        },
      },
    );
  },
  // Questions
  askQuestion(channelId, question) {
    return axios.post(`${BASE_URL}/${channelId}/question`, {
      text: question,
    });
  },
  getQuestionList(channelId, offset) {
    return axios.get(`${BASE_URL}/${channelId}/question/list?offset=${offset}`);
  },
  getQuestionListCount(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/question/list/count`);
  },
  // New Questions API
  fetchQuestions(
    channelId: string,
    limit: number,
    offset: number | undefined,
    moderateQuestionStatus: ModerateQuestionStatus,
    questionViewFilter: IFilterType | IFilterType.ALL_QUESTIONS,
    sortByFilter: IFilterType | IFilterType.UPVOTES,
    userPendingQuestion: boolean | false,
  ) {
    return axios.get(`${BASE_URL}/${channelId}/questions/v1/`, {
      params: {
        channelId,
        limit,
        offset,
        moderateQuestionStatus,
        questionViewFilter,
        sortByFilter,
        userPendingQuestion,
      },
    });
  },
  fetchQuestionsCount(channelId, moderateQuestionStatus) {
    return axios.get(
      `${BASE_URL}/${channelId}/questions/count?moderateQuestionStatus=${moderateQuestionStatus}`,
    );
  },
  updateModerateQuestionStatus(channelId, questionId, moderateQuestionStatus) {
    return axios.post(
      `${BASE_URL}/${channelId}/questions/${questionId}?moderateQuestionStatus=${moderateQuestionStatus}`,
    );
  },
  bulkUpdateModerateQuestionStatus(
    channelId,
    questionIds,
    moderateQuestionStatus,
  ) {
    return axios.post(
      `${BASE_URL}/${channelId}/questions/bulk?moderateQuestionStatus=${moderateQuestionStatus}`,
      questionIds,
    );
  },
  // Polls
  createPoll(channelId, poll) {
    return axios.post(`${BASE_URL}/${channelId}/poll`, poll);
  },
  getPollListWithResults(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/poll/list`);
  },
  // Reactions
  saveReaction(channelId, reactionType) {
    return axios.post(`${BASE_URL}/${channelId}/reaction/${reactionType}`);
  },
  // Timer
  getCurrentTimer(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/timer`);
  },
  startTimer(channelId, timerDetails) {
    return axios.post(`${BASE_URL}/${channelId}/timer/start`, timerDetails);
  },
  stopTimer(channelId) {
    return axios.post(`${BASE_URL}/${channelId}/timer/stop`);
  },
  // Confetti
  startConfetti(channelId) {
    return axios.post(`${BASE_URL}/${channelId}/confetti`);
  },
  stopConfetti(channelId) {
    return axios.post(`${BASE_URL}/${channelId}/confetti/stop`);
  },
  // Kickoff
  kickoffUser({ channelId, channelName, uid }) {
    return axios.post(`${BASE_URL}/${channelId}/kickoff/${channelName}/${uid}`);
  },
  moveUserToStage({ channelId, channelName, uid }) {
    return axios.post(
      `${BASE_URL}/${channelId}/move/stage/${channelName}/${uid}`,
    );
  },
  moveUserToGreenRoom({ channelId, channelName, uid }) {
    return axios.post(
      `${BASE_URL}/${channelId}/move/green_room/${channelName}/${uid}`,
    );
  },
  /**
   * @deprecated This API has been deprecated, instead use `use-remote-stream-control.tsx`
   */
  muteUser({ channelId, uid }) {
    return axios.post(`${BASE_URL}/${channelId}/mute/${uid}`);
  },
  // Raised Hand Requests
  getRaisedHandStatus(channelId: number) {
    return axios.get(`${BASE_URL}/${channelId}/raise_hand/requested`);
  },
  getRaisedHandRequests(channelId: number) {
    return axios.get(`${BASE_URL}/${channelId}/raise_hand/list`);
  },
  sendRaisedHandRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/raise_hand`);
  },
  cancelRaisedHandRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/raise_hand/cancel`);
  },
  approveRaisedHandRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/raise_hand/${accountId}/approve`,
    );
  },
  dismissAllRaisedHandRequests(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/raise_hand/dismiss`);
  },
  dismissRaisedHandRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/raise_hand/${accountId}/dismiss`,
    );
  },
  completeMyRaisedHandRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/raise_hand/complete`);
  },
  completeRaisedHandRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/raise_hand/${accountId}/complete`,
    );
  },
  completeAllRaisedHandRequests(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/raise_hand/complete/all`);
  },
  // Audience Gallery Requests
  getAudienceGalleryStatus(channelId: number) {
    return axios.get(`${BASE_URL}/${channelId}/audience_gallery/requested`);
  },
  getAudienceGalleryRequests(channelId: number) {
    return axios.get(`${BASE_URL}/${channelId}/audience_gallery/list`);
  },
  sendAudienceGalleryRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/audience_gallery`);
  },
  cancelAudienceGalleryRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/audience_gallery/cancel`);
  },
  approveAudienceGalleryRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/audience_gallery/${accountId}/approve`,
    );
  },
  dismissAllAudienceGalleryRequests(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/audience_gallery/dismiss`);
  },
  dismissAudienceGalleryRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/audience_gallery/${accountId}/dismiss`,
    );
  },
  completeMyAudienceGalleryRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/audience_gallery/complete`);
  },
  completeAudienceGalleryRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/audience_gallery/${accountId}/complete`,
    );
  },
  completeAllAudienceGalleryRequests(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/audience_gallery/complete/all`);
  },
  // Videos
  getAllChannelVideos(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/video/list`);
  },
  getCurrentlyPlayingVideo(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/video/current`);
  },
  updateChannelVideoStatus(channelId, channelVideoDetails) {
    return axios.post(
      `${BASE_URL}/${channelId}/video/status`,
      channelVideoDetails,
    );
  },
  addChannelVideo(channelId, channelVideoDetails) {
    return axios.post(`${BASE_URL}/${channelId}/video`, channelVideoDetails);
  },
  deleteChannelVideo(channelId, channelVideoId) {
    return axios.delete(`${BASE_URL}/${channelId}/video/${channelVideoId}`);
  },
  // emoji
  getEmojiList() {
    return axios.get(`${BASE_URL}/emoji/master/list`);
  },
  saveEmojiByChannelId(record) {
    return axios.post(`${BASE_URL}/emoji`, record);
  },
  removeEmojiByChannelId(record) {
    return axios.post(`${BASE_URL}/emoji/remove`, record);
  },
  // settings
  getChannelSettingsByChannelId(channelId: string) {
    return axios.get<IEventChannelSettingsType>(
      `${BASE_URL}/${channelId}/settings`,
    );
  },
  updateStageSettingsWithChannelId(channelId, settings) {
    return axios.post(`${BASE_URL}/${channelId}/settings`, settings);
  },
  // files
  getFileList(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/file/list`);
  },
  saveFile(channelId, file) {
    return axios.post(`${BASE_URL}/${channelId}/file`, file);
  },
  deleteFile(channelFileId) {
    return axios.delete(`${CHANNEL_FILE_BASE_URL}/${channelFileId}`);
  },
  updateFile(channelFileId, file) {
    return axios.post(`${CHANNEL_FILE_BASE_URL}/${channelFileId}`, file);
  },
  getChannelUsers(refType: ChannlRefType, refId: string) {
    return axios.get(`${BASE_URL}/users/list`, {
      params: {
        refId,
        refType,
      },
    });
  },
  // Images
  getChannelImagesByChannel(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/image/list`);
  },
  getLiveChannelImages(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/image/live`);
  },
  updateChannelImageStatus(channelId, channelVideoDetails) {
    return axios.post(
      `${BASE_URL}/${channelId}/image/status`,
      channelVideoDetails,
    );
  },
  addChannelImage(channelId, imageDetails) {
    return axios.post(`${BASE_URL}/${channelId}/image/add`, imageDetails);
  },
  deleteChannelImage(channelId, channelImageId) {
    return axios.delete(`${BASE_URL}/${channelId}/image/${channelImageId}`);
  },
  // Videos
  getCurrentChannelLiveStream(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/live_stream`);
  },
  getStageLiveStreams(stageId) {
    return axios.get(`${SERVICE_URL}/stream/${stageId}/live_stream/list`);
  },
  getCurrentPlayingStream(stageId) {
    return axios.get(`${SERVICE_URL}/stream/${stageId}/live_stream/status`);
  },
  startChannelLiveStream(channelId, liveStreamId) {
    return axios.post(
      `${SERVICE_URL}/stream/${channelId}/live_stream/start/${liveStreamId}`,
    );
  },
  stopChannelLiveStream(channelId, liveStreamId) {
    return axios.post(
      `${SERVICE_URL}/stream/${channelId}/live_stream/stop/${liveStreamId}`,
    );
  },
  switchChannelLiveStream(channelId, fromLiveStreamId, toLiveStreamId) {
    return axios.post(
      `${SERVICE_URL}/stream/${channelId}/live_stream/switch/from/${fromLiveStreamId}/to/${toLiveStreamId}`,
    );
  },
  addPresentation({
    channelId,
    url,
    name,
    presentImmediately = false,
    isTemp = false,
    totalPages,
  }) {
    return axios.post(`${BASE_URL}/${channelId}/presentation/add`, {
      channelId,
      presentationName: name,
      presentationUrl: url,
      presentImmediately,
      isTemporary: isTemp,
      totalPages,
    });
  },
  viewPresentationList(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/presentation/list`);
  },
  viewCurrentPresentation(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/presentation/current`);
  },
  startPresentation(channelId, presentationId) {
    return axios.post(
      `${BASE_URL}/${channelId}/presentation/${presentationId}/start`,
    );
  },
  stopPresentations(channelId) {
    return axios.post(`${BASE_URL}/${channelId}/presentation/stop`);
  },
  changePresentationPage(channelId, presentationId, page) {
    return axios.patch(
      `${BASE_URL}/${channelId}/presentation/${presentationId}/page`,
      {
        page,
      },
    );
  },
  deletePresentation(channelId, presentationId) {
    return axios.delete(
      `${BASE_URL}/${channelId}/presentation/${presentationId}`,
    );
  },
  // Update Channel Last Seen
  updateChannelLastSeen(channelName: string) {
    return axios.post(`${BASE_URL}/${channelName}/last_seen`);
  },
  // Contributor View
  getContributorViewStatus(channelId: number) {
    return axios.get(`${BASE_URL}/${channelId}/contributor/requested`);
  },
  getContributorViewRequests(channelId: number) {
    return axios.get(`${BASE_URL}/${channelId}/contributor/list`);
  },
  sendContributorViewRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/contributor`);
  },
  cancelContributorViewRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/contributor/cancel`);
  },
  approveContributorViewRequest(
    channelId: number,
    accountId: string,
    slotNumber: number,
  ) {
    return axios.post(
      `${BASE_URL}/${channelId}/contributor/${accountId}/approve/${slotNumber}`,
    );
  },
  dismissAllContributorViewRequests(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/contributor/dismiss`);
  },
  dismissContributorViewRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/contributor/${accountId}/dismiss`,
    );
  },
  completeMyContributorViewRequest(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/contributor/complete`);
  },
  completeContributorViewRequest(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/contributor/${accountId}/complete`,
    );
  },
  completeAllContributorViewRequests(channelId: number) {
    return axios.post(`${BASE_URL}/${channelId}/contributor/complete/all`);
  },
  getMySlotConfig(channelId: number) {
    return axios.get(`${BASE_URL}/${channelId}/contributor/slot/config`);
  },
  getCurrentPlugin(channelId) {
    return axios.get(`${BASE_URL}/${channelId}/plugin/current`);
  },
  addPlugin({ channelId, url, name, pluginType, isActive }) {
    return axios.post(`${BASE_URL}/${channelId}/plugin/add`, {
      channelId,
      pluginName: name,
      pluginUrl: url,
      pluginType,
      isActive,
    });
  },
  stopPlugin(channelId) {
    return axios.post(`${BASE_URL}/${channelId}/plugin/stop`);
  },
  updateCustomAppsSetting(
    channelId: number,
    channelCustomAppsSettingList: any,
  ) {
    return axios.put(
      `${BASE_URL}/${channelId}/custom_apps/setting`,
      channelCustomAppsSettingList,
    );
  },
  deleteCustomAppsSetting(channelId: number, channelCustomAppsSettingIds: any) {
    return axios.delete(`${BASE_URL}/${channelId}/custom_apps/setting`, {
      data: channelCustomAppsSettingIds,
    });
  },
  // This API using for fetching channel broadcasting status check every 10sec.
  getChannelBroadcastingStatus(channelId: string) {
    return axios.get(`${BASE_URL}/${channelId}/broadcasting/status`);
  },
  getBookDemoDetails(channelId: string) {
    return axios.get(`${BASE_URL}/${channelId}/banner`);
  },
  raiseHandRequest(channelId: number) {
    return axios.post(`${CHANNEL_REQUEST_BASE_URL}/${channelId}/raise-hand`);
  },
  lowerHandRequest(channelId: number) {
    return axios.patch(`${CHANNEL_REQUEST_BASE_URL}/${channelId}/lower-hand`);
  },
  lowerHandOfOther(channelId: number, requestBy: string) {
    return axios.patch(
      `${CHANNEL_REQUEST_BASE_URL}/${channelId}/lower-hand/other`,
      {
        channelId,
        requestBy,
      },
    );
  },
  channelRequestList(
    channelId: number,
    type: IChannelRequestType,
    status: IChannelRequestStatus,
  ) {
    return axios.get<IChannelRequest[]>(
      `${CHANNEL_REQUEST_BASE_URL}/${channelId}/request/list`,
      {
        params: { type, status },
      },
    );
  },
  muteRemoteUser(channelId: number, accountId: string) {
    return axios.post(`${BASE_URL}/${channelId}/user/${accountId}/audio/off`);
  },
  requestUnMuteRemoteUser(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/user/${accountId}/audio/on/request`,
    );
  },
  videoOffRemoteUser(channelId: number, accountId: string) {
    return axios.post(`${BASE_URL}/${channelId}/user/${accountId}/video/off`);
  },
  requestVideoOnRemoteUser(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/user/${accountId}/video/on/request`,
    );
  },
  screenShareStopRemoteUser(channelId: number, accountId: string) {
    return axios.post(
      `${BASE_URL}/${channelId}/user/${accountId}/screen-share/stop`,
    );
  },
};
